<template>
  <v-btn size="x-small" icon="mdi-close" style="position: absolute; right: -10px; top: -10px; z-index: 10;" @click="$emit('closeDialog', false)"></v-btn>
  <v-card style="border: 1px solid gray;" height="700px">
    <span size="big" class="dialog-title">{{ $t(submitType) + ' ' + (currentRole === 'Client' ? $t('leftmenu.admin').toLowerCase() : $t('employee').toLowerCase()) }}</span>
    <v-stepper  :model-value="step" alt-labels
                style="overflow: unset; box-shadow: none; height: -webkit-fill-available">
      <div
          style="position: -webkit-sticky; position: sticky; top: 0; z-index: 10;">
        <v-stepper-header style="overflow: unset; box-shadow: none;">
          <v-stepper-item editable @click="setStep(0)" edit-icon="mdi-account" complete-icon="mdi-account"
                          color="primary" :complete="stepComplete('1')" :title="$t('details') + ' ' + $t('register.account')" value="1" style="margin-left: 0;"></v-stepper-item>
          <v-divider></v-divider>
          <v-stepper-item :editable="validForm" @click="setStep(1)" edit-icon="mdi-domain"  complete-icon="mdi-domain"
                          color="primary" :complete="stepComplete('2')" :title="$t('companies')" value="2"></v-stepper-item>
          <v-divider></v-divider>
          <v-stepper-item :editable="validForm" @click="setStep(2)" edit-icon="mdi-check-bold" complete-icon="mdi-check-bold"
                          color="primary" :complete="stepComplete('3')" :title="$t('register.review')" value="3"></v-stepper-item>
        </v-stepper-header>
        <v-divider></v-divider>
      </div>
      <v-stepper-window style="min-height: -webkit-fill-available;"
                        :model-value="step"
                        v-touch="{left: () => prev, right: () => next}">
        <v-stepper-window-item
            value="1"
            class="register-window"
        >
          <v-form v-model="validForm" ref="userForm" style="width: 80%; margin: 20px 10% 0 10%;">
            <v-text-field
                v-model="userFormItem.lastname" class="form-item"
                :label="$t('name') + '*'"
                clearable="true"
                :rules="[requiredRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="userFormItem.firstname" class="form-item"
                :label="$t('firstname') + '*'"
                clearable="true"
                :rules="[requiredRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="userFormItem.email" class="company-form-item"
                :rules="[requiredRule, emailRule]"
                label="Email*"
                clearable="true"
                density="compact"
                variant="outlined"
            ></v-text-field>
<!--            <v-select-->
<!--                :label="$t('role') + '*'"-->
<!--                v-model="userFormItem.role" class="company-form-item"-->
<!--                :rules="[requiredRule]"-->
<!--                :items="['Accountant', 'Client']"-->
<!--                density="compact"-->
<!--                variant="outlined"-->
<!--                readonly-->
<!--            ></v-select>-->
            <v-text-field
                v-model="userFormItem.phone" class="form-item"
                :label="$t('telephone') + '*'"
                :rules="[requiredRule, phoneRule]"
                clearable="true"
                density="compact"
                variant="outlined"
            ></v-text-field>
          </v-form>
        </v-stepper-window-item>
        <v-stepper-window-item
            value="2"
            class="register-window">
          <div class="companies-container">
            <v-text-field v-model="searchText" :label="$t('leftmenu.search')"
                          hide-details variant="solo" density="compact" style="margin: 0 10px 10px;"></v-text-field>
            <span class="companies-text">{{ $t('companies.assigned') }}</span>
            <br/>
            <v-chip v-for="company in assignedCompanies" :key="company.id" color="secondary"
                    style="margin: 5px 5px 0 0;" @click="removeCompanyAssociation(company)">
              {{ company.name }}
              <v-btn v-if="!cart.mycompany || company.id !== cart.mycompany.id || currentRole !== 'Employee'" size="x-small" icon="mdi-delete" @click="removeCompanyAssociation(company)" style="margin-left: 10px;">
                <v-icon size="small">mdi-delete</v-icon>
              </v-btn>
            </v-chip>
          </div>
            <v-divider style="margin: 10px 0"></v-divider>

<!--          <div style="display: flex; margin: 10px 0; height: 50px;">-->
<!--            <v-divider style="margin: 25px 0"></v-divider>-->
<!--            <v-btn icon="mdi-arrow-up"></v-btn>-->
<!--            <v-divider style="margin: 25px 0"></v-divider>-->
<!--          </div>-->
          <div class="companies-container">
            <span class="companies-text">{{ $t('companies.unassigned') }}</span>
            <br/>
            <v-chip v-for="company in unassignedCompanies" :key="company.id" color="secondary"
                    style="margin: 5px 5px 0 0;" @click="addCompanyAssociation(company)">
              {{ company.name }}
              <v-btn size="x-small" icon="mdi-plus" @click="addCompanyAssociation(company)" style="margin-left: 10px;">
                <v-icon size="small">mdi-plus</v-icon>
              </v-btn>
            </v-chip>
          </div>
        </v-stepper-window-item>
        <v-stepper-window-item
            value="3"
            class="register-window">

          <div class="overview-card request-category">
            <v-btn size="x-small" icon="mdi-account" color="light-green-darken-1" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
            <span style="margin: 5px calc(50% - 42px);" class="overview-card-title">{{ $t('details') + ' ' + $t('register.account') }}</span>
            <div class="overview-card-content">
              <span>{{$t('name') + ": " + userFormItem.lastname}}</span>
              <span>{{$t('firstname') + ": " + userFormItem.firstname}}</span>
              <span>{{'Email' + ": " + userFormItem.email}}</span>
              <span>{{$t('role') + ": " + userFormItem.role}}</span>
              <span>{{$t('telephone') + ": " + userFormItem.phone}}</span>
            </div>
          </div>
          <div class="overview-card request-category add-user-overview">
            <v-btn size="x-small" icon="mdi-domain" color="indigo-lighten-2" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
            <span style="margin: 5px calc(50% - 21px);" class="overview-card-title">{{$t('companies')}}</span>
            <div class="companies-container">
              <v-chip v-for="company in assignedCompanies" :key="company.id" color="secondary"
                      style="margin: 5px 5px 0 0;">{{ company.name }}</v-chip>
            </div>
          </div>
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
    <v-card-actions :style="mq.current !== 'mobile' ? '' : 'position: fixed;'">
      <v-btn class="cancel-btn" variant="tonal" @click="prev">{{ step > 0 ? $t('back') : $t('close')}}</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="success-btn" variant="tonal" @click="next">{{ step === 2 ? $t(submitType) : $t('next') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import {defineProps} from "vue";
import {cart} from "@/services/cart";
const props = defineProps(['submitType', 'currentRole'])
</script>

<script>
import i18n from "@/i18n";
import SubscriptionService from "@/services/SubscriptionService";
import UserService from "@/services/UserService";
import {rules} from "@/services/rules";

export default {
  name: "PeopleStepProcess",
  inject: ['mq'],
  data: () => ({
    validForm: true,
    requiredRule: rules.value.required,
    emailRule: rules.value.email,
    phoneRule: rules.value.phone,
    searchText: ''
  }),
  computed: {
    userFormItem() {
      let formItem = UserService.getFormItem();
      return formItem;
    },
    step() {
      return cart.value.userFormStep;
    },
    assignedCompanies() {
      let formItem = this.userFormItem;
      if (!formItem.companyIds) return [];
      let assignedCompanies = cart.value.companies.filter(company => {
        return company.name &&
            (!this.searchText || company.name.toLowerCase().includes(this.searchText.toLowerCase()) || company.cui.includes(this.searchText)) &&
            (formItem.companyIds.includes(company.id) || formItem.defaultCompanyId === company.id);
      });
      // if (cart.value.mycompany && !assignedCompanies.find(comp => comp.id === cart.value.mycompany.id)) {
      //   assignedCompanies.push(cart.value.mycompany);
      // }
      return assignedCompanies;
    },
    unassignedCompanies() {
      let formItem = UserService.getFormItem();
      if (!formItem.companyIds)
        return cart.value.companies.filter(company => company.name && (!this.searchText ||
            company.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
            company.cui.includes(this.searchText))
            // (cart.value.mycompany && cart.value.mycompany.id !== company.id)
        );
      return cart.value.companies.filter(company => {
        return company.name && !formItem.companyIds.includes(company.id) &&
            (!this.searchText || company.name.toLowerCase().includes(this.searchText.toLowerCase()) || company.cui.includes(this.searchText)) &&
            formItem.defaultCompanyId !== company.id
      });
    }
  },
  methods: {
    setStep(step) {
      if (step > 0 && !this.validForm) {
        this.invalidateForm();
        return;
      }
      cart.value.userFormStep = step;
    },
    prev() {
      if (cart.value.userFormStep > 0)
        cart.value.userFormStep--;
      else
        this.$emit('closeDialog', false);
    },
    next() {
      if (this.currentRole === 'Employee' && cart.value.userFormStep === 0 && cart.value.mycompany) {
        this.addCompanyAssociation(cart.value.mycompany);
      }
      if (cart.value.userFormStep === 0) {
        if (!this.validForm) {
          this.invalidateForm();
          return;
        } else if (this.submitType !== 'edit') {
          UserService.getUserByEmail(this.userFormItem.email).then(response => {
            if (response !== null && response.data && response.data.id) {
              cart.value.showAlertDetails(i18n.global.t('email.exists'));
            }
          }).catch(() => {
            cart.value.userFormStep++;
          });
        } else if (this.submitType === 'edit') {
          cart.value.userFormStep++;
          return;
        }
      }
      if (cart.value.userFormStep === 2) {
        this.addOrEdit();
        return;
      }
      if (cart.value.userFormStep !== 0 && cart.value.userFormStep < 2)
        cart.value.userFormStep++;

    },
    addOrEdit() {
      if (this.submitType === 'add') {
        this.addUser();
      } else if (this.submitType === 'edit') {
        this.editUser();
      }
    },
    invalidateForm() {
      console.log("Invalid form");
      // cart.value.showAlertDetails(i18n.global.t('form.validate'));
      this.$refs.userForm.validate();
    },
    addUser() {
      let user = UserService.getLoggedUser();
      user = UserService.getUserByIdLocally(user.id);
      let userDTO = this.userFormItem;
      if (!this.validForm) {
        console.log("Invalid add form");
        cart.value.showAlertDetails(i18n.global.t('form.validate'));
        this.$refs.addForm.validate();
        return;
      }
      cart.value.startLoadingVar('appLoader');

      const employeeNumber = cart.value.employees.filter(employee => employee.role === 'Accountant' || employee.role === 'Employee').length;
      if (!SubscriptionService.validateUserNumber(employeeNumber) && (userDTO.role === 'Accountant' || userDTO.role === 'Employee')) {
        cart.value.showAlertDetails(i18n.global.t('subscription.error.people1') + cart.value.selectedSubscription.maxUsers + i18n.global.t('subscription.error.people2'), true)
        this.$emit('closeDialog', false);
        cart.value.appLoader = false;
        return;
      }
      if (this.currentRole === 'Employee') userDTO.role = "Employee";
      if (cart.value.mycompany && this.currentRole === 'Employee') {
        userDTO.defaultCompanyId = cart.value.mycompany.id;
      }

      if (userDTO.companyIds && userDTO.companyIds.length > 0)
        userDTO.companyIds = [...new Set(userDTO.companyIds)];
      UserService.add(user.id, userDTO).then(user => {
        if (!user.data.id) {
          cart.value.appLoader = false;
          cart.value.showAlertDetails(i18n.global.t('employee.exists'));
          return;
        }
        if (cart.value.mycompany && !user.data.companyIds.includes(cart.value.mycompany.id)) {
          UserService.associateCompany(user.data.id, cart.value.mycompany.id).then(result => {
            cart.value.employees.push(result.data);
          });
        } else {
          cart.value.employees.push(user.data);
        }
        cart.value.appLoader = false;
        this.$emit('closeDialog', false);
        cart.value.showAlertDetails(i18n.global.t('user') + i18n.global.t('was') + i18n.global.t('created2') + i18n.global.t('withsuccess'))
      });
    },
    editUser() {
      if (!this.validForm) {
        console.log("Invalid edit form");
        cart.value.showAlertDetails(i18n.global.t('form.validate'))
        this.$refs.editForm.validate();
        return;
      }
      let userDTO = this.userFormItem;
      cart.value.startLoadingVar('appLoader');
      if (userDTO.role === i18n.global.t('employee')) userDTO.role = "Accountant";
      UserService.edit(userDTO).then(user => {
        let cmp = cart.value.employees.find(c => c.id === user.data.id);
        cmp.name = user.data.name;
        cmp.firstname = user.data.firstname;
        cmp.lastname = user.data.lastname;
        cmp.email = user.data.email;
        cmp.role = user.data.role;
        cmp.phone = user.data.phone;
        this.$emit('closeDialog', false);
        cart.value.appLoader = false;
        cart.value.showAlertDetails((userDTO.role === 'Accountant' ? i18n.global.t('employee2') : i18n.global.t('admin2')) + i18n.global.t('was') + i18n.global.t('edited2') + i18n.global.t('withsuccess'))
      });
    },
    stepComplete(status) {
      return status <= cart.value.userFormStep;
    },
    addCompanyAssociation(company) {
      if (!company || !company.id) return;
      let formItem = UserService.getFormItem();
      if (!formItem || !formItem.companyIds) formItem.companyIds = [];
      if (!formItem.companyIds.includes(company.id))
        formItem.companyIds.push(company.id);
      UserService.setFormItem(formItem);
    },
    removeCompanyAssociation(company) {
      if (!company || !company.id) return;
      let formItem = UserService.getFormItem();
      let id = formItem.companyIds.indexOf(company.id);
      if (id === -1) return;
      formItem.companyIds.splice(id, 1);
      UserService.setFormItem(formItem);
    }
  }
}
</script>

<style scoped>

.request-category {
  display: grid;
  /*margin: 10px;*/
  border: 1px solid rgb(var(--v-theme-leftMenu));
  padding: 10px;
  border-radius: 5px;
  /*box-shadow: 1px 2px 3px 3px #e6e6e6;*/
  margin: 10px 5px;
  /*background: rgb(var(--v-theme-leftMenu));*/
}

.overview-card-content {
  display: grid;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.companies-text {
  opacity: 0.5;
}

.add-user-overview {
  margin-bottom: 50px;
}

</style>