<template>
  <div id="main-content" @contextmenu="showMainContextWindow($event)" class="content-page-main rectangle-select"
       @drop.self="onFileDrop($event, null)" @dragover.prevent @dragenter.prevent @drop.prevent
       @dragover.self="dragenter" @dragleave.self="dragleave"
  >
    <table-filters uploadDocumentsBtnOn search-on my-company-btn-on></table-filters>
    <v-row style="margin: 5px 0;" class="rectangle-select" @dragover.self="dragenter">
      <v-btn size="small" id="folder-back" color="primary" style="height: 36px; border-radius: 5px 0 0 5px; margin-top: 10px;"
             @click="goBack" :disabled="folderData.historyBack.length < 2">
        <v-icon>mdi-arrow-left</v-icon>
        <tooltip-message :message="$t('listview.tooltip')"/>
      </v-btn>
      <v-btn size="small" id="folder-forward" style="height: 36px; border-radius: 0 5px 5px 0; margin-top: 10px;"
             @click="goToMyFiles">
<!--      <v-btn size="small" id="folder-forward" color="primary" style="display:none; border-radius: 0 5px 5px 0; margin-top: 10px;"-->
<!--             @click="goForward">-->
        <v-icon color="primary">mdi-home</v-icon>
<!--        <tooltip-message :message="$t('gridview.tooltip')"/>-->
        <tooltip-message :message="$t('folder.home')"/>
      </v-btn>
      <v-breadcrumbs :items="folderData.breadcrumbItems">
        <template v-slot:title="{ item }">
          <span @click="goToSection(item)" :id="item.position ? 'breadcrumb-' + item.position : ''"
                style="color: rgb(var(--v-theme-primary)); padding: 10px 15px; border-radius: 5px; user-select: none;"
                @drop="onFileDrop($event, item)" @dragover.prevent @dragenter.prevent @drop.prevent
                @dragover="startHover($event, item, 'breadcrumb')" @dragleave="stopHover($event, item, 'breadcrumb')">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-row>
    <span v-if="getDirectoryItems.length === 0 && getContentItems.length === 0">{{ $t('directory.addfile') }}</span>
    <!--    Directories VIEW -->
    <span v-if="getDirectoryItems.length !== 0" style="margin-left: -5px; color: rgb(90,90,90); user-select: none;">
      <v-icon size="small" style="opacity: 0.25; margin-right: 5px;">mdi-folder</v-icon>
      {{ folderData.hideDirectories ? $t('folder.home.text') : $t('directories') }}
    </span>
    <div class="content-container rectangle-select" @dragover.self="dragenter"
         @drop.self="onFileDrop($event, null)" @dragover.prevent @dragenter.prevent @drop.prevent
    >
      <!--      <span v-if="getDirectoryItems.length === 0" style="position: absolute;">{{ $t('directory.nodirectory') }}</span>-->
      <v-card
          class="mx-auto content-item"
          v-for="item in getDirectoryItems"
          :key="item.id"
          :id="'parent-' + item.id"
          elevation="2"
          :height="100"
          draggable="true" @dragstart="startFileDrag($event, item.id)" @dragend="stopFileDrag(item.id)"
          @dragover="dragleave"
      ><div class="content-body" :id="'card-' + item.id" @click="itemAction(item, $event)"
            @contextmenu="showContext($event, item)"
            @drop="onFileDrop($event, item)" @dragover.prevent @dragenter.prevent @drop.prevent
            @dragover="startHover($event, item, 'card')" @dragleave="stopHover($event, item, 'card')"
      >
        <tooltip-message :message="item.title" open-delay="400"/>
        <v-card-title class="content-item-title">
          <v-icon size="small" :color="item.color ? item.color : 'primary'">{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-card-title>
        <v-card-text class="content-item-text">{{ item.details }}</v-card-text>
        <v-card-text class="content-item-text" style="font-size: 11px; display: flex;"><span>{{ item.details2 }}</span><v-spacer></v-spacer><span>{{ item.details3 }}</span></v-card-text>
      </div>
        <v-menu location-strategy="connected" :target="[x, y]" v-model="item.showContext" style="min-width: 150px; max-width: 150px; width: 150px; height: fit-content;">
          <v-list width="150" slim>
            <v-list-item
                v-for="(contextItem, index) in folderContextMenuItems"
                :key="index"
                :value="index"
                style="min-height: 30px; padding-left: 5px;"
                @click="contextItem.onclick($event, item)"
                :disabled="contextItem.disabled"
            >
              <template v-slot:prepend>
                <v-icon :icon="contextItem.icon" color="#a4a4a4" size="small" style="width: 28px;"></v-icon>
              </template>
              <v-list-item-title style="font-size: 14px;">{{ contextItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>
    </div>
    <span v-if="getContentItems.length !== 0" style="margin-left: -5px; color: rgb(90,90,90); user-select: none;">
      <v-icon size="small" style="opacity: 0.25; margin-right: 5px;">{{ folderData.hideDirectories ? 'mdi-office-building' : 'mdi-file' }}</v-icon>
      {{ folderData.hideDirectories ? $t('companies') : $t('files') }}
    </span>
    <div class="content-container rectangle-select" @dragover.self="dragenter"
         @drop.self="onFileDrop($event, null)" @dragover.prevent @dragenter.prevent @drop.prevent>
<!--      FILES VIEW    -->
      <v-card
          class="mx-auto content-item"
          v-for="item in getContentItems"
          :key="item.id"
          :id="'parent-' + item.id"
          elevation="2"
          :height="100"
          draggable="true" @dragstart="startFileDrag($event, item.id)" @dragend="stopFileDrag(item.id)"
      >
        <div class="content-body" :id="'card-' + item.id" @click="itemAction(item, $event)"
             @contextmenu="showContext($event, item)"
             @drop="onFileDrop($event, item)" @dragover.prevent @dragenter.prevent @drop.prevent
        >
          <tooltip-message :message="item.title" open-delay="400"/>
          <v-card-title class="content-item-title">
            <v-icon size="small" :color="item.color ? item.color : 'primary'">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-card-title>
          <v-card-text class="content-item-text">{{ item.details }}</v-card-text>
          <v-card-text class="content-item-text" style="font-size: 11px; display: flex;">
            <span>{{ item.details2 }}</span>
            <v-spacer></v-spacer>
            <span :style="item.isCompany ? 'font-weight: 600;' : ''">{{ item.details3 }}</span></v-card-text>
        </div>
        <v-menu v-if="item.type === 'file'" location-strategy="connected" :target="[x, y]" v-model="item.showContext"
                style="min-width: 180px; max-width: 180px; width: 180px; height: fit-content;">
          <v-list width="180" slim>
            <v-list-item
                v-for="(contextItem, index) in fileContextMenuItems"
                :key="index"
                :value="index"
                style="min-height: 30px; padding-left: 5px;"
                @click="contextItem.onclick($event, item)"
                :disabled="contextItem.disabled"
            >
              <template v-slot:prepend>
                <v-icon :icon="contextItem.icon" color="#a4a4a4" size="small" style="width: 28px;"></v-icon>
              </template>
              <v-list-item-title style="font-size: 14px;">{{ contextItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>

      <v-menu v-model="showMainContext" location-strategy="connected" :target="[x, y]" style="min-width: 150px; max-width: 150px; width: 150px; height: fit-content;">
        <v-list width="170" slim>
          <v-list-item
              v-for="(item, index) in mainContextMenuItems"
              :key="index"
              :value="index"
              style="min-height: 30px; padding-left: 5px;"
              @click="item.onclick()"
              :disabled="item.disabled"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon" color="#a4a4a4" size="small" style="width: 28px;"></v-icon>
            </template>
            <v-list-item-title style="font-size: 14px;">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>

  <v-dialog
      v-model="folderData.deleteDialog"
      max-width="500px"
      class="icarus-dialog"
  >
    <v-card>
      <span size="big" class="dialog-title">{{ $t('file.delete.confirmation') }}</span>
      <v-card-actions>
        <v-btn class="cancel-btn" variant="tonal" @click="folderData.deleteDialog = false">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success-btn delete-btn" variant="tonal" @click="deleteFile()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
      v-model="folderData.moveDialog"
      max-width="400px"
      class="icarus-dialog"
  >
    <v-card>
      <span class="dialog-title" size="big">{{ $t('move') + " " + folderData.moveItem.title }}</span>
      <span style=" margin: 10px 15px; font-size: 16px;">{{ $t('folder.location') + ": /" + cart.company.name + (folderData.moveItem.path !== null ? folderData.moveItem.path : '/') }}</span>
      <v-text-field v-model="folderData.folderSearch" :label="$t('directory.search')"
                    hide-details variant="solo" density="compact" style="margin: 0 10px;" autofocus></v-text-field>
      <span style=" margin: 10px 15px; font-size: 14px;">{{ $t('folder.move') }}</span>
      <v-divider></v-divider>
      <v-checkbox v-for="item in getDirectories" :class="'move-folder move-folder-' + item.id" :key="item.id" :label="item.title" :value="item.id"
                  density="compact" hide-details @mousedown="moveFileSelect(item)">
        <template v-slot:label>
          <v-icon size="small" color="#a4a4a4" style="margin-right: 8px;">mdi-folder</v-icon><span>{{ item.filename }}</span>
        </template>
        <template v-slot:input>
        </template>
      </v-checkbox>
      <v-divider></v-divider>
      <span style="margin: 15px; font-size: 14px; font-weight: bold;">{{ folderData.moveFilePath ? folderData.moveFilePath : $t('folder.select') }}</span>

      <v-card-actions id="request-details-buttons" style="background: rgb(var(--v-theme-primary)); min-height: 40px; padding: 0;">
        <v-btn class="cancel-btn" variant="tonal" @click="folderData.moveDialog = false">
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success-btn" variant="tonal" @click="moveFile()">{{ $t('move') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog
      v-model="folderData.addDialog"
      max-width="400px"
      class="icarus-dialog"
  >
    <v-card>
      <span size="big" class="dialog-title">{{ $t('folder.create.name') }}</span>
      <v-text-field v-model="folderData.fileName"
                    :label="$t('folder.name')"
                    class="folder-name"
                    density="compact"
                    autofocus
                    variant="outlined">
        <template v-slot:append>
          <span>{{ folderData.fileExtension }}</span>
        </template>
      </v-text-field>
      <v-card-actions>
        <v-btn class="cancel-btn" size="small" variant="tonal" @click="folderData.addDialog = false">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success-btn" variant="tonal" @click="createOrRenameDirectory()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <input type="file" id="upload-files-input" multiple @change="uploadFiles" style="display: none;">
  <input class="directory-upload" id="upload-directory-input" type="file" directory webkitdirectory
         mozdirectory multiple @change="uploadDirectory" ref="uploader" style="display: none;">
  <div id="selectionRectangle"></div>
</template>

<script setup>
import TableFilters from "@/components/TableFilters";
import TooltipMessage from "@/components/mini/Tooltip";
import {cart} from "@/services/cart";
</script>

<script>
import i18n from "@/i18n";
import {folderData, processDirectory} from "@/services/FolderService";
import FolderService from "@/services/FolderService";
import FileService from "@/services/FileService";
import UserService from "@/services/UserService";
import printJS from "print-js";
import SelectionService from "@/services/SelectionService";
import Helper from "@/services/Helper";
import PreviewService from "@/services/PreviewService";

let time = null;

export default {
  name: "FolderSystem",
  inject: ['mq'],
  mounted() {
    FolderService.addBreadcrumbItem(0, i18n.global.t('leftmenu.companies'), '');
    if (cart.value.company) {
      FolderService.selectCompany(cart.value.company);
      FolderService.addBreadcrumbItem(1, cart.value.company.name, '');
    }
    this.$watch(
        () => {
          return cart.value.company;
        },
        (company) => {
          if (!company || !company.name) return;
          FolderService.selectCompany(company);
          FolderService.addBreadcrumbItem(1, company.name, '');
        }
    )
    this.showContextMenu = false;
    this.setEventListeners();
    // Helper.sendFbEvent("trackCustom", "documents");
  },
  data: () => ({
    historyForward: [],
    draggedId: null,
    selectedCompany: null,
    previewFileSrc: null,
    filePreviewDialog: false,
    dragging: false,
    hovering: false,
    showMainContext: false,
    showContextMenu: true,
    movePath: null,
    x: 0,
    y: 0,
    isDrawing: false,
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
    timer: null,
    counter: 0,
  }),
  computed: {
    getContentItems() {
      return folderData.value.contentItems.filter(item => {
        if (item.type === 'directory' || item.isMainCompany) return false;
        return !cart.value.search || cart.value.search.length === 0 ||
            item.title.toLowerCase().includes(cart.value.search.toLowerCase()) ||
            item.details.toLowerCase().includes(cart.value.search.toLowerCase());
      })
    },
    getDirectoryItems() {
      return folderData.value.contentItems.filter(item => {
        if (item.type === 'file' || (item.type === 'company' && !item.isMainCompany)) return false;
        return !cart.value.search || cart.value.search.length === 0 ||
            item.title.toLowerCase().includes(cart.value.search.toLowerCase()) ||
            item.details.toLowerCase().includes(cart.value.search.toLowerCase());
      })
    },
    getDirectories() {
      let directories = cart.value.filesData;
      directories.push({'filename': cart.value.company.name, 'id': cart.value.company.id});
      return directories.filter(fileData => {
        return fileData.companyId === cart.value.company.id && fileData.directory
            && fileData.filename.toLowerCase().indexOf(folderData.value.folderSearch.toLowerCase()) > -1
            // && (!folderData.value.folderSearch || folderData.value.folderSearch === '' ||
            //     fileData.filename.toLowerCase() === folderData.value.folderSearch.toLowerCase())
            ;
      });
    },
    folderContextMenuItems() {
      folderData.value.folderContextMenuItems.forEach((item) => {
        item.title = i18n.global.t(item.title);
      })
      return folderData.value.folderContextMenuItems;
    },
    mainContextMenuItems() {
      folderData.value.mainContextMenuItems.forEach((item) => {
        item.title = i18n.global.t(item.title);
      })
      return folderData.value.mainContextMenuItems;
    },
    fileContextMenuItems() {
      folderData.value.fileContextMenuItems.forEach((item) => {
        item.title = i18n.global.t(item.title);
      })
      return folderData.value.fileContextMenuItems;
    },
  },
  methods: {
    showMainContextWindow(e) {
      e.preventDefault();
      if (folderData.value.hideDirectories) return;
      let showContext = true;
      folderData.value.contentItems.forEach((item) => {
        if (item.showContext) showContext = false;
      });
      if (!showContext) return;
      this.showMainContext = true;
      this.x = e.clientX;
      this.y = e.clientY;
      if (folderData.value.clipboardItems.length === 0) {
        FolderService.disableContexts(folderData.value.mainContextMenuItems, ["paste"]);
      }
      SelectionService.deselectAll();
    },
    showContext(e, item) {
      e.preventDefault();
      if (item.type === 'company') return;
      folderData.value.contentItems.forEach((item) => {
        item.showContext = false;
      });
      item.showContext = true;
      this.showMainContext = false;
      this.x = e.clientX;
      this.y = e.clientY;
      FolderService.enableAllContexts();
      if (item.type === 'directory' && folderData.value.clipboardItems.length === 0) {
        FolderService.disableContexts(folderData.value.folderContextMenuItems, ["paste"]);
      }
      if (item.type === 'directory' && folderData.value.selectedItems.size > 1) {
        FolderService.disableContexts(folderData.value.folderContextMenuItems, ["open", "rename", "paste"]);
      } else if (item.type === 'file' && folderData.value.selectedItems.size > 1) {
        FolderService.disableContexts(folderData.value.fileContextMenuItems, ["downloadpdf", "rename"]);
      }
      if (Array.from(folderData.value.selectedItems).find(selectItem => selectItem.id === item.id)) return;
      this.itemClick(item, e);
    },
    keyPressEvents(event) {
      SelectionService.keysHandler(event);
    },
    deselectAll() {
      SelectionService.deselectAll();
      folderData.value.lastSelectedItem = null;
    },
    itemAction(item, event) {
      event.stopPropagation();
      this.counter++;

      if(this.counter === 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
          this.itemClick(item, event);
        }, 200);

        return;
      }
      this.itemDoubleClick(item, event);
    },
    itemClick(item, event) {
      event.preventDefault();
      if (item === null) {
        SelectionService.deselectAll();
        SelectionService.removeUploadDesign(null);
        return;
      }

      // SHIFT HELD
      if (SelectionService.shiftClick(item, event)) return;

      // NO KEY HELD
      if (!event.ctrlKey && !event.shiftKey) {
        SelectionService.deselectAll();
      }

      // Deselect if item clicked and already selected or select if not
      if (folderData.value.selectedItems.has(item)) {
        SelectionService.deselectItem(item);
      } else {
        SelectionService.addSelectedItem(item);
      }

      SelectionService.setSelectedItemsDesign();
    },
    itemDoubleClick(item, event) {
      SelectionService.enterItem(item, event, this.mq.current === 'mobile')
    },
    uploadDirectory(e) {
      // This is used only for the folder upload button from the context menu
      // obs. this is not used when the user drops or pastes a directory into the app
      const files = FileService.getFilesFromEvent(e);

      if (!files.length) return;

      let filePaths = FolderService.createDirectories(files);
      FileService.createFiles(files, null, filePaths, folderData.value.currentCompany);
    },
    uploadFiles(e, path=null) {
      // This is also used when the user uploads a directory via drop or paste
      const files = FileService.getFilesFromEvent(e);

      if (!files.length) {
        this.dragging = false;
        return;
      }

      if (folderData.value.currentFile && folderData.value.currentFile.path) path = folderData.value.currentFile.path;
      if (!path) path = '';
      //This happens if user drags the files on the screen instead of using the upload button
      if (e instanceof DragEvent || e instanceof ClipboardEvent) {
        FolderService.processRootDirectory(e).then(() => {
          FolderService.uploadDirectories(path);
          FolderService.getFilesContent().then(() => {
            let paths = folderData.value.uploadFiles.map(file => path + file.path);
            FileService.createFiles(folderData.value.uploadFiles, null, paths);
          });
        });
      } else {
        FileService.createFiles(files, null, Array(files.length).fill(path), folderData.value.currentCompany);
      }
    },
    goToSection(item) {
      //Clicked on one of the breadcrumbs link
      SelectionService.goToSection(item);
    },
    goBack() {
      SelectionService.goBack();
    },
    goToMyFiles() {
      // FolderService.selectCompany(cart.value.mycompany);
      FolderService.loadCompanies();
      FolderService.resetBreadcrumbs(1);
      // FolderService.addBreadcrumbItem(0, cart.value.mycompany.name, '');
    },
    moveFileSelect(item) {
      const directories = document.getElementsByClassName('move-folder');
      for (let i = 0; i < directories.length; i++) {
        directories[i].style.background = 'none';
        directories[i].style.color = 'black';
      }

      const elem = document.getElementsByClassName('move-folder-' + item.id)[0];
      elem.style.background = 'rgb(var(--v-theme-primary))';
      elem.style.color = 'white';
      folderData.value.moveFilePath = item.path;
      if (item.id && !item.path)
        folderData.value.moveFilePath = '/';
      // elem.style.color = 'white';
    },
    pasteClipboard(ev) {
      this.onFileDrop(ev, null);
    },
    startFileDrag(e, item) {
      this.draggedId = item;
      let selectedIds = Array.from(folderData.value.selectedItems).map(item => item.id);
      if (selectedIds.includes(item)) {
        folderData.value.selectedItems.forEach(selected => {
          document.getElementById('parent-' + selected.id).style.opacity = '.4';
        })
      } else {
        SelectionService.deselectAll();
        SelectionService.addSelectedItem({'id': item});
        document.getElementById('parent-' + item).style.opacity = '.4';
      }
      if (!(e instanceof DragEvent)) {
        e.preventDefault();
      }
      this.dragging = true;
    },
    stopFileDrag(item) {
      this.dragging = false;
      SelectionService.removeUploadDesign();
      if (!this.draggedId) return;
      folderData.value.selectedItems.forEach(selected => {
        document.getElementById('parent-' + selected.id).style.opacity = '1';
      });
      this.draggedId = null;
    },
    onFileDrop(e, droppedOverItem) {
      // User dropped file directly on the screen not on directory
      if (droppedOverItem === null) {
        if (folderData.value.currentFile) droppedOverItem = folderData.value.currentFile;
        else if (cart.value.company) {
          droppedOverItem = cart.value.company;
          droppedOverItem.path = null;
        } else {
          return;
        }
      }
      //File dragged over companies view
      if (folderData.value.hideDirectories) {
        cart.value.showAlertDetails(i18n.global.t('companyselect'));
        this.stopFileDrag();
        return;
      }
      console.log("File dropped on " + droppedOverItem.id);
      this.stopHover(null, droppedOverItem, 'card');
      if (droppedOverItem.id === this.draggedId) {
        this.stopFileDrag();
        return;
      }
      const files = FileService.getFilesFromEvent(e);
      // User dragged a file from local system and not app
      if (!this.draggedId && files.length) {
        // or adjust the method to upload both files and directories
        this.uploadFiles(e, droppedOverItem.path);
        this.stopFileDrag();
        return;
      }

      if (droppedOverItem.type !== 'directory' && !droppedOverItem.position) {
        this.stopFileDrag();
        return;
      }
      if (droppedOverItem.position) this.stopHover(null, droppedOverItem, 'breadcrumb');
      FolderService.move(this.draggedId, droppedOverItem.path);
      this.stopFileDrag();
    },
    startHover(e, item, itemType) {
      if (!item || item.id === this.draggedId) return;
      if (itemType === 'breadcrumb') {
        if (!document.getElementById(itemType + '-' + item.position)) return;
        document.getElementById(itemType + '-' + item.position).style.background = 'rgb(var(--v-theme-primary))';
        document.getElementById(itemType + '-' + item.position).style.color = 'black';
      } else {
        if (!document.getElementById(itemType + '-' + item.id)) return;
        SelectionService.setUploadDesign(itemType + '-' + item.id);
        // document.getElementById(itemType + '-' + item.id).style.background = '#f1f1f1';
      }
    },
    stopHover(e, item, itemType) {
      if (!item) return;
      if (itemType === 'breadcrumb') {
        if (!document.getElementById(itemType + '-' + item.position)) return;
        document.getElementById(itemType + '-' + item.position).style.background = 'none';
        document.getElementById(itemType + '-' + item.position).style.color = 'rgb(var(--v-theme-primary))';
      } else {
        if (!document.getElementById(itemType + '-' + item.id)) return;
        SelectionService.removeUploadDesign(null);
        // document.getElementById(itemType + '-' + item.id).style.background = 'none';
      }
    },
    startSelectionCanvas(e) {
      if (!e.target.classList.contains("rectangle-select")) return;
      if (!e || e.button !== 0) {
        return;
      }

      this.itemClick(null, e);

      const selectionRectangle = document.getElementById('selectionRectangle');
      if (!selectionRectangle) return;

      this.isDrawing = true;
      this.startX = e.clientX;
      this.startY = e.clientY;

      selectionRectangle.style.left = this.startX + 'px';
      selectionRectangle.style.top = this.startY + 'px';
      selectionRectangle.style.width = '0';
      selectionRectangle.style.height = '0';
      selectionRectangle.style.display = 'block';
    },
    stopSelectionCanvas(e) {
      const selectionRectangle = document.getElementById('selectionRectangle');
      this.isDrawing = false;
      if (!selectionRectangle) return;
      selectionRectangle.style.display = 'none';
    },
    moveSelectionCanvas(e) {
      if (!this.isDrawing) return;
      const selectionRectangle = document.getElementById('selectionRectangle');
      if (!selectionRectangle) return;

      this.endX = e.clientX;
      this.endY = e.clientY;

      const rectWidth = Math.abs(this.endX - this.startX);
      const rectHeight = Math.abs(this.endY - this.startY);

      selectionRectangle.style.width = rectWidth + 'px';
      selectionRectangle.style.height = rectHeight + 'px';
      selectionRectangle.style.left = (this.endX < this.startX) ? this.endX + 'px' : this.startX + 'px';
      selectionRectangle.style.top = (this.endY < this.startY) ? this.endY + 'px' : this.startY + 'px';

      const elements = SelectionService.rectangleSelect("content-item", this.startX, this.startY, this.endX, this.endY);
      // if (e.type === 'click' && e.button === 0 && this.isDrawing)
      SelectionService.deselectAll();
      if (elements.length === 0) return;
      elements.forEach(elem => {
        SelectionService.setSelectedElementDesign(elem);
      });
    },
    dragenter(e) {
      if (!this.draggedId)
        SelectionService.setUploadDesign('main-content');
    },
    dragleave(e) {
      SelectionService.removeUploadDesign('main-content');
    },
    createOrRenameDirectory() {
      FolderService.createOrRenameDirectory(folderData.value.fileName);
    },
    moveFile() {
      if (folderData.value.moveFilePath === '/') folderData.value.moveFilePath = null;
      FolderService.move(folderData.value.moveItem.id, folderData.value.moveFilePath);
    },
    downloadFile(ev, fileData) {
      FolderService.downloadFile(ev, fileData);
    },
    downloadFilePdf(ev, fileData) {
      FolderService.downloadFilePdf(ev, fileData);
    },
    openDeleteDialog(ev, item) {
      FolderService.openDeleteDialog(ev, item);
    },
    deleteFile() {
      FolderService.deleteFile();
    },
    previewFile(ev, item) {
      PreviewService.previewFile(item);
    },
    printFile() {
      printJS({printable: this.previewFileSrc, type: 'pdf'})
    },
    getUserById(userId) {
      return UserService.getUserByIdLocally(userId);
    },
    setEventListeners() {
      let content = document.getElementById('main-content')
      window.removeEventListener("keyup", this.keyPressEvents);
      window.addEventListener( "keyup", this.keyPressEvents);
      window.removeEventListener( "mousedown", this.startSelectionCanvas);
      window.addEventListener( "mousedown", this.startSelectionCanvas);
      window.removeEventListener( "mouseup", this.stopSelectionCanvas);
      window.addEventListener( "mouseup", this.stopSelectionCanvas);
      window.removeEventListener( "mousemove", this.moveSelectionCanvas);
      window.addEventListener( "mousemove", this.moveSelectionCanvas);
      document.removeEventListener('paste', this.pasteClipboard);
      document.addEventListener('paste', this.pasteClipboard);

      document.getElementById('main-content').addEventListener('contextmenu', this.showMainContextWindow);

    }
  },
}
</script>

<style scoped>

.content-item {
  width: 200px;
  height: 150px;
  /*border: none;*/
  /*box-shadow: none;*/
  /*background-image: url('../../assets/images/folder yellow nb.png');*/
  /*background-size: 200px 200px;*/
  /*background-position-y: -25px;*/
  /*padding-top: 20px;*/
}

.content-item-title {
  width: 200px;
  font-size: 17px;
}

.content-item-text, .content-item-title, .content-item {
  /*height: -webkit-fill-available;*/
}

.content-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 10px 10px 20px;
}

.content-container:first-child {
  margin-bottom: 20px;
}

.content-container:last-child {
  margin-top: 10px;
}

.content-item-text {
  padding: 2px 10px;
}

.content-body {
  height: 100px;
  transition: background-color .2s ease-in-out;
}

.content-body:hover {
  opacity: 0.9;
  background-color: #f0f0f0;
  /*background-color: #fafafa;*/
  cursor: pointer;
}

.v-theme--mainDarkTheme .content-body:hover {
  background-color: #2d2d2d;

}

#main-content {
  min-height: calc(100vh - 67px);
  /*min-height: 65vh;*/
  margin: 5px 5px 5px 205px;
  /* padding-bottom: 50px; */
  padding: 0px 20px 20px;
}

.folder-name {
  width: 80%;
  margin: 5% 0 0 10%;
}

.move-folder:hover {
  background: rgb(230, 230, 230);
  /*background: rgb(var(--v-theme-primary));*/
  cursor: pointer;
}

.move-folder:active {
  /*background: rgb(230, 230, 230);*/
  background: rgba(var(--v-theme-primary));
}

.move-folder:deep(.v-label) {
  opacity: .9;
}

.selected-item {
  background: rgb(var(--v-theme-primary)) !important;
  color: white;
}

.selected-item:hover {
  background: rgb(var(--v-theme-primary));
  /*color: black;*/
  opacity: 0.9;
}

#selectionRectangle {
  position: absolute;
  opacity: 0.3;
  border: 1px solid blue;
  background: rgb(var(--v-theme-primary));
}

.uploading {
  border: 2px solid rgb(var(--v-theme-primary));
  border-radius: 5px;
  background: rgba(var(--v-theme-primary), 0.3);
  color: white !important;
}
</style>