<template>
  <div class="support-container" v-if="!isFree()">
    <v-btn class="support-btn" size="smaller" @click="requestSupport" :style="mq.current === 'mobile' ? 'margin: 0;' : ''">
      <v-icon>mdi-message-question</v-icon>
    </v-btn>
  </div>
</template>

<script>
import i18n from "@/i18n";
import SubscriptionService from "@/services/SubscriptionService";

export default {
  name: "SupportOverlay",
  inject: ['mq'],
  methods: {
    requestSupport(subscription) {
      const supportAddress = "office@icarusdocs.com";
      const emailSub = i18n.global.t('support.mail.subject');
      let body = i18n.global.t('support.mail.body');
      // if (subscription) {
      //   body = i18n.global.t('support.mail.bodySubscribe') + subscription.name;
      // }
      window.open('mailto:' + supportAddress + '?subject=' + emailSub + '&body=' + body, '_self')
    },
    isFree() {
      return SubscriptionService.isFreeUser();
    }
  }
}
</script>

<style scoped>
.support-container {
  /*position: absolute;*/
  /*top: 0;*/
  /*right: 20px;*/
  /*margin-left: calc(100% - 80px);*/
  /*padding-top: 70px;*/
  /*z-index: 2500;*/
  /*height: 100%;*/
}

.support-btn {
  /*position:sticky;*/
  /*position:-webkit-sticky;*/
  margin: 0 10px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  font-size: medium;
}
</style>