<template>
  <v-card
      :class="mq.current === 'mobile' ? 'register-fail-card-mobile' : 'register-fail-card'"
  >
    <div class="fail-card">
      <v-icon size="x-large" style="margin: 0 calc(50% - 30px); cursor: default; font-size: 64px;" icon="mdi-close-circle"></v-icon>
      <span style="margin: 5px calc(50% - 85px);" class="overview-card-title">{{ $t('subscription.fail.title') }}</span>
    </div>
    <div class="overview-card-content">
      <span style="height: fit-content; margin: 5px 0;"> {{ $t('subscription.fail') }} </span>
      <div style="display: flex; font-size: 14px; height: fit-content; margin-top: 20px;">
        <span>{{ $t('subscription.fail.info') }} <span class="link-text" @click="requestSupport">{{ $t('contactus') }}</span></span>
      </div>
    </div>
    <div style="position: absolute; bottom: 10px; width: calc(100% - 10px);">
      <v-btn append-icon="mdi-account-plus" @click="goRegister" style="margin-left: 10px;">Register</v-btn>
      <v-btn prepend-icon="mdi-login" @click="goLogin" style="position:absolute; right: 10px;">Login</v-btn>
    </div>
  </v-card>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import router from "@/router";
import i18n from "@/i18n";
import Helper from "@/services/Helper";
import SettingsService from "@/services/SettingsService";

export default {
  name: "RegistrationFail",
  inject: ['mq'],
  mounted() {
    SubscriptionService.clearData();
    this.applyUserSettings();
    Helper.sendFbEvent("track", "RegistrationFail");
  },
  methods: {
    goRegister() {
      router.push({name: 'register'});
    },
    goLogin() {
      router.push({name: 'login'});
    },
    requestSupport() {
      const supportAddress = "office@icarusdocs.com";
      const emailSub = i18n.global.t('support.mail.subject');
      let body = i18n.global.t('support.mail.body');
      window.open('mailto:' + supportAddress + '?subject=' + emailSub + '&body=' + body, '_self')
    },
    applyUserSettings() {
      const settings = SettingsService.getUserSettings();
      i18n.global.locale.value = settings.Language;
      this.theme.global.name.value = settings.Theme;
    },
  }
}
</script>

<style scoped>

.register-fail-card {
  min-width: 400px;
  height: 350px;
  margin: 10px calc(50% - 200px) 20px;
  /*padding: 20px;*/
  overflow: auto;
}

.register-fail-card-mobile {
  width: 80%;
  height: 350px;
  margin: 10px 10% 20px;
  /*padding: 20px;*/
  overflow: auto;
}

.fail-card {
  margin-bottom: 20px;
  display: grid;
  /*margin: 10px;*/
  border: 1px solid rgb(var(--v-theme-leftMenu));
  padding: 10px;
  border-radius: 5px;
  /*box-shadow: 1px 2px 3px 3px #e6e6e6;*/
  /*margin: 10px 5px;*/
  color: white;
  background: rgb(var(--v-theme-error));
}

.overview-card-title {
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
}

.overview-card-content {
  display: grid;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.link-text {
  cursor: pointer;
  color: rgb(var(--v-theme-primary));
}

.link-text:hover {
  opacity: 0.7;
}

</style>