<template>
  <v-spacer></v-spacer>
  <v-card width="850" height="550" style="margin-left: calc(50% - 425px);" id="printContainer" :loading="cart.previewLoading">
    <v-btn size="small" icon="mdi-close" style="position: fixed; right: calc(50% - 425px); z-index: 2500;" @click="closeDialog()"></v-btn>
    <v-btn size="small" icon="mdi-printer" style="position: absolute; right: 50px; z-index: 10;" @click="printFile()" v-if="cart.previewPrintButton"></v-btn>
    <iframe id="ifrm" :src="previewFileSrc" :srcdoc="previewFileSrcDoc" width="800" height="500" style="margin: 25px 0 0 25px; border: none;"/>
<!--    <iframe id="ifrm" v-if="!previewFileSrcDoc" :src="previewFileSrc" width="800" height="500" style="margin: 25px 0 0 25px; border: none;"/>-->
<!--    <div id="editor">-->
<!--      <div id="editor-container">-->
<!--      </div>-->
<!--    </div>-->
  </v-card>
</template>

<script setup>
import {cart} from "@/services/cart";

</script>

<script>
import printJS from "print-js";
import SelectionService from "@/services/SelectionService";
// import Quill from "quill";
// import 'quill/dist/quill.snow.css';

export default {
  name: "PreviewDialog",
  // data: () => ({
  // }),
  props: {
    previewFileSrc: String,
    previewFileSrcDoc: String,
  },
  mounted() {
    // if (cart.value.previewFileSrcDoc) {
    //   this.quill = new Quill("#editor-container", {
    //     theme: 'snow',
    //   });
    //   document.getElementById("editor-container").innerHTML = cart.value.previewFileSrc;
    // }
  },
  methods: {
    printFile() {
      printJS({printable: this.previewFileSrc, type: 'pdf'})
    },
    closeDialog() {
      this.stopAudios();
      SelectionService.deselectAll();
      this.$emit('filePreviewDialog', false);
    },
    stopAudios() {
      const frames = window.frames;
      for (let i = 0; i < frames.length; i++) {
        const sounds = frames[i].document.getElementsByTagName('video');
        for (let j = 0; j < sounds.length; j++) {
          sounds[j].pause();
        }
      }
    }
  }
}
</script>

<style scoped>
td {
  background: black;
}

#editor-container {
  /*width: 100%;*/
  /*height: 500px;*/
}
</style>