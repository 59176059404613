<template>
  <div fluid class="password-forgot">
    <div :class="mq.current === 'mobile' ? 'login-main-container-mobile' : 'login-main-container'" class="d-flex justify-center" :style="theme.global.current.value.dark ? 'background-color: #212121;' : '' ">
      <v-sheet width="400" height="100vh" class="mx-auto login-fields-container">
        <locale-switcher style="opacity: 0.8;"></locale-switcher>
        <h2 class="title">{{ $t('passwordnewtitle') }}</h2>
        <p style="font-size: small; padding-bottom: 3rem; padding-top: 0.5rem; opacity: 0.7;">{{ $t('login.register.message') }} <a href="#">{{ $t('here') }}</a>.</p>
        <p style="font-size: small; opacity: 0.7; padding-bottom: 1rem; color: red;" v-if="invalidData">{{ $t('login.invalid') }}</p>
        <div style="display: grid;">
          <v-checkbox color="success" v-model="min" :label="$t('pass.min')" hide-details density="compact" readonly class="pass-details-cb"></v-checkbox>
          <v-checkbox color="success" v-model="upper" :label="$t('pass.uppercase')" hide-details density="compact" readonly class="pass-details-cb"></v-checkbox>
          <v-checkbox color="success" v-model="lower" :label="$t('pass.lowercase')" hide-details density="compact" readonly class="pass-details-cb"></v-checkbox>
          <v-checkbox color="success" v-model="symbol" :label="$t('pass.symbol')" hide-details density="compact" readonly class="pass-details-cb"></v-checkbox>
          <v-checkbox color="success" v-model="number" :label="$t('pass.number')" hide-details density="compact" readonly class="pass-details-cb"></v-checkbox>
          <v-checkbox color="success" v-model="match" :label="$t('pass.match')" hide-details density="compact" readonly class="pass-details-cb"></v-checkbox>
        </div>
        <form ref="form" @submit.prevent="login()">
          <p class="field-label text-design" style="margin-top: 1rem;">{{ $t('passwordnew') }}</p>
          <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules="[requiredRule, passRule]"
              name="password"
              variant="solo"
              class="small-text-field"
              prepend-inner-icon="mdi-lock-outline"
              @click:append="show1 = !show1"
              @input="checkPass"
          ></v-text-field>
          <p class="field-label text-design" style="margin-top: 1rem;">{{ $t('password.confirm') }}</p>
          <v-text-field
              v-model="passwordCheck"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirm ? 'text' : 'password'"
              :rules="[requiredRule, passRule]"
              name="password"
              variant="solo"
              class="small-text-field"
              prepend-inner-icon="mdi-lock-outline"
              @click:append="showConfirm = !showConfirm"
              @input="checkPassConfirm"
          ></v-text-field>
          <v-btn :disabled="!isValidPassword" type="submit" class="mt-4 e-round-corner full-width" color="primary" value="log in">{{ $t('confirm') }}</v-btn>
        </form>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import router from "@/router";
import { useTheme } from 'vuetify'
import LocaleSwitcher from "@/components/LocaleSwitcher";
import {useRoute} from "vue-router/dist/vue-router";
import {rules} from "@/services/rules";

export default {
  name: "ForgotPassword",
  components: {LocaleSwitcher},
  inject: ['mq'],
  setup () {
    const theme = useTheme()
    const rules = import('@/services/rules')

    const route = useRoute();
    return {theme, rules}
  },
  data() {
    return {
      show1: false,
      show2: true,
      showConfirm: false,
      username: "",
      password: "",
      passwordCheck: "",
      invalidData: false,
      saveCookies: false,
      min: false,
      upper: false,
      lower: false,
      symbol: false,
      number: false,
      match: false,
      uuid: "",
      passRule: rules.value.pass,
      requiredRule: rules.value.required
    };
  },
  computed: {
    isValidPassword() {
      return this.min && this.upper && this.lower && this.symbol && this.number && this.match;
    }
  },
  mounted() {
    this.uuid = this.$route.query.uuid
    if (!this.uuid) router.push({path: "login"});
    UserService.checkUserPasswordUuid(this.uuid).then(result => {
      if (!result || !result.data) {
        router.push({path: "login"});
      }
    })
  },
  methods: {
    login() {
      const { username, password } = this;
      UserService.changePassword(this.uuid, password).then((response) => {
        router.push({ path: 'login' });
      });
    },
    checkPass() {
      this.min = this.password.length >= 12;
      this.upper = /[A-Z]/.test(this.password);
      this.lower = /[a-z]/.test(this.password);
      this.symbol = /[@$!%*#?~(&)+=^_-]/.test(this.password);
      this.number = /[0-9]/.test(this.password);
    },
    checkPassConfirm() {
      this.match = this.password === this.passwordCheck;
    }
  },
}
</script>

<style scoped>
.password-forgot, .login-main-container  {
  height: 100%;
}

.password-forgot {
  /*background-image: linear-gradient(#2e73ee, #756cec, #2e73ee);*/
  background-color: rgb(var(--v-theme-primary));
}

@media screen and (max-height: 405px) {
  .password-forgot {height: auto;}
}

a {
  color: rgb(98 0 238);
  text-decoration: none;
}

a:hover {
  color: #2e73ee;
}

.v-container {
  padding: 0;
}

.login-fields-container {
  padding: 5% 20px;
}

.login-main-container {
  background-color: white;
  overflow-y: auto;
  /*margin: 0 10% 0 10%;*/
}

.e-round-corner {
  border-radius: 20px;
  font-size: small;
}

.full-width {
  width: 100%;
  margin-bottom: 10px;
}

.field-label {
  margin-bottom: 10px;
  font-weight: bold;
}

.text-design {
  font-size: small;
  opacity: 0.6;
}

.small-text-field .v-input__control .v-field .v-field__field .v-field__input {
  min-height: 40px;
  padding: 5px 15px 5px 15px;
}

.pass-details-cb:deep(.v-label--clickable), .pass-details-cb:deep(.v-selection-control__input input) {
  cursor: default;
}

.pass-details-cb:deep(.v-selection-control) {
  min-height: 0;
  font-size: 12px;
}

.pass-details-cb:deep(.v-label) {
  font-size: 14px;
}

</style>