<template>
  <div :class="mq.current === 'mobile' ? 'people-container-mobile' : 'people-container'" class="content-page-main">
    <div style="display:flex;">
      <add-card @click="showAddDialog" icon="mdi-account-plus" :title="$t('employee.new.title') +
      (currentRole === 'Client' ? $t('leftmenu.admin').toLowerCase() : $t('employee').toLowerCase())"
                :tooltip-message="$t('adduser')"></add-card>
<!--      <v-btn hidden v-if="mq.current !== 'mobile'" prepend-icon="mdi-file-upload" @click="cart.showDataUpload = true" style="margin-top: 5px; position: absolute; right: 20px;">-->
<!--        {{ $t('leftmenu.upload') + " " + $t('files') }}-->
<!--        <tooltip-message :message="$t('data.upload.tooltip') + $t('more.users') + $t('leftmenu.people').toLowerCase()"/>-->
<!--      </v-btn>-->
<!--      <v-btn hidden v-if="mq.current === 'mobile'" icon="mdi-file-upload" @click="cart.showDataUpload = true" size="small">-->
<!--        <v-icon>mdi-file-upload</v-icon>-->
<!--      </v-btn>-->
    </div>

    <div :class="mq.current === 'mobile' ? 'row-container-mobile' : 'row-container'">
<!--      <div :style="mq.current !== 'mobile' ? 'width: 65%;' : ''">-->
      <v-data-table
          :headers="employeeHeaders"
          :items="loadEmployeesData"
          density="compact"
          :loading="loading"
          style="padding-bottom: 10px;"
          class="people-data-table"
      >
        <template v-slot:no-data>
          <span>{{ $t('nodata.people') }}</span>
        </template>

        <template v-slot:item="{ item, internalItem, toggleExpand }">
          <!--      TODO: think if changing color of the row while dragging over is necessary -->
            <tr @drop="onDrop($event, item)" @dragover.prevent @dragenter.prevent
                @click="toggleExpand(internalItem)"
                style="cursor: pointer;" :class="isHovering ? 'highlight-row' : ''">
<!--            <tr @dragover.prevent @dragenter.prevent @click="toggleExpand(item)">-->
              <td>{{ item.firstname ? (item.firstname + " " + item.lastname) : item.name }}</td>
              <td>{{ item.email }}</td>
              <td><span style="text-decoration: underline; color: #039be5;">{{ item.assignedCompanies }}</span></td>
<!--                <td>{{ item.role === 'Accountant' ? $t('employee') : item.role }}</td>-->
              <td style="display: flex;" :style="mq.current === 'mobile' ? 'padding: 5px 0; margin-top: 4px;' : 'padding: 0'">
                <v-btn class="table-button" size="x-small" icon="mdi-delete" @click="deleteDialog = true; deleteId = item.id;" style="margin-right: 10px">
                  <v-icon>mdi-delete</v-icon>
                  <tooltip-message :message="$t('deleteuser')"/>
                </v-btn>
                <v-btn class="table-button" size="x-small" icon="mdi-pencil" @click="showEditDialog($event, item, 0)" style="margin-right: 10px">
                  <v-icon>mdi-pencil</v-icon>
                  <tooltip-message :message="$t('edituser')"/>
                </v-btn>
                <v-btn class="table-button" size="x-small" icon="mdi-office-building-cog" @click="showEditDialog($event, item, 1)">
                  <v-icon>mdi-office-building-cog</v-icon>
                  <tooltip-message :message="$t('edit.companies')"/>
                </v-btn>
              </td>
              <td :style="mq.current !== 'mobile' ? 'padding: 0;' : ''">
                <v-icon size="small" icon="mdi-arrow-down" ></v-icon>
                <!--              @click="toggleExpand(item)"-->
              </td>
            </tr>
          <v-dialog
              v-model="deleteDialog"
              max-width="500px"
              class="icarus-dialog"
          >
            <v-card>
              <span size="big" class="dialog-title">{{ $t('employee.delete.confirmation') }}</span>
              <v-card-actions>
                <v-btn class="cancel-btn" variant="tonal" @click="deleteDialog = false;">{{ $t('cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="success-btn delete-btn" variant="tonal" @click="deleteUser">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:expanded-row="{ columns, item }">
          <tr @drop="onDrop($event, item)" @dragover.prevent @dragenter.prevent @drop.prevent>
            <td :colspan="columns.length" :style="item.companies.length > 4 ? 'overflow-x: scroll;' : ''">
              <v-chip v-for="company in item.companies.filter(comp => comp && comp.id)" :key="company.id" style="margin: 3px 5px 3px 0;">
                {{ company.name }}
                <v-btn size="xx-small" icon="mdi-delete" @click="deleteCompanyAssociation(item, company.id)" style="margin-left: 10px;">
                  <v-icon>mdi-delete</v-icon>
                  <tooltip-message :message="$t('removecompanyassociation')"/>
                </v-btn>
              </v-chip>
              <span v-if="!item.companies || !item.companies.length || item.companies.length === 0" class="no-assigned-companies">{{ $t('peoplemanager.companies.associated') }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
      <span style="font-size: small;" v-if="mq.current !== 'mobile'" :style="cart.isDark ? 'color: white;' : 'color: black;'">{{ $t('clickuser') }}</span>

      <span style="font-size: small;" :style="cart.isDark ? 'color: white;' : 'color: black;'" v-if="mq.current === 'mobile'">{{ $t('clickuser') }}</span>

    </div>
  </div>

  <v-dialog
      v-model="addDialog"
      max-width="600px"
      persistent
      class="icarus-dialog">
    <people-step-process :current-role="currentRole"
                         submitType="add" @closeDialog="addDialog = false"></people-step-process>
  </v-dialog>

  <v-dialog
      v-model="editDialog"
      max-width="600px"
      persistent
      class="icarus-dialog">
    <people-step-process :current-role="currentRole"
                         submitType="edit" @closeDialog="editDialog = false"></people-step-process>
  </v-dialog>

  <v-dialog v-model="cart.showDataUpload" height="70%">
    <v-btn v-if="mq.current === 'mobile'" size="small" icon="mdi-file-download" style="position: absolute; left: -10px; top: -10px; z-index: 10;" @click="downloadTemplate"></v-btn>
    <v-btn size="small" icon="mdi-close" style="position: absolute; right: -10px; top: -10px; z-index: 10;" @click="cart.showDataUpload = false"></v-btn>
    <data-file-upload data-type="users"></data-file-upload>
  </v-dialog>

</template>

<script setup>

import AddCard from "@/components/mini/AddCard";
import TooltipMessage from "@/components/mini/Tooltip";
import DataFileUpload from "@/components/mini/DataFileUpload";
import { rules } from '@/services/rules';
import PeopleStepProcess from "@/components/mini/PeopleStepProcess";

</script>

<script>
import UserService from "@/services/UserService";
import { cart } from '@/services/cart';
import i18n from "@/i18n";
import SubscriptionService from "@/services/SubscriptionService";
import CompanyService from "@/services/CompanyService";

export default {
  name: "PeopleManager",
  inject: ['mq'],
  props: {
    currentRole: String
  },
  computed: {
    employeeHeaders() {
      return [{
          title: i18n.global.t("name"),
          align: 'start',
          key: 'name',
          width: '30%'
      },
      {
          title: 'Email',
          align: 'start',
          key: 'email',
          width: '30%'
      }/*, {
          title: i18n.global.t("role"),
          align: 'start',
          key: 'role',
          width: '15%'
      }*/, {
          title: i18n.global.t("companies.assigned"),
          align: 'left',
          key: 'assignedCompanies',
          sortable: true,
          width: '13%'
        }, {
          title: '',
          align: 'start',
          key: 'actions',
          sortable: false,
          width: '15%'
      }, {
          title: '',
          align: 'end',
          key: 'expand',
          sortable: false,
          width: '1%',
      }]
    },
    loadEmployeesData() {
      let employees = [];
      if (cart.value.employees && cart.value.employees.length > 0)
        cart.value.startLoading(this);
      let loggedUser = UserService.getLoggedUser();
      loggedUser = UserService.getUserByIdLocally(loggedUser.id);
      cart.value.employees.forEach(employee => {
        if (this.currentRole !== null && this.currentRole === 'Client' && employee.role !== "Client" && employee.role !== "Accountant") {
          this.loading = false;
          return;
        } else if (this.currentRole !== null && this.currentRole === 'Employee' && employee.role !== "Employee") {
          this.loading = false;
          return;
        }
        let companies = [];
        if (employee.companyIds) {
          employee.companyIds.forEach(companyId => {
            let comp = this.companies.find(company => company && company.id === companyId);
            if (comp)
              companies.push(comp);
          });
        }
        employee.companies = companies;
        employee.assignedCompanies = employee.companyIds ? employee.companyIds.length : 0;
        employees.push(employee);
        this.loading = false;
      });
      if (employees === [] || employees.length === 0) this.loading = false;
      return employees;
    },
    companies() {
      return cart.value.companies.filter(company => company.name);
    }
  },
  data: () => ({
    validEditForm: false,
    validAddForm: false,
    requiredRule: rules.value.required,
    emailRule: rules.value.email,
    phoneRule: rules.value.phone,
    dragging: false,
    hover: false,
    addDialog: false,
    deleteDialog: false,
    deleteId: '',
    editDialog: false,
    loading: false,
    draggedId: null,
    // currentRole: 'client',
    addUserItem: {
      name: '',
      email: '',
      username: '',
      role: '',
    },
    editUserItem: {
      name: '',
      email: '',
      username: '',
      role: '',
    },
    groupBy: [{ key: 'email', order: 'asc' }],
    // companies: [],
    employees: [],
    user: null
  }),
  mounted() {
    this.user = UserService.getLoggedUser();
    this.employees = [];
    // this.companies = cart.value.companies;
  },
  methods: {
    deleteUser() {
      console.log("Deleting user " + this.deleteId);
      if (!this.deleteId || this.deleteId === ''){
        console.log("Couldn't delete user!");
        this.deleteDialog = false;
        return;
      }
      cart.value.startLoading(this);
      UserService.remove(this.deleteId).then(response => {
        cart.value.showAlertDetails(i18n.global.t('employee2') + i18n.global.t('was') + i18n.global.t('deleted2') + i18n.global.t('withsuccess'))
        UserService.getUserEmployees(this.user.id).then(employees => {
          cart.value.employees = employees.data;
          this.employees = [];
          this.loading = false;
        });
      });
      this.deleteDialog = false;
    },
    showEditDialog(e, item, step=0) {
      e.stopPropagation();
      this.editUserItem = {...item};
      this.editDialog = true;
      if (this.currentRole.toLowerCase() === 'Employee')
        this.editUserItem.role = i18n.global.t('employee');
      else
        this.editUserItem.role = this.currentRole;

      cart.value.userFormStep = step;
      UserService.setFormItem(this.editUserItem);
    },
    editUser(id) {
      if (!this.validEditForm) {
        console.log("Invalid edit form");
        cart.value.showAlertDetails(i18n.global.t('form.validate'))
        this.$refs.editForm.validate();
        return;
      }
      cart.value.startLoading(this);
      console.log("Editing user " + id);
      if (this.editUserItem.role === i18n.global.t('employee')) this.editUserItem.role = "Accountant";
      UserService.edit(this.editUserItem).then(user => {
        let cmp = this.employees.find(c => c.id === user.data.id);
        cmp.name = user.data.name;
        cmp.firstname = user.data.firstname;
        cmp.lastname = user.data.lastname;
        cmp.email = user.data.email;
        cmp.role = user.data.role;
        cmp.phone = user.data.phone;
        this.loading = false;
        cart.value.showAlertDetails((this.editUserItem.role === 'Accountant' ? i18n.global.t('employee2') :
            i18n.global.t('admin2')) + i18n.global.t('was') + i18n.global.t('edited2') + i18n.global.t('withsuccess'))
      });
      this.editDialog = false;
    },
    showAddDialog() {
      const employeeNumber = cart.value.employees.filter(employee => employee.role === 'Accountant' || employee.role === 'Employee').length;
      if (!SubscriptionService.validateUserNumber(employeeNumber) && this.currentRole === 'Employee') {
        cart.value.showAlertDetails(i18n.global.t('subscription.error.people3') + cart.value.selectedSubscription.maxUsers + " " + i18n.global.t('employees').toLowerCase() + ".", true)
        return;
      }
      this.addUserItem = {};
      if (this.currentRole.toLowerCase() === 'Employee')
        this.addUserItem.role = i18n.global.t('employee');
      else
        this.addUserItem.role = this.currentRole;

      cart.value.userFormStep = 0;
      UserService.setFormItem(this.addUserItem);
      this.addDialog = true;
    },
    getUserDetails(id) {
      const user = this.employees.filter(u => u.id === id)[0];
      return user.name + "  " + user.role + "  " + user.email
    },
    startDrag(e, item) {
      this.draggedId = item.id;
      if (e instanceof TouchEvent) {
        e.preventDefault();
      }
      this.dragging = true;
    },
    //TODO: Fix drop on touch events
    onDrop(e, user) {
      const itemID = this.draggedId;
      if (!itemID) return;
      const company = this.companies.find(comp => comp.id == itemID);
      if (user.companies && user.companies.find(comp => comp && comp.id === company.id)) {
        console.log("Company " + itemID + " was already associated with user " + user.id);
        return;
      }
      console.log("Company " + itemID + " was associated with user " + user.id);
      user.companies.push(company);
      UserService.associateCompany(user.id, company.id);
      cart.value.showAlertDetails(i18n.global.t('companyassoc') + i18n.global.t('was') + i18n.global.t('created') + i18n.global.t('withsuccess'))
    },
    deleteCompanyAssociation(user, companyId) {
      const companyIndex = user.companies.map(comp => comp.id).indexOf(companyId);
      if (companyIndex === -1) {
        console.log("Company not found for association removal.");
        return;
      }
      console.log("Company association removed successfully.");
      cart.value.showAlertDetails(i18n.global.t('companyassoc') + i18n.global.t('was') + i18n.global.t('deleted') + i18n.global.t('withsuccess'))
      user.companies.splice(companyIndex, 1);
      UserService.removeCompanyAssociation(user.id, companyId);
    },
    downloadTemplate() {
      UserService.downloadFileTemplate();
    },
    // filterPeople(type) {
    //   document.getElementsByClassName('active-role').item(0).classList.remove('active-role')
    //   document.getElementById('role-' + type).classList.add('active-role');
    //   this.currentRole = type;
    // }
  }
}
</script>

<style scoped>
.active-role {
  opacity: 1 !important;
}

.highlight-row > td {
  /*background: blue !important;*/
  opacity: .9;
  background: #ECEFF1 !important;
}

.v-table:deep(div) {
  overflow: visible !important;
}

.people-container {
  margin: 30px 30px 30px 230px
}

.people-container-mobile {
  padding: 10px;
}

.people-container-mobile td {
  font-size: small;
  padding: 0 5px;
}

.people-container-mobile .v-table {
  overflow: scroll !important;
  width: 100% !important;
}

.people-container-mobile .table-button {
  font-size: x-small;
  height: 24px;
  width: 24px;
}

.row-container {
  display: block;
  margin: 20px 0 20px 10px
}

.row-container-mobile {
  display: contents;
  /*width: 65%;*/
  /*margin: -20px 0;*/
}

.companies-drag-container-mobile {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.companies-drag-container {
  width: 30%;
}

.company-form-item {
  margin: 10px 0;
}

.people-data-table:deep(.v-progress-linear) {
  transform: translate(-85%) !important;
}

.assigned-companies-container {

}

.no-assigned-companies {
  font-size: small;
  height: 38px;
  display: block;
  padding: 8px 5px;
}

</style>