<template>
  <div class="client-upload content-page">
    <LeftMenu/>
    <TopBar/>
    <ClientUpload/>
    <TutorialOverlay/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue'
import LeftMenu from "@/components/LeftMenu";
import {defineAsyncComponent} from "vue";
import TutorialOverlay from "@/components/TutorialOverlay";


const ClientUpload = defineAsyncComponent(() =>
    import('@/components/fileComponents/ClientUpload')
)

export default {
  name: 'ClientUploadView',
  components: {
    TutorialOverlay,
    LeftMenu,
    TopBar,
    ClientUpload
  },
}
</script>
