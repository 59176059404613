<template>
  <v-expansion-panels
      v-if="cart.showUploadFilesWindow"
      v-model="cart.uploadLoaderWindowPanel"
      style="position: fixed; max-width: 300px; right: 15px; top: 60px; z-index: 3000;"
  >
    <v-btn size="small" icon="mdi-close"  class="close-button" @click="cart.showUploadFilesWindow = false"
    style="border-bottom-left-radius: 25px !important; border-bottom-right-radius: 25px !important;"></v-btn>
    <v-expansion-panel style="margin-top: 10px !important;" elevation="4">
      <v-expansion-panel-title size="small" style="min-height: 46px; margin-bottom: 8px;" color="primary">
        {{ $t('uploading') }}
      </v-expansion-panel-title>
      <v-expansion-panel-text v-for="uploadFile in uploadFilesToShow" :key="uploadFile.id" style=" border-bottom: 1px solid rgba(0, 0, 0, 0.1); padding: 6px 0 0px;">
        <v-row style="padding: 5px 5px;">
          <tooltip-message :message="uploadFile.file.name"/>
          <span class="file-name" :title="uploadFile.file.name">
            <v-icon size="small" :color="getColor(uploadFile.file.name)">{{ getIcon(uploadFile.file.name) }}</v-icon>
            {{ uploadFile.file.name }}
          </span>
          <v-spacer></v-spacer>
          <v-progress-circular
              color="primary"
              indeterminate
              size="16"
              v-if="isLoading(uploadFile)"
          ></v-progress-circular>
          <v-btn v-if="!isLoading(uploadFile) && !uploadFile.failedUpload" :disabled="!validPreview(uploadFile.file.name)" icon="mdi-file-find"
                 size="small" class="btn-icon-small" elevation="1" @click="preview(uploadFile)">
            <v-icon>mdi-file-find</v-icon>
            <tooltip-message :message="$t('previewfile')"/>
          </v-btn>
          <v-btn v-if="!isLoading(uploadFile) && !uploadFile.failedUpload" icon="mdi-delete" size="small" class="btn-icon-small" elevation="1"
          @click="prepareDelete(uploadFile)">
            <v-icon>mdi-delete</v-icon>
            <tooltip-message :message="$t('deletefile')"/>
          </v-btn>
          <v-icon v-if="uploadFile.uploaded" color="success">mdi-check-circle</v-icon>
          <v-icon v-if="uploadFile.failedUpload && !uploadFile.uploaded" color="error">mdi-close-circle</v-icon>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>


  <v-dialog
      v-model="deleteDialog"
      max-width="500px"
      class="icarus-dialog"
  >
    <v-card>
      <span size="big" class="dialog-title">{{ $t('file.delete.confirmation') }}</span>
      <v-card-actions>
        <v-btn class="cancel-btn" variant="tonal" @click="deleteDialog = false">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success-btn" style="background: rgb(var(--v-theme-error))" variant="tonal" @click="deleteFile()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {cart} from "@/services/cart";
</script>

<script>
import {folderData} from "@/services/FolderService";
import FileService from "@/services/FileService";
import Helper from "@/services/Helper";
import PreviewService from "@/services/PreviewService";

export default {
  name: "UploadLoaderWindow",
  data: () => ({
    deleteDialog: false,
    deleteFileId: null
  }),
  computed: {
    uploadFilesToShow() {
      return folderData.value.filesToUpload;
    }
  },
  methods: {
    validPreview(filename) {
      return FileService.validPreview(filename);
    },
    prepareDelete(uploadFile) {
      this.deleteFileId = uploadFile;
      this.deleteDialog = true;
    },
    preview(uploadFile) {
      uploadFile.filename = uploadFile.file.name;
      PreviewService.previewFile(uploadFile);
    },
    deleteFile() {
      this.deleteDialog = false;
      folderData.value.filesToUpload.splice(this.deleteFileId, 1);
      FileService.deleteFileById(this.deleteFileId.id);
      if (!cart.value.showUploadFilesWindow || folderData.value.filesToUpload.length === 0) {
        cart.value.showUploadFilesWindow = false;
      }
    },
    isLoading(uploadFile) {
      return !uploadFile.failedUpload && !uploadFile.uploaded;
    },
    getIcon(filename) {
      let extension = filename.substring(filename.lastIndexOf('.') + 1);
      return Helper.getFileColorAndIcon(extension).icon;
    },
    getColor(filename) {
      let extension = filename.substring(filename.lastIndexOf('.') + 1);
      return Helper.getFileColorAndIcon(extension).color;
    }
  }
}
</script>

<style scoped>
  .close-button {
    position: absolute; border-radius: 25px;
    right: -15px;
    top: -0px;
    z-index: 10;
    width: 28px;
    height: 28px;
  }

  .btn-icon-small {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .file-name {
    font-size: 13px;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
  }
</style>