<template>
  <iframe src="legal.html" style="width: 100%; height: 99vh; border: none;"></iframe>
</template>
<script setup>

</script>

<script>

export default {
  name: "LegalPage"
}
</script>

<style scoped>

</style>