<template>
  <v-chip-group v-model="selectedTheme" mandatory>
    <v-chip v-for="(theme, i) in themes" :key="`theme-${i}`" :value="theme.value" :style="mq.current === 'mobile' ? 'margin: 5px 0;' : ''"
            @click="toggleTheme(theme.value); changeThemeSetting(theme.value);" color="primary">
      <v-icon>{{ theme.icon }}</v-icon>
      <span v-if="mq.current !== 'mobile'"> {{ theme.name }} </span>
    </v-chip>
  </v-chip-group>
</template>

<script>

import {cart} from "@/services/cart";
import Helper from "@/services/Helper";
import router from "@/router";
import {useTheme} from "vuetify";
import $cookies from "vue-cookie";
import SettingsService from "@/services/SettingsService";
import UserService from "@/services/UserService";
import i18n from "@/i18n";

export default {
  name: "ThemeSwitcher",
  inject: ['mq'],
  setup () {
    const theme = useTheme()

    return {
      theme,
      toggleTheme(themeName) {
        cart.value.isDark = theme.global.current.value.dark;
        theme.global.name.value = themeName;
        $cookies.set('Theme', theme.global.name.value);
      }
    }
  },
  data() {
    return {
      themes: [
          {name: "Dark", value: "mainDarkTheme", icon: 'mdi-moon-waning-crescent', color: 'v-theme--mainDarkTheme'},
          {name: "Light", value: "mainTheme", icon: 'mdi-white-balance-sunny', color: 'v-theme--mainTheme'}
      ],
      selectedTheme: []
    };
  },
  mounted() {
    this.selectedTheme = [SettingsService.getUserSettings().Theme === 'mainTheme' ? 1 : 0];
    // SettingsService.getByUserId(UserService.getLoggedUser().id).then(settings => {
    //   const themeSetting = cart.value.settings.find(setting => setting.name === 'Theme');
    //   if (themeSetting && themeSetting.value) {
    //     this.selectedTheme = [themeSetting.value === 'mainTheme' ? 1 : 0];
    //     this.theme.global.name.value = themeSetting.value;
    //   } else {
    //     this.selectedTheme = [cart.value.isDark ? 0 : 1];
    //   }
    // })
  },
  methods: {
    changeThemeSetting(theme) {
      SettingsService.editSetting('Theme', theme);
    }
  }
};
</script>