<template>
  <div class="screen-select content-page" style="height: -webkit-fill-available;">
    <TopBar/>
    <LeftMenu/>
    <SettingsForm/>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue'
import LeftMenu from "@/components/LeftMenu";
import {defineAsyncComponent} from "vue";


const SettingsForm = defineAsyncComponent(() =>
    import('@/components/Settings')
)

export default {
  name: 'ScreenSelectView',
  components: {
    SettingsForm,
    TopBar,
    LeftMenu,

  }
}
</script>
