<template>
<!--  :style="mq.current === 'mobile' ? '' : 'padding: 20px;'"-->
  <v-card elevation="0" :width="mq.current === 'mobile' ? '100%' : 600">
    <span size="big" class="dialog-title" v-if="title && title.length">{{ title }}</span>
    <span class="dialog-message" v-if="submitType !== 'edit'">{{ $t('company.new.cui.message') }}</span>
    <v-form v-model="validForm" ref="form" :style="mq.current === 'mobile' ? 'margin: 20px 10px 0;' : 'width: 96%; margin: 20px 2% 0 2% !important;'" v-if="formItem">
      <v-container>
        <v-row :style="mq.current === 'mobile' ? 'display: block;' : ''">
          <v-col>
            <v-row style="width: 100%; margin: 0; flex-wrap: nowrap;">
              <v-text-field
                  v-model="formItem.cui" class="company-form-item"
                  :label="$t('companyCUI')"
                  :clearable="submitType === 'add'"
                  :rules="[requiredRule, cuiRule]"
                  density="compact"
                  variant="outlined"
                  :readonly="submitType === 'edit'"
              ></v-text-field>
              <v-btn v-if="submitType !== 'edit'" @click="getExistentCompany" icon="mdi-search-web" color="primary"
                     style="margin: 0px 0 0 10px;" size="small">
                <v-icon>mdi-search-web</v-icon>
                <tooltip-message :message="$t('company.search')"/>
              </v-btn>
            </v-row>
            <v-text-field
                v-model="formItem.name"
                class="company-form-item"
                :label="$t('companyName')"
                clearable="true"
                :rules="[requiredRule]"
                :disabled="disableField()"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="formItem.administrator"
                class="company-form-item"
                label="Administrator"
                :disabled="disableField()"
                :rules="[requiredRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="formItem.registryNo"
                class="company-form-item"
                :label="$t('companyRegistry')"
                clearable="true"
                :disabled="disableField()"
                :rules="[requiredRule, registryRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="formItem.county"
                class="company-form-item"
                :label="$t('county')"
                clearable="true"
                :disabled="disableField()"
                :rules="[requiredRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                v-model="formItem.city"
                class="company-form-item"
                :label="$t('city')"
                clearable="true"
                :disabled="disableField()"
                :rules="[requiredRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="formItem.address"
                class="company-form-item"
                :label="$t('address')"
                clearable="true"
                :disabled="disableField()"
                :rules="[requiredRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="formItem.iban"
                class="company-form-item"
                label="IBAN"
                clearable="true"
                :disabled="disableField()"
                :rules="[ibanRule]"
                density="compact"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-if="submitType !== 'myCompany'"
                v-model="formItem.email"
                class="company-form-item"
                label="Email"
                :disabled="disableField()"
                :rules="[requiredRule, emailRule]"
                density="compact"
                variant="outlined"
                :readonly="submitType === 'edit'"
            ></v-text-field>
            <v-checkbox
                v-model="formItem.tva"
                :disabled="disableField()"
                class="company-form-item"
                variant="outlined"
                label="TVA">
              <tooltip-message :message="$t('tvacompanyinfo')"/>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions v-if="!noActions">
<!--      <v-spacer v-if="submitType !== 'myCompany'"></v-spacer>-->
      <v-btn class="cancel-btn" v-if="submitType !== 'myCompany'" variant="tonal" @click="$emit('closeDialog', false);">{{ $t('cancel') }}</v-btn>
      <v-spacer v-if="submitType !== 'myCompany'"></v-spacer>
      <v-btn class="success-btn" v-if="subType !== 'FreeEmployee'" variant="tonal" @click="submitData">{{ $t(submitType) }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import TooltipMessage from "@/components/mini/Tooltip";
import { rules } from '@/services/rules';
import {defineProps} from "vue";
const props = defineProps(['item', 'submitType', 'title', 'noActions'])

</script>

<script>
import CompanyService from "@/services/CompanyService";
import {cart} from "@/services/cart";
import i18n from "@/i18n";
import UserService from "@/services/UserService";

export default {
  name: "CompanyForm",
  inject: ['mq'],
  mounted() {
    this.user = UserService.getLoggedUser();
    this.cuiSearched = false;
  },
  computed: {
    formItem() {
      if (this.submitType === 'myCompany') return cart.value.mycompany;
      return CompanyService.getFormItem();
    },
    subType() {
      return UserService.getLoggedUser().subscriptionType
    },
    companyCuiLength() {
      let formItem = null;
      if (this.submitType === 'myCompany')
        formItem = cart.value.mycompany;
      else
        formItem = CompanyService.getFormItem();
      if (!formItem || !formItem.cui) return false;
      return formItem.cui.length > 4;
    }
  },
  data: () => ({
    user: null,
    validForm: false,
    cuiSearched: false,
    requiredRule: rules.value.required,
    emailRule: rules.value.email,
    ibanRule: rules.value.iban,
    cuiRule: rules.value.cui,
    registryRule: rules.value.registry,
  }),
  methods: {
    submitData() {
      if (this.submitType === 'edit') {
        this.editCompany(this.item.id);
      } else if (this.submitType === 'add') {
        this.addCompany();
      } else if (this.submitType === 'myCompany') {
        this.saveCompany();
      }
    },
    editCompany(id) {
      if (!this.validForm) {
        console.log("Invalid edit form");
        cart.value.showAlertDetails(i18n.global.t('form.validate'))
        this.$refs.form.validate();
        return;
      }
      console.log("Editing company " + id);
      this.$emit('closeDialog', false);
      CompanyService.edit(this.formItem).then(company => {
        let cmp = cart.value.companies.find(c => c.id === company.data.id);
        this.setDefaultCompany(company.data);
        cmp.name = company.data.name;
        cmp.cui = company.data.cui;
        cmp.registryNo = company.data.registryNo;
        cmp.address = company.data.address;
        cmp.county = company.data.county;
        cmp.city = company.data.city;
        cmp.iban = company.data.iban;
        cart.value.showAlertDetails(i18n.global.t('company') + i18n.global.t('was') + i18n.global.t('edited') + i18n.global.t('withsuccess'))
      });
    },
    addCompany() {
      if (!this.validForm) {
        console.log("Invalid add form");
        cart.value.showAlertDetails(i18n.global.t('form.validate'));
        this.$refs.form.validate();
        return;
      }
      let companyCuis = cart.value.companies.map(company => company.cui);
      if (companyCuis.includes(this.formItem.cui)) {
        console.log("Company already exists");
        cart.value.showAlertDetails(i18n.global.t('form.company.exists'))
        return;
      }

      const userData = {
        name: this.formItem.administrator,
        email: this.formItem.email,
        role: "Client",
      }
      UserService.getUserByEmail(this.formItem.email).then(response => {
        if (response !== null && response.data && response.data.id) {
          cart.value.showAlertDetails(i18n.global.t('email.exists'));
        }
      }).catch(() => {
        this.$emit('closeDialog', false);
        CompanyService.add(this.user.id, this.formItem).then(company => {
          cart.value.companies.push(company.data);
          this.setDefaultCompany(company.data);
          cart.value.showAlertDetails(i18n.global.t('company') + i18n.global.t('was') + i18n.global.t('created') + i18n.global.t('withsuccess'))

          if (userData && userData.email && this.submitType !== 'myCompany')
            this.addUser(userData, company.data.id);
        });
      });
    },
    getExistentCompany() {
      if (!this.formItem.cui) {
        return;
      }
      CompanyService.getExistentCompany({'cui': this.formItem.cui, 'data': '2023-08-16'}).then(result => {
        console.log("Searched for CUI" + this.formItem.cui);
        this.cuiSearched = true;
        if (result && result.data && result.data.cod !== 200) {
          cart.value.showAlertDetails(i18n.global.t('rule.cui.server.error'))
          return;
        }
        if (result && result.data && result.data.found && result.data.found[0] && result.data.found[0].date_generale) {
          const generalData = result.data.found[0].date_generale
          const address = result.data.found[0].adresa_domiciliu_fiscal
          this.formItem.name = generalData.denumire;
          this.formItem.address = generalData.adresa;
          this.formItem.registryNo = generalData.nrRegCom;
          this.formItem.county = address.ddenumire_Judet;
          this.formItem.city = address.ddenumire_Localitate;
          this.formItem.tva = result.data.found[0].inregistrare_scop_Tva.scpTVA;
        } else {
          cart.value.showAlertDetails(i18n.global.t('rule.cui.invalid'))
        }
      })
    },
    addUser(userData, companyId) {
      userData.role = 'Client';
      userData.defaultCompanyId = companyId;
      userData.companyIds = [companyId];
      UserService.add(this.user.id, userData).then(user => {
        // UserService.associateCompany(user.data.id, companyId).then(associatedUser => {
        cart.value.employees.push(user.data);
        cart.value.showAlertDetails(i18n.global.t('user2') + i18n.global.t('was') + i18n.global.t('created2') + i18n.global.t('withsuccess'))
        // });
      });
    },
    saveCompany() {
      let companyCuis = cart.value.companies.map(company => company.cui);
      UserService.getUserById(this.user.id).then(user => {
        if (user.data.defaultCompanyId) {
          console.log("Edit default company");
          cart.value.showAlertDetails(i18n.global.t('company') + i18n.global.t('was') + i18n.global.t('edited') + i18n.global.t('withsuccess'))
          this.editCompany(this.formItem.id);
        } else {
          console.log("Set default company");
          cart.value.showAlertDetails(i18n.global.t('company') + i18n.global.t('was') + i18n.global.t('created') + i18n.global.t('withsuccess'))
          cart.value.companies = cart.value.companies.filter(company => company.id);
          if (companyCuis.includes(this.formItem.cui)) {
            const company = cart.value.companies.find(company => company.cui === this.formItem.cui);
            this.setDefaultCompany(company);
          } else {
            this.addCompany();
          }
        }
      });
    },
    setDefaultCompany(company) {
      if (this.submitType === 'myCompany') {
        cart.value.mycompany.id = company.id;
        UserService.setDefaultCompany(this.user.id, cart.value.mycompany.id);
      }
    },
    isFormValid() {
      return this.validForm;
    },
    emitInterface() {
      this.$emit("interface", {
        validForm: () => this.isFormValid()
      });
    },
    disableField() {
      return !this.cuiSearched && this.submitType && this.submitType !== 'edit';
    }
  }
}
</script>

<style scoped>

.company-form-item {
  margin: 0px 0;
}

.dialog-message {
  padding: 10px 10px 10px 10px;
  opacity: 0.6;
  font-size: 14px;
}
</style>