import axios from 'axios';
import Helper from './Helper'
import {cart} from "@/services/cart";

const PRODUCT_API_BASE_URL = Helper.getHost() + '/api/product';

class ProductService{
    add(product) {
        return axios.post(PRODUCT_API_BASE_URL, product);
    }

    addAll(products) {
        return axios.post(PRODUCT_API_BASE_URL + "/all", products);
    }

    edit(product) {
        return axios.put(PRODUCT_API_BASE_URL, product);
    }

    remove(id) {
        return axios.delete(PRODUCT_API_BASE_URL, { headers: {'productId': id}});
    }

    getById(id) {
        return axios.get(PRODUCT_API_BASE_URL, id);
    }

    getByCompany(companyId) {
        return axios.get(PRODUCT_API_BASE_URL + "/company",{headers: {'companyId': companyId}});
    }

    loadAvailableProducts(companyId, filtered= true) {
        this.getByCompany(companyId).then(products => {
            // if (cart.value.availableProducts.length > 0) {
            //     return;
            // }
            if (!filtered) {
                cart.value.availableProducts = products.data;
                return products.data;
            }
            cart.value.availableProducts = [];
            cart.value.availableProducts = products.data.filter(product => {
                return !product.um && !product.quantity;
            });
        });
    }
}

export default new ProductService();