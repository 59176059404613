<template>
  <v-tooltip
      v-if="mq.current !== 'mobile' && cart.showTooltip"
      activator="parent"
      :open-delay="openDelay ? openDelay : 800"
      @click:outside="showTooltip = false"
      @update:modelValue="onShowTooltip"
      location="start">{{ message }}</v-tooltip>
</template>

<script setup>
import {cart} from "@/services/cart";
</script>

<script>
export default {
  name: "TooltipMessage",
  inject: ['mq'],
  props: {
    message: String,
    openDelay: Number,
    closeAfter: Number
  },
  mounted() {
    cart.value.showTooltip = true;
  },
  methods: {
    onShowTooltip(val) {
      if (val) {
        const closeDelay = this.closeAfter ? this.closeAfter : 5000;
        setTimeout(() => {
          // cart.value.showTooltip = false;
        }, closeDelay);
      }
    }
  }
}
</script>

<style scoped>

</style>