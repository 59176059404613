<template>
  <div class="requests content-page" style="height: -webkit-fill-available;">
    <TopBar/>
    <LeftMenu/>
    <RequestsComp/>
    <TutorialOverlay/>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import TopBar from "@/components/TopBar";
import {defineAsyncComponent} from "vue";
import TutorialOverlay from "@/components/TutorialOverlay";


const RequestsComp = defineAsyncComponent(() =>
      import('@/components/RequestsComp')
)

export default {
  name: "RequestsView",
  components: {TutorialOverlay, RequestsComp, TopBar, LeftMenu,
    }
}
</script>

<style scoped>

</style>