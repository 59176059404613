import axios from 'axios';
import Helper from './Helper'
import {cart} from "@/services/cart";
import UserService from "@/services/UserService";
import i18n from "@/i18n";
import $cookies from "vue-cookie";

const SETTINGS_API_BASE_URL = Helper.getHost() + '/api/setting';

class SettingsService{

    add(setting) {
        return axios.post(SETTINGS_API_BASE_URL, setting);
    }

    edit(setting) {
        return axios.put(SETTINGS_API_BASE_URL, setting);
    }

    remove(id) {
        return axios.delete(SETTINGS_API_BASE_URL, { headers: {'id': id}});
    }

    getById(id) {
        return axios.get(SETTINGS_API_BASE_URL + "/id", { headers: {'id': id}});
    }

    getByUserId(userId) {
        return axios.get(SETTINGS_API_BASE_URL, { headers: {'userId': userId}});
    }

    addSetting(name, value) {
        let user = UserService.getLoggedUser();
        let setting = {
            name: name,
            value: value,
            userId: user.id
        }
        this.add(setting).then(setting => {
            cart.value.settings.push(setting.data);
        })
    }

    editSetting(name, value) {
        let user = UserService.getLoggedUser();
        if (!cart.value.settings || cart.value.settings.length === 0){
            console.log("Settings are empty for user " + user.id);
            return;
        }
        let setting = cart.value.settings.find(setting => setting.name === name);
        if (!setting){
            console.log("Setting " + name + " not found for user " + user.id);
            this.addSetting(name, value);
            return;
        }
        setting.value = value;
        console.log("Editing setting " + name + " with value " + value + " for user " + user.id);
        return this.edit(setting);
    }

    setUserSettings() {
        if (!UserService.getLoggedUser() || !UserService.getLoggedUser().id) return;
        return this.getByUserId(UserService.getLoggedUser().id).then(settings => {
            //Set language setting
            const languageSetting = settings.data.find(setting => setting.name === 'Language');
            if (languageSetting && languageSetting.value) {
                $cookies.set("Language", languageSetting.value);
            }
            
            //Set theme setting
            const themeSetting = settings.data.find(setting => setting.name === 'Theme');
            if (themeSetting && themeSetting.value) {
                $cookies.set("Theme", themeSetting.value);
            }
        });
    }

    getUserSettings() {
        return {
            "Language": $cookies.get("Language") ? $cookies.get("Language") : 'ro',
            "Theme": $cookies.get("Theme") ? $cookies.get("Theme") : 'mainTheme'
        }
    }
}

export default new SettingsService();