<template>
  <div v-if="mq.current !== 'mobile' && tutorial">
    <div class="overlay-container">
    </div>
    <tutorial-step tutorial-number="1" :total-steps="totalSteps" :text="$t('tutorial.user')"></tutorial-step>
    <tutorial-step tutorial-number="2" :total-steps="totalSteps" :text="$t('tutorial.overview')"></tutorial-step>
    <tutorial-step tutorial-number="3" :total-steps="totalSteps" :text="$t('tutorial.download.filter')"></tutorial-step>
    <tutorial-step tutorial-number="4" :total-steps="totalSteps" :text="$t('tutorial.download.table')"></tutorial-step>
    <tutorial-step tutorial-number="5" :total-steps="totalSteps" :text="$t('tutorial.upload.table')"></tutorial-step>
    <tutorial-step tutorial-number="6" :total-steps="totalSteps" :text="$t('tutorial.requests.table')"></tutorial-step>
    <tutorial-step tutorial-number="7" :total-steps="totalSteps" :text="$t('tutorial.requests.details')"></tutorial-step>
<!--    <tutorial-step tutorial-number="8" :total-steps="totalSteps" :text="$t('tutorial.invoices.details')"></tutorial-step>-->
<!--    <tutorial-step tutorial-number="9" :total-steps="totalSteps" :text="$t('tutorial.invoices.buttons')"></tutorial-step>-->
    <tutorial-step tutorial-number="8" :total-steps="totalSteps" :text="$t('tutorial.companies.select')" :is-last="freeUser ? true : false"></tutorial-step>
    <tutorial-step tutorial-number="9" :total-steps="totalSteps" :text="$t('tutorial.companies.page')"></tutorial-step>
    <tutorial-step tutorial-number="10" :total-steps="totalSteps" :text="$t('tutorial.people.page')" is-last></tutorial-step>
  </div>
  <v-dialog
      class="details-dialog tutorial-step-7"
      :persistent="cart.tutorialCurrentStep === 7"
      v-model="cart.tutorialDetailsDialog"
      :class="mq.current === 'mobile' ? 'request-details-container-mobile' : 'request-details-container'">
    <request-details :requestDetails="requestDetails"/>
    <v-card-actions class="request-details-buttons">
      <v-spacer></v-spacer>
      <v-btn color="primary" variant="text" @click="closeRequestsDialog">{{ $t('cancel') }}</v-btn>
      <v-btn color="primary" variant="text">{{ $t('resolved') }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-dialog>
</template>

<script setup>
import {cart} from "@/services/cart";
import RequestDetails from "@/components/RequestDetailsModal";
import TutorialStep from "@/components/mini/TutorialStep";
</script>

<script>
import UserService from "@/services/UserService";
import router from "@/router";
import TutorialService from "@/services/TutorialService";


export default {
  name: "TutorialOverlay",
  inject: ['mq'],
  data: () => ({
    user: null,
    totalSteps: 10,
    detailsDialog: false,
    requestDetails: {
      title: "Cerere Tutorial",
      details: "Aici veți găsi detaliile cererii",
      status: "InProgress",
      priority: "Critical",
      createdById: "createdBy",
      companyId: "companyId",
      responseMessage: "Aici veți găsi detaliile după completarea cererii",
    }
  }),
  mounted() {
    window.scrollTo(0, 0);
    this.user = UserService.getLoggedUser();
    if (!cart.value.startTutorial) return;
    if (this.isFreeUser()) this.totalSteps = 8;
    if (cart.value.tutorialCurrentStep > 2)
      document.getElementsByClassName("overlay-container")[0].style.height = '100%';
    if (router.currentRoute.value.name === 'overview' && cart.value.tutorialCurrentStep !== 2) cart.value.tutorialCurrentStep = 1;
    if (router.currentRoute.value.name === 'download' && cart.value.tutorialCurrentStep !== 4) cart.value.tutorialCurrentStep = 3;
    if (router.currentRoute.value.name === 'upload') cart.value.tutorialCurrentStep = 5;
    if (router.currentRoute.value.name === 'requests' && cart.value.tutorialCurrentStep !== 7) cart.value.tutorialCurrentStep = 6;
    if (router.currentRoute.value.name === 'companies' && cart.value.tutorialCurrentStep !== 8){
      // cart.value.tutorialCurrentStep = 10;
      cart.value.tutorialDetailsDialog = false;
    }
    if (router.currentRoute.value.name === 'people') cart.value.tutorialCurrentStep = 10;
    if (cart.value.tutorialCurrentStep === 7) cart.value.tutorialDetailsDialog = true;
    this.highlightElements();
    this.showTutorialBox();
    if (!cart.value.company) {
      cart.value.company = {name: 'Firma Tutorial', id: 'tutorial'};
    }
  },
  computed: {
    tutorial () {
      return cart.value.startTutorial;
    },
    freeUser() {
      return UserService.getLoggedUser().subscriptionType.toLowerCase() === 'free' || UserService.getLoggedUser().subscriptionType.toLowerCase() === 'freeemployee';
    }
  },
  methods: {
    previous() {
      TutorialService.previous();
    },
    next() {
      TutorialService.next();
    },
    showTutorialBox() {
      TutorialService.showTutorialBox();
    },
    highlightElements() {
      TutorialService.highlightElements();
    },
    finalizeTutorial() {
      TutorialService.finalizeTutorial();
    },
    isFreeUser() {
      return TutorialService.isFreeUser();
    },
    closeRequestsDialog() {
      this.detailsDialog = false;
    }
  }
}
</script>

<style scoped>
  .overlay-container {
    position: absolute;
    width: 100%;
    height: 275%;
    overflow-y: hidden;
    z-index: 1200;
    background-color: black;
    opacity: 0.5;
    top: 0;
  }

  .request-details-buttons {
    background-color: white; z-index: 10; border-radius: 5px; margin-top: -5px;
    border-top: 1px solid rgb(152, 152, 152);
  }

  .request-details-container {
    max-width: 550px;
  }

  .request-details-container-mobile {
    padding: 0px;
  }
</style>