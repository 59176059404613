import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

import { Vue3Mq } from "vue3-mq";

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import VueGtag from "vue-gtag";
import router from "./router";
//Uncomment this to generate a new sitemap
// import helper from "@/router/helper";
import i18n from './i18n'
import {cart} from "@/services/cart";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ChartDataLabels, ArcElement, PointElement, LineElement, BarElement, CategoryScale, LinearScale)


const mainTheme = {
    dark: false,
    colors: {
        primary: '#6C7FF5',
        secondary: '#6C7FF5',
        gradient1: '#7884cc',
        gradient2: '#6C7FF5',
        leftMenu: '#F3F3F3FF',
        whiteBackground: '#FFF',
        input: '#FFF'
        // secondary: '#F6B12D'
    },
}

const mainDarkTheme = {
    dark: true,
    colors: {
        primary: '#075D70',
        background: '#1E1E1E',
        secondary: '#075D70',
        gradient1: '#0c7991',
        gradient2: '#075D70',
        leftMenu: '#232323FF',
        whiteBackground: '#232323FF',
        input: '#363636'
    },
}

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        options: {
            customProperties: true
        },
        defaultTheme: 'mainTheme',
        themes: {
            mainTheme,
            mainDarkTheme
        },
    },
})

const vue_mq_props = {
    breakpoints: { // default breakpoints - customize this
        mobile: 0,
        laptop: 1264,
        large: 1904
    },
    defaultBreakpoint: 'lg' // customize this for SSR
};

const VueCookies = require('vue-cookies');

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
    console.log(err)
    cart.value.showAlertDetails(i18n.global.t('servererror'))
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
}

app.use(i18n)
    .use(Vue3Mq, vue_mq_props)
    .use(vuetify)
    .use(VueCookies)
    .use(router)
    .use(VueGtag, {id: 'G-D95BE6LDNR', router})
    .mount('#app')
