<template>
  <div class="people-manager content-page" style="height: -webkit-fill-available;" :style="mq.current === 'mobile' ? 'margin-top: 60px;' : ''">
    <TopBar/>
    <LeftMenu/>
    <div :style="mq.current !== 'mobile' ? 'position: absolute; margin: 60px 0 0 230px;' : 'width: 340px; margin: 10px calc(50% - 170px) 0;'">
      <v-btn id="role-companies" class="active-role" color="primary" style="border-radius: 5px 0 0 5px; opacity: 0.7;" @click="filterPeople('companies')">
        <v-icon>mdi-office-building</v-icon>
        {{$t('companies')}}
      </v-btn>
      <v-btn id="role-client" color="primary" style="border-radius: 0 5px 5px 0; opacity: 0.7;" @click="filterPeople('client')">
        <v-icon>mdi-account-tie</v-icon>
        {{ $t('leftmenu.admins') }}
      </v-btn>
    </div>
    <CompaniesManager v-if="currentView === 'companies'"/>
    <PeopleManager v-if="currentView === 'client'" current-role='Client'/>
    <TutorialOverlay/>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import TopBar from "@/components/TopBar";
import {defineAsyncComponent} from "vue";
import TutorialOverlay from "@/components/TutorialOverlay";
import PeopleManager from "@/components/PeopleManager";

const CompaniesManager = defineAsyncComponent(() =>
    import('@/components/CompaniesManager')
)

export default {
  name: "CompaniesManagerView",
  inject: ['mq'],
  components: {
    PeopleManager,
    TutorialOverlay,
    LeftMenu,
    TopBar,
    CompaniesManager
  },
  data: () => ({
    currentView: 'companies'
  }),
  methods: {
    filterPeople(type) {
      document.getElementsByClassName('active-role').item(0).classList.remove('active-role')
      document.getElementById('role-' + type).classList.add('active-role');
      this.currentView = type;
    }
  }
}
</script>

<style scoped>
.active-role {
  opacity: 1 !important;
}

</style>