<template>
  <v-data-table
      :headers="headers"
      :items="filteredFiles"
      :search="cart.search"
      density="compact"
      :loading="cart.downloadTableLoading"
      :hide-default-footer="true"
      class="tutorial-step-4"
      :style="mq.current === 'mobile' ? 'margin-bottom: 50px;' : ''"
  >
    <template v-slot:[`item.filename`]="{ item }">
      <v-icon size="small" :color="getColor(item.filename)" style="margin-right: 5px;">{{ getIcon(item.filename) }}</v-icon>
      <span style="width: 200px; white-space: nowrap;">{{ item.filename }}</span>
    </template>
    <template v-slot:[`item.uploadedTime`]="{ item }">
      {{ getFormattedDate(item.uploadedTime) }}
    </template>
    <template v-slot:[`item.uploadedById`]="{ item }">
      {{ getUserById(item.uploadedById).name }}
    </template>
    <template v-slot:[`item.companyId`]="{ item }">
      {{ getCompanyById(getUserById(item.uploadedById).defaultCompanyId).name }}
    </template>
    <template v-slot:[`item.resolved`]="{ item }">
      <v-checkbox density="compact" style="margin-left: 20px;" hide-details v-model="item.resolved"
                  @click="toggleResolveFile(cart.getDownloadTableDataByIndex(item.id))">

        <tooltip-message :message="$t('resolvefile')"/>
      </v-checkbox>
    </template>
    <template v-slot:[`item.downloadFileBtn`]="{ item }">
      <div style="display: flex;">
        <v-btn size="x-small" icon="mdi-file-find" :disabled="!validPreview(item.filename)"
               @click="mq.current === 'mobile' ? downloadFile(cart.getDownloadTableDataByIndex(item.id)) : previewFile(item)" style="margin-right: 10px;">
          <v-icon>mdi-file-find</v-icon>
          <tooltip-message :message="$t('previewfile')"/>
        </v-btn>
        <v-btn size="x-small" icon="mdi-download" @click="downloadFile(cart.getDownloadTableDataByIndex(item.id))" style="margin-right: 10px;">
          <v-icon>mdi-download</v-icon>
          <tooltip-message :message="$t('downloadfile')"/>
        </v-btn>
        <v-btn size="x-small" icon="mdi-file-pdf-box"  :disabled="!validPdfExport(item.filename)"
               @click="downloadFilePdf(cart.getDownloadTableDataByIndex(item.id))">
          <v-icon>mdi-file-pdf-box</v-icon>
          <tooltip-message :message="$t('downloadpdf')"/>
        </v-btn>
      </div>
    </template>
    <template v-slot:no-data>
      <span>{{ $t('nodata') }}</span>
    </template>
  </v-data-table>

</template>

<script setup>


import TooltipMessage from "@/components/mini/Tooltip";

</script>

<script>
import FileService from "@/services/FileService";
import {cart} from "@/services/cart";
import i18n from "@/i18n";
import UserService from "@/services/UserService";
import CompanyService from "@/services/CompanyService";
import Helper from "@/services/Helper";
import PreviewService from "@/services/PreviewService";

export default {
  name: "DownloadTableComponent",
  inject: ['mq'],
  data: () => ({
    filePreviewDialog: false,
    previewFileSrc: null,
  }),
  computed: {
    headers() {
      return [{
        title: i18n.global.t("name"),
        align: 'start',
        key: 'filename',
        width: '20%'
      }, {
        title: i18n.global.t("company"),
        align: 'start',
        key: 'companyId',
        width: '10%'
      }, {
        title: i18n.global.t("uploaddate"),
        align: 'start',
        key: 'uploadedTime',
        width: '10%'
      }, {
        title: i18n.global.t("uploadby"),
        align: 'start',
        key: 'uploadedById',
        width: '10%'
      }, {
        title: i18n.global.t("resolved"),
        align: 'start',
        key: 'resolved',
        width: '5%'
      }, {
        sortable: false,
        key: 'downloadFileBtn',
        width: '10%'
      }]
    },
    filteredFiles() {
      cart.value.downloadTableDataFiltered = cart.value.filesData.filter(fileData => {
        const uploadedTime = this.getFormattedDate(fileData.uploadedTime);
        let loggedUser = UserService.getLoggedUser();
        loggedUser = this.getUserById(loggedUser.id);
        let uploadedByUser = this.getUserById(fileData.uploadedById);
        if (!uploadedByUser || !loggedUser) return false;
        if (loggedUser.employer) {
          loggedUser = this.getUserById(loggedUser.employer);
        }
        let companiesFilter = false;
        if (fileData.fromCompanyId !== fileData.companyId) {
          companiesFilter = cart.value.filterCompanies.find(company => (company.id === fileData.fromCompanyId || company === fileData.fromCompanyId))
              && loggedUser.defaultCompanyId === fileData.companyId;
        }
        return (uploadedTime.indexOf(cart.value.filterDate) !== -1 || cart.value.showAllMonths) &&
            companiesFilter &&
            (cart.value.showResolved || !cart.value.showResolved && !fileData.resolved) &&
            !fileData.directory
            ;
      });
      cart.value.downloadTableDataFiltered.sort(function(a,b){
        return new Date(FileService.getFormattedDate(b.uploadedTime)) - new Date(FileService.getFormattedDate(a.uploadedTime));
      });
      return cart.value.downloadTableDataFiltered;
    }
  },
  methods: {
    toggleResolveFile(fileData) {
      FileService.resolveFile(fileData.id, !fileData.resolved).then(file => {
        let newData = cart.value.downloadTableData;
        const id = newData.indexOf(fileData);
        newData[id] = file;
        cart.value.showAlertDetails(i18n.global.t('file') + i18n.global.t('was') + " " + i18n.global.t('resolved').toLowerCase() + i18n.global.t('withsuccess'))
        cart.value.setDownloadTableData(newData)
      });
    },
    downloadFile(fileData) {
      FileService.getAndDownloadFile(fileData);
    },
    downloadFilePdf(fileData) {
      FileService.getFileAsPdf(fileData.id).then(file => {
        if (!file.data) {
          cart.value.showAlertDetails(i18n.global.t('upload.extension.invalid.get'));
          return;
        }
        FileService.downloadFile(file.data);
      })
    },
    previewFile(item) {
      PreviewService.previewFile(item);
    },
    getFormattedDate(date) {
      return FileService.getFormattedDate(date).substring(0, 10);
    },
    getUserById(userId) {
      return UserService.getUserByIdLocally(userId);
    },
    getCompanyById(compId) {
      return CompanyService.getCompanyById(compId);
    },
    validPreview(filename) {
      return FileService.validPreview(filename);
    },
    validPdfExport(filename) {
      return FileService.validPdfExport(filename);
    },
    getIcon(filename) {
      let extension = filename.substring(filename.lastIndexOf('.') + 1);
      return Helper.getFileColorAndIcon(extension).icon;
    },
    getColor(filename) {
      let extension = filename.substring(filename.lastIndexOf('.') + 1);
      return Helper.getFileColorAndIcon(extension).color;
    }
  }
}
</script>

<style scoped>

</style>