<template>
  <v-chip-group v-model="$i18n.locale" mandatory>
    <v-chip v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" style="margin: 5px 0;"
    @click="changeLanguageSetting(locale)">
      <v-img aspect-ratio="16/9" width="20" style="margin-right: 5px;" :src="require('@/assets/' + locale + '.png')" :alt="locale"></v-img>
      <span> {{ locale }} </span>
    </v-chip>
  </v-chip-group>
</template>

<script>
import i18n from "@/i18n";
import SettingsService from "@/services/SettingsService";
import UserService from "@/services/UserService";
import $cookies from "vue-cookie";

export default {
  name: "LocaleSwitcher",
  inject: ['mq'],
  data() {
    return { locales: ["en", "ro"] };
  },
  methods: {
    changeLanguageSetting(locale) {
      if (!UserService.getLoggedUser() || !UserService.getLoggedUser().id) return;
      SettingsService.editSetting('Language', locale);
      $cookies.set('Language', locale);
    }
  },
  mounted() {
    // if (!UserService.getLoggedUser() || !UserService.getLoggedUser().id) return;
    // SettingsService.getByUserId(UserService.getLoggedUser().id).then(settings => {
    //   const languageSetting = settings.data.find(setting => setting.name === 'Language');
    //   if (languageSetting && languageSetting.value) {
    //     i18n.global.locale.value = languageSetting.value;
    //   }
    // });
  }
};
</script>