<template>
  <div>
    <v-col style="margin: 50px calc(50% - 205px); width: 310px;">
      <span>The page you requested doesn't exist</span>
      <v-row style="margin: 30px 0;">
        <v-btn prepend-icon='mdi-arrow-left' @click="goBack" color="primary">Go Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn prepend-icon='mdi-login' @click="goLogin" color="primary">Login</v-btn>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "ErrorView",
  methods: {
    goBack() {
      router.back();
    },
    goLogin() {
      router.push('login');
    }
  }
}
</script>

<style scoped>

</style>