import axios from 'axios';
import $cookies from "vue-cookie";
import Helper from './Helper'
import UserService from "@/services/UserService";
import CompanyService from "@/services/CompanyService";
import FileService from "@/services/FileService";
import i18n from "@/i18n";
import {cart} from "@/services/cart";

const REQUEST_API_BASE_URL = Helper.getHost() + '/api/request';

class RequestService{
    getAll(userId) {
        return axios.get(REQUEST_API_BASE_URL + 's', { headers: {'userId': userId}});
    }

    getAssignedTo(userId) {
        return axios.get(REQUEST_API_BASE_URL + "s/assigned-to", { headers: {'userId': userId}});
    }

    getCreatedBy(userId) {
        return axios.get(REQUEST_API_BASE_URL + "s/created-by", { headers: {'userId': userId}});
    }

    getByCompany(companyIds) {
        return axios.get(REQUEST_API_BASE_URL + "s/companies", {headers: {'Company-Id': companyIds}});
    }

    add(user, request) {
        delete request['updateTime'];
        delete request['createTime'];
        request.fromCompanyId = user.defaultCompanyId;
        return axios.post(REQUEST_API_BASE_URL, request, { headers: {'userId': user.id}});
    }

    edit(request, resolved) {
        delete request['updateTime'];
        delete request['createTime'];
        return axios.put(REQUEST_API_BASE_URL, request, {headers: {'resolved': resolved}});
    }

    remove(id) {
        return axios.delete(REQUEST_API_BASE_URL, { headers: {'requestId': id}});
    }

    itemClick(item) {
        let requestDetails = item.item;
        const companyName = this.getCompanyById(requestDetails.companyId);
        const assignedTo = this.getUserById(requestDetails.assignedToId);
        const createdBy = this.getUserById(requestDetails.createdById);
        const resolvedBy = requestDetails.resolvedById ? this.getUserById(requestDetails.resolvedById): '';
        requestDetails.companyName = companyName ? companyName.name : '';
        requestDetails.assignedTo = assignedTo ? assignedTo.name : '';
        requestDetails.createdBy = createdBy ? createdBy.name : '';
        requestDetails.resolvedBy = resolvedBy ? resolvedBy.name : '';
        requestDetails.resolvedTimeFormatted = requestDetails.resolvedTime ? this.getFormattedDate(requestDetails.resolvedTime) : '';
        requestDetails.createTimeFormatted = this.getFormattedDate(requestDetails.createTime);
        requestDetails.updateTimeFormatted = this.getFormattedDate(requestDetails.updateTime);
        return requestDetails;
    }

    resolveRequest(request) {
        console.log("Resolving request " + request.id);
        request.resolved = !request.resolved;
        request.responseMessage = cart.value.requestDetailsResponseMessage;
        request.status = request.resolved ? "Done" : "InProgress";
        request.resolvedNow = false;
        if (request.resolved) {
            let user = UserService.getLoggedUser();
            request.resolvedById = user.id;
            request.resolvedNow = true;
        }
        this.edit(request, request.resolvedNow).then(req => {
            let re = cart.value.requestsData.find(r => r.id === req.data.id);
            re.resolved = req.data.resolved;
            re.status = req.data.status;
            if (request.resolved)
                cart.value.showAlertDetails(i18n.global.t('request') + i18n.global.t('was') + " " + i18n.global.t('resolved2').toLowerCase() + i18n.global.t('withsuccess'))
        });
    }

    getUserById(userId) {
        return UserService.getUserByIdLocally(userId);
    }

    getCompanyById(compId) {
        return CompanyService.getCompanyById(compId);
    }

    getFormattedDate(date) {
        return FileService.getFormattedDate(date);
    }
}

export default new RequestService();