<template>
  <div fluid style="height: 100vh;" class="d-flex align-center justify-center" :class="mq.current === 'mobile' ? 'login-main-container-mobile' : 'login-main-container'"
       :style="theme.global.current.value.dark ? 'background-color: #212121;' : '' ">
      <v-sheet width="400" height="100%" class="mx-auto login-fields-container">
        <div style="display: -webkit-box;">
          <v-img @click="goToHomepage" :width="60" :src="theme.global.current.value.dark ? require('@/assets/logo.svg') : require('@/assets/logo-blue.svg')" style="cursor: pointer;"></v-img>
          <locale-switcher style="opacity: 0.8; margin-left: 20px;"></locale-switcher>
        </div>
        <h2 class="title align-left">{{ $t('login.signin.account') }}</h2>
        <p class="align-left" style="font-size: small; padding-bottom: 3rem; padding-top: 0.5rem; opacity: 0.7;">{{ $t('login.register.message') }} <span @click="goToRegister" class="link-text">{{ $t('form') }}</span>.</p>
        <p class="align-left" style="font-size: small; opacity: 0.7; padding-bottom: 1rem; color: red;" v-if="invalidData">{{ $t('login.register.invalid') }}</p>
        <v-form ref="form" v-model="isFormValid" @submit.prevent="login()">
          <p class="field-label text-design align-left">Email</p>
          <v-text-field
              v-model="username"
              name="username"
              type="text"
              variant="solo"
              :rules="[requiredRule, emailRule]"
              prepend-inner-icon="mdi-email-outline"
              class="small-text-field"
          ></v-text-field>

          <p class="field-label text-design align-left" style="margin-top: 1rem;">{{ $t('password') }}</p>
          <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules="[requiredRule]"
              prepend-inner-icon="mdi-lock-outline"
              name="password"
              variant="solo"
              class="small-text-field"
              @click:append="show1 = !show1"
          ></v-text-field>
          <div class="mt-2" style="display: flex; margin: 10px 0 10px 0;">
            <input type="checkbox" id="checkbox" v-model="saveCookies" />
            <label for="checkbox" style="margin-left: 10px; margin-top: 2px;" class="text-design">{{ $t('login.remember') }}</label>
            <div style="width: 27%;"></div>
            <span style="color:#645bd9; cursor: pointer; font-size: small; margin-top: 2px;" @click="resetPasswordDialog = true">{{ $t('login.forgot') }}</span>
          </div>
          <v-btn prepend-icon="mdi-login" type="submit" class="mt-4 e-round-corner full-width"
                 color="secondary" value="log in" width="200" style="margin: 0 calc(50% - 100px);">{{ $t('login.signin') }}</v-btn>
        </v-form>
      </v-sheet>
  </div>
  <v-dialog
      v-model="resetPasswordDialog"
      max-width="400px"
  >
    <v-card style="padding: 20px; border: 1px solid gray;">
      <span size="big" style="text-align: center; margin: 10px 0;">{{ $t('request') + ' ' + $t('passwordnew') }}</span>
      <div style="width: 80%; margin: 20px 10% 0 10%;">
        <v-text-field
            v-model="email"
            label="Email"
            clearable="true"
            density="compact"
            variant="outlined"
            required
        ></v-text-field>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="text" @click="resetPasswordDialog = false">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" variant="text" @click="sendResetEmail">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="loading" fullscreen full-width>
    <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5); height: 100%;">
      <v-layout justify-center align-center>
        <v-progress-circular
            indeterminate
            color="primary" style="margin: 25% 50%; position: absolute;"
        >
        </v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
  <v-alert v-model="notif.show"
           :title="notif.title"
           density="compact"
           border="top"
           border-color="primary"
           closable
           :text="notif.text"
           style="z-index: 3010; position: absolute; margin: 20px calc(50% - 200px); width: 400px; top: 0;">
  </v-alert>
</template>

<script>
import UserService from '../services/UserService';
import router from "@/router";
import $cookies from "vue-cookie";
import { v4 as uuid } from 'uuid';
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { useTheme } from 'vuetify'
import {cart} from "@/services/cart";
import i18n from "@/i18n";
import {rules} from "@/services/rules";
import FileService from "@/services/FileService";
import SubscriptionService from "@/services/SubscriptionService";
import CompanyService from "@/services/CompanyService";
import Helper from "@/services/Helper";
import SettingsService from "@/services/SettingsService";

export default {
  name: "LoginForm",
  components: {LocaleSwitcher},
  props:['data'],
  inject: ['mq'],
  setup () {
    const theme = useTheme()
    const notif = cart.value.notification
    const rules = import('@/services/rules')
    return {theme, notif, rules}
  },
  data() {
    return {
      isFormValid: false,
      resetPasswordDialog: false,
      show1: false,
      show2: true,
      loading: false,
      username: "",
      password: "",
      email: "",
      invalidData: false,
      saveCookies: true,
      requiredRule: rules.value.required,
      emailRule: rules.value.email,
      user: null
    };
  },
  mounted() {
    // Helper.pingServer();
    if (UserService.getLoggedUser().id) {
      this.checkActiveUser(UserService.getLoggedUser(), this.checkActiveUserAndGo());
    }
    if (cart.value.activatedAccountSuccessfully == null) return;
    if (cart.value.activatedAccountSuccessfully) {
      cart.value.showAlertDetails(i18n.global.t('login.activate.success'));
    } else {
      cart.value.showAlertDetails(i18n.global.t('login.activate.fail'));
    }
    Helper.sendFbEvent("track", "ViewLoginPage");
  },
  methods: {
    login() {
      if (!this.isFormValid) {
        this.invalidData = true;
        return;
      }
      cart.value.startLoading(this);
      const { username, password } = this;
      console.log(username + " tried to log in")
      const session = uuid();
      $cookies.set("session", session);
      UserService.loginUser(username, password).then((response) => {
        let user = response.data;
        if (!user) {
          this.invalidData = true;
          this.loading = false;
          return;
        }
        this.invalidData = false;
        this.loading = false;
        if (this.saveCookies)
          UserService.setUser(user);
        else
          UserService.setLoggedUser(user);
        this.user = user;
        this.checkDefaultCompany();

        this.checkActiveUser(user, this.checkActiveUserAndGo());

        cart.value.startTutorial = !user.hadTutorial;
        cart.value.tutorialCurrentStep = 1;
        cart.value.showAlertDetails(i18n.global.t('welcome') + user.name);
        cart.value.iconAvailable = false;
        cart.value.logo = null;

        // router.push({ name: 'overview' })
      }).catch((response) => {
        console.log(response);
        this.invalidData = true;
        this.loading = false;
      });
    },
    goToHomepage() {
      router.push("/");
    },
    sendResetEmail() {
      UserService.changePasswordEmail(this.email).then(response => {
        if (!response || !response.data) {
          cart.value.showAlertDetails(i18n.global.t('email.wrong'));
        } else {
          cart.value.showAlertDetails(i18n.global.t('email.success'));
        }
        this.resetPasswordDialog = false;
      });
    },
    requestSupport() {
      const supportAddress = "office@icarusdocs.com";
      const emailSub = i18n.global.t('support.mail.subject');
      let body = i18n.global.t('support.mail.body');
      window.open('mailto:' + supportAddress + '?subject=' + emailSub + '&body=' + body, '_self')
    },
    goToRegister() {
      router.push('register');
    },
    checkActiveUser(user, fn) {
      UserService.fetchIsUserActive(user, fn);
    },
    checkDefaultCompany() {
      if (!this.user.hadTutorial && !this.user.defaultCompanyId && this.user.companyIds && this.user.companyIds.length === 1) {
        UserService.setDefaultCompany(this.user.id, this.user.companyIds[0]);
      }
    },
    checkActiveUserAndGo() {
      CompanyService.loadDefaultCompanyDb(UserService.getLoggedUser());
      SettingsService.setUserSettings().then(() => {
        router.push({ name: 'overview' });
      });
    }
  }
};
</script>

<style>
.login, .login-main-container  {
  height: 100%;
}

.login {
  background-color: rgb(var(--v-theme-primary));
  /*background-image: url('../assets/logo-white.png');*/
  /*background-position-x: 20%;*/
  /*background-position-y: 50%;*/
  /*background-size: 80px;*/

  /*background-size: cover;*/
  /*background-image: linear-gradient(#075D70, #078B91, #075D70);*/
  /*background-image: linear-gradient(#078B91, #075D70, #078B91);*/
}

@media screen and (max-height: 405px) {
  .login {height: auto;}
}

a {
  color: rgb(98 0 238);
  text-decoration: none;
}

a:hover {
  color: #2e73ee;
}

.v-container {
  padding: 0;
}

.login-fields-container {
  padding: 15% 20px;
}

.login-main-container {
  background-color: white;
  margin: 0 20% 0 20%;
}

.login-main-container-mobile {
  background-color: white;
}

.e-round-corner {
  border-radius: 20px;
  font-size: small;
}

.full-width {
  width: 100%;
  margin-bottom: 10px;
}

.field-label {
  margin-bottom: 10px;
  font-weight: bold;
}

.text-design {
  font-size: small;
  opacity: 0.6;
}

.small-text-field .v-input__control .v-field .v-field__field .v-field__input {
  min-height: 40px;
  padding: 5px 15px 5px 15px;
}

.link-text {
  cursor: pointer;
  color: rgb(var(--v-theme-primary));
}

.link-text:hover {
  opacity: 0.7;
}
</style>