<template>
  <div class="people-manager content-page" style="height: -webkit-fill-available;">
    <TopBar/>
    <LeftMenu/>
    <PeopleManager current-role='Employee'/>
    <TutorialOverlay/>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import TopBar from "@/components/TopBar";
import {defineAsyncComponent} from "vue";
import TutorialOverlay from "@/components/TutorialOverlay";


const PeopleManager = defineAsyncComponent(() =>
    import('@/components/PeopleManager')
)

export default {
  name: "PeopleManagerView",
  components: {
    TutorialOverlay,
    PeopleManager,
    LeftMenu,
    TopBar,

  }
}
</script>

<style scoped>

</style>