import {cart} from "@/services/cart";
import i18n from "@/i18n";
import {ref} from "vue";
import FileService from "@/services/FileService";
import UserService from "@/services/UserService";
import CompanyService from "@/services/CompanyService";
import Helper from "@/services/Helper";
import SelectionService from "@/services/SelectionService";


class FolderService {
    selectCompany(company) {
        //Clicked on one of the items
        if (!company) return;
        CompanyService.changeCompany(company.id);
        folderData.value.currentCompany = company;
        folderData.value.currentFile = null;
        folderData.value.hideDirectories = false;
        this.loadAll(company.id, null);
    }

    selectFolder(item) {
        //Clicked on one of the items
        if (!item) return;
        if (item.path) {
            let paths = item.path.replace(/^\/|\/$/g, '').split("/");
            let len = 2;
            paths.forEach(path => {
                this.addBreadcrumbItem(len++, path, '', false, item.path, item.companyId);
            });
        }
        folderData.value.currentFile = item;
        folderData.value.lastSelectedItem = null;
        folderData.value.selectedItems = new Set();
        if (item && item.companyId && item.path)
            this.loadAll(item.companyId, item.path);
    }

    loadAll(companyId, path=null) {
        cart.value.folderViewFiles = [];
        folderData.value.contentItems = [];
        let loggedUser = this.getLoggedUserOrEmployer();
        this.getFiles().filter(fileData => {
            //Check if the uploaded
            let companyCheck = fileData.fromCompanyId === companyId || fileData.companyId === companyId;
            if (loggedUser.defaultCompanyId === companyId) {
                companyCheck = fileData.fromCompanyId === fileData.companyId;
            }
            //If path is not mentioned filter only the company
            if (path === null || path.length === 0) {
                return companyCheck && (fileData.path === null || (fileData.directory && (fileData.path.match(/\//g) || []).length === 1))
            }
            let pathCheck = false;
            if (fileData.directory && fileData.path) {
                const foundIndex = fileData.path.indexOf(path);
                if (foundIndex === -1) return false;
                let filePathFromSearch = fileData.path.substring(foundIndex + path.length + 1);
                pathCheck = ('/' + fileData.filename) !== path && // Check for same directory
                    filePathFromSearch === fileData.filename // Check if the directory name is the only remaining element of the path after removing the current path
            } else {
                pathCheck = fileData.path === path;
            }
            return companyCheck && pathCheck;
        })
        .sort((x,y) => { // Firstly sort by name
            if (x.filename === y.filename) return 0;
            if (x.filename < y.filename) return 1;
            return -1;
        })
        .sort((x,y) => { // Second sort by date
            if (FileService.getFormattedDate(x.uploadedTime) === FileService.getFormattedDate(y.uploadedTime)) return 0;
            if (FileService.getFormattedDate(x.uploadedTime) < FileService.getFormattedDate(y.uploadedTime)) return 1;
            return -1;
        }) // Lastly sort by directory or file. Order is: the most important criteria is last.
        .sort((x,y) => x.directory === y.directory ? 0 : x.directory ? -1 : 1)
        .forEach(fileData => {
            cart.value.folderViewFiles.push(fileData);
            this.addFileUI(fileData);
        })
    }

    addFileUI(fileData) {
        let extension = fileData.filename.substring(fileData.filename.lastIndexOf('.') + 1);
        const iconColor = Helper.getFileColorAndIcon(extension, fileData.directory);
        let icon = iconColor.icon;
        let color = iconColor.color;
        if (fileData.directory)
            color = '#a4a4a4';
        const user = this.getUserById(fileData.uploadedById);
        let loggedUser = this.getLoggedUserOrEmployer();
        folderData.value.contentItems.push({
            'id': fileData.id,
            'companyId': fileData.companyId,
            'title': fileData.filename,
            'path': fileData.path,
            'icon': icon,
            'color': color,
            'type': fileData.directory ? 'directory' : 'file',
            'details2': user ? user.name : '',
            // 'details3': uploadedById && uploadedById.role !== role ? i18n.global.t("received") : null,
            'details3': fileData.fromCompanyId !== loggedUser.defaultCompanyId ? i18n.global.t("received") : null,
            'details': this.getFormattedDate(fileData.uploadedTime),
        })
    }

    getFiles() {
        let loggedUser = this.getLoggedUserOrEmployer();
        return cart.value.filesData.filter(clientFile => {
            return clientFile.companyId === loggedUser.defaultCompanyId || clientFile.fromCompanyId === loggedUser.defaultCompanyId;
        });
    }

    loadCompanies() {
        cart.value.folderViewFiles = [];
        folderData.value.contentItems = [];
        folderData.value.hideDirectories = true;
        this.setCompanyUI(cart.value.mycompany, true);
        // cart.value.folderViewFiles = this.getFiles();
        cart.value.companies.filter(company => company.name).forEach(company => {
            if (cart.value.mycompany.id === company.id) return;
            cart.value.folderViewFiles.push(company);
            this.setCompanyUI(company);
        })
    }

    setCompanyUI(company, parentCompany = false) {
        let loggedUser = this.getLoggedUserOrEmployer();
        let defaultCompanyCheck = false;
        if (loggedUser.defaultCompanyId === company.id || parentCompany) {
            defaultCompanyCheck = true;
        }
        const filesCount = this.getFiles().filter(fileData =>
            (fileData.fromCompanyId === company.id || fileData.companyId === company.id) &&
            (!defaultCompanyCheck || fileData.fromCompanyId === fileData.companyId) &&
            !fileData.directory
        ).length;
        const directoriesCount = this.getFiles().filter(fileData =>
            (fileData.fromCompanyId === company.id || fileData.companyId === company.id) &&
            (!defaultCompanyCheck || fileData.fromCompanyId === fileData.companyId) &&
            fileData.directory
        ).length;
        folderData.value.contentItems.push({
            'id': company.id,
            'title': company.name,
            'details': company.cui,
            'icon': 'mdi-home',
            'color': '#a4a4a4',
            'location': company.cui,
            'details3': filesCount + " " + i18n.global.t('files').toLowerCase(),
            'details2': directoriesCount + " " + i18n.global.t('directories').toLowerCase(),
            // 'type': parentCompany ? 'directory' : 'file',
            'type': 'company',
            'isMainCompany': parentCompany
        })
    }

    getUserById(userId) {
        return UserService.getUserByIdLocally(userId);
    }

    getFormattedDate(date) {
        return FileService.getFormattedDate(date).substring(0, 10);
    }

    openDeleteDialog(ev, item) {
        ev.preventDefault();
        folderData.value.deleteDialog = true;
        folderData.value.deleteFileId = item.id;
    }

    deleteFile() {
        cart.value.startLoadingVar('appLoader');
        folderData.value.deleteDialog = false;
        const deleteIds = this.getSelectedItemOrDefault(folderData.value.deleteFileId);
        let parentPath = folderData.value.currentFile ? folderData.value.currentFile.path : null;

        FileService.deleteFile(deleteIds).then(() => {
            cart.value.showAlertDetails(i18n.global.t('file') + i18n.global.t('was') + i18n.global.t('deleted2') + i18n.global.t('withsuccess'))
            deleteIds.forEach(deleteId => {
                const index = cart.value.filesData.map(file => file.id).indexOf(deleteId);
                if (index !== -1)
                    cart.value.filesData.splice(index,1);
            });
            this.loadAll(folderData.value.currentCompany.id, parentPath);
            cart.value.appLoader = false;
        });
    }

    createOrRenameDirectory(name) {
        if (folderData.value.actionType === 'create') {
            this.createDirectory([name]);
        } else if (folderData.value.actionType === 'rename') {
            this.rename(name);
        }
        folderData.value.addDialog = false;
    }

    openCreate(item) {
        folderData.value.addDialog = true;
        folderData.value.fileName = "New Folder";
        folderData.value.fileExtension = "";
        folderData.value.actionType = "create";
    }

    // Method that sends the directories to the backend for storage
    // Used by all other methods that create directories
    createDirectory(names, parentPaths=null) {
        if (!names) names = ["New Folder"];
        cart.value.startLoadingVar('appLoader');
        if (!parentPaths)
            parentPaths = folderData.value.currentFile ? [folderData.value.currentFile.path] : [null];
        let user = UserService.getLoggedUser();
        FileService.createDirectory(names, user, folderData.value.currentCompany.id, parentPaths).then(directories => {
            cart.value.showAlertDetails(i18n.global.t('foldercreated'));
            cart.value.filesData = cart.value.filesData.concat(directories.data);
            this.loadAll(folderData.value.currentCompany.id, parentPaths[0]);
            cart.value.appLoader = false;
        });
    }

    //Creates the directories and returns the paths for the files within the directory itself
    //Used by the upload directory button
    createDirectories(files) {
        let paths = [];
        let names = [];
        let filePaths = [];
        let parentPath = folderData.value.currentFile && folderData.value.currentFile.path ? folderData.value.currentFile.path : '';
        let filesArray = Array.from(files);
        filesArray.forEach((file) => {
            filePaths.push(parentPath + "/" + file.webkitRelativePath.substring(0, file.webkitRelativePath.lastIndexOf("/")));
        });
        this.getAllPaths(filesArray).forEach((fullPath) => {
            names.push(fullPath.substring(fullPath.lastIndexOf("/") + 1));
            paths.push(parentPath + fullPath.substring(0, fullPath.lastIndexOf("/")));
        });
        this.createDirectory(names, paths);
        return filePaths;
    }

    getAllPaths(filesArray) {
        let paths = new Set();
        filesArray.forEach((file) => {
            const subPaths = file.webkitRelativePath.split('/');
            let currentPath = "";
            for (let i = 0; i < subPaths.length - 1; i++) {
                currentPath += "/" + subPaths[i];
                paths.add(currentPath);
            }
        });
        return paths;
    }

    openRename(item) {
        folderData.value.actionType = "rename";
        folderData.value.fileExtension = "";
        folderData.value.addDialog = true;
        if (item.type === 'directory') {
            folderData.value.fileName = item.title;
        } else {
            folderData.value.fileName = item.title.substring(0, item.title.lastIndexOf('.'));
            folderData.value.fileExtension = item.title.substring(item.title.lastIndexOf('.'));
        }
        folderData.value.editFileId = item.id;
    }

    rename() {
        if (!folderData.value.editFileId) {
            console.log("No file id to rename.");
            return;
        }
        cart.value.startLoadingVar('appLoader');
        folderData.value.fileName += folderData.value.fileExtension ? folderData.value.fileExtension : '';
        FileService.renameFile(folderData.value.editFileId, folderData.value.fileName).then(file => {
            console.log ("Renamed file " + file.data.id);
            folderData.value.editFileId = null;
            let filesData = cart.value.filesData.find(data => data.id === file.data.id);
            if (filesData.directory) {
                this.getFiles().forEach(fileData => {
                    if (fileData.path && filesData.path && fileData.path.endsWith(filesData.path)) {
                        fileData.path = file.data.path;
                    }
                })
            }
            filesData.filename = file.data.filename;
            filesData.path = file.data.path;
            let folderEntry = folderData.value.contentItems.find(data => data.id === file.data.id)
            folderEntry.title = file.data.filename;
            folderEntry.path = file.data.path;
            // const path = folderData.value.currentFile && folderData.value.currentFile.path ? folderData.value.currentFile.path : null;
            // this.loadAll(folderData.value.currentCompany.id, path);
            cart.value.appLoader = false;
        });
    }

    openMove(item) {
        folderData.value.moveDialog = true;
        folderData.value.moveItem = item;
        folderData.value.moveFilePath = null;
    }

    move(moveId, moveToPath) {
        if (!moveId) {
            console.log("No file id to move.");
            return;
        }
        cart.value.startLoadingVar('appLoader');

        const moveIds = this.getSelectedItemOrDefault(moveId);
        const path = folderData.value.currentFile && folderData.value.currentFile.path ? folderData.value.currentFile.path : null;

        FileService.moveFile(moveIds, moveToPath).then(files => {
            files.data.forEach(file => {
                console.log ("Moved file " + files.id);
                // cart.value.filesData.find(data => data.id === file.id).path = file.path;
                let filesData = cart.value.filesData.find(data => data.id === file.id);
                if (filesData.directory) {
                    this.getFiles().forEach(fileData => {
                        if (fileData.path && filesData.path && fileData.path.startsWith(filesData.path)) {
                            fileData.path = file.path;
                        }
                    })
                }
                folderData.value.contentItems.find(data => data.id === file.id).path = file.path;
                filesData.path = file.path;
            });
            this.loadAll(folderData.value.currentCompany.id, path);
            cart.value.appLoader = false;
            folderData.value.moveDialog = false;
        });
    }

    downloadFile(ev, fileData) {
        ev.preventDefault();
        const fileIds = this.getSelectedItemOrDefault(fileData.id);
        if (fileIds.length > 1) {
            cart.value.appLoader = true;
            FileService.getAllFilesFromDirectory(fileIds).then(bytes => {
                cart.value.appLoader = false;
                if (!bytes || !bytes.data) return;
                const fileData = {
                    filename: "Icarus archive" + '.zip',
                    fileBytes: bytes.data
                }
                FileService.downloadFile(fileData);
            }).catch(e => {
                cart.value.showAlertDetails(i18n.global.t('loading.failed'));
                cart.value.appLoader = false;
            });
        } else {
            let clientFile = cart.value.filesData.find(f => f.id === fileData.id);
            FileService.getAndDownloadFile(clientFile);
        }
    }

    downloadFilePdf(ev, fileData) {
        ev.preventDefault();
        FileService.getFileAsPdf(fileData.id).then(file => {
            if (!file || !file.data) {
                cart.value.showAlertDetails(i18n.global.t('upload.extension.invalid.get'));
                return;
            }
            FileService.downloadFile(file.data);
        })
    }

    downloadAll(item) {
        console.log("Downloading all files from directory " + item.id);
        cart.value.appLoader = true;

        const downloadIds = this.getSelectedItemOrDefault(item.id);

        FileService.getAllFilesFromDirectory(downloadIds).then(bytes => {
            cart.value.appLoader = false;
            if (!bytes || !bytes.data) return;
            const fileData = {
                filename: item.title + '.zip',
                fileBytes: bytes.data
            }
            FileService.downloadFile(fileData);
        }).catch(e => {
            cart.value.showAlertDetails(i18n.global.t('loading.failed'));
            cart.value.appLoader = false;
        });
    }

    copyFiles(item) {
        folderData.value.clipboardItems = [item];
    }

    paste() {
        if (!folderData.value.clipboardItems || folderData.value.clipboardItems.length === 0) {
            console.log("No items to paste");
            return;
        }
        let ids = folderData.value.clipboardItems.map(item => item.id);
        const path = folderData.value.currentFile && folderData.value.currentFile.path ? folderData.value.currentFile.path : null;

        cart.value.appLoader = true;
        FileService.pasteFiles(ids, path, UserService.getLoggedUser().id).then(files => {
            files.data.forEach(file => {
                console.log ("Pasted file " + files.id);
                cart.value.filesData.push(file);
            });
            this.loadAll(folderData.value.currentCompany.id, path);
            cart.value.appLoader = false;

        });
        folderData.value.clipboardItems = [];
    }

    getSelectedItemOrDefault(defaultItemId) {
        let selectedIds = [defaultItemId];
        if (folderData.value.selectedItems && folderData.value.selectedItems.size >= 0) {
            selectedIds = Array.from(folderData.value.selectedItems).map(item => item.id);
        }
        return selectedIds;
    }

    enableAllContexts() {
        folderData.value.folderContextMenuItems.forEach(item => {
            item.disabled = false;
        });
        folderData.value.fileContextMenuItems.forEach(item => {
            item.disabled = false;
        });
        folderData.value.mainContextMenuItems.forEach(item => {
            item.disabled = false;
        });
    }

    disableContexts(contextItems, actionIds) {
        contextItems.forEach(item => {
            if (actionIds.includes(item.actionId)) {
                item.disabled = true;
            }
        });
    }

    processRootDirectory(e) {
        return new Promise((resolve, reject) => {
            folderData.value.uploadFileEntries = [];
            folderData.value.uploadDirectories = [];
            let items = []
            if (e instanceof DragEvent)
                items = e.dataTransfer.items;
            else if (e instanceof ClipboardEvent) {
                items = e.clipboardData.items;
            }
            let promises = [];
            for (let i = 0; i < items.length; i++) {
                const file = items[i];
                const entry = file.webkitGetAsEntry();
                if (entry.isFile) {
                    folderData.value.uploadFileEntries.push(entry);
                } else {
                    folderData.value.rootDir = entry;
                    folderData.value.uploadDirectories.push(entry);
                    promises.push(processDirectory(entry));
                }
            }
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    }

    //Used by the drop and clipboard events to upload the directories
    uploadDirectories(parentPath) {
        if (folderData.value.uploadDirectories.length === 0) return;
        let names = [];
        let paths = [];
        folderData.value.uploadDirectories.forEach(directory => {
            let name = directory.name;
            names.push(name)
            paths.push(parentPath + directory.fullPath.replace("/" + name, ""))
        });
        this.createDirectory(names, paths);
    }

    getFilesContent() {
        return new Promise((resolve, reject) => {
            const promises = [];
            folderData.value.uploadFiles = [];
            folderData.value.uploadFileEntries.forEach(fileEntry => {
                promises.push(new Promise(innerResolve => {
                    if (!fileEntry || !fileEntry.isFile) return;
                    fileEntry.file((f) => {
                        f.path = fileEntry.fullPath.replace("/" + fileEntry.name, "");
                        folderData.value.uploadFiles.push(f);
                        innerResolve();
                    });
                }));
            });
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    }

    resetBreadcrumbs(position) {
        folderData.value.breadcrumbItems.splice(position);
    }

    addBreadcrumbItem(position, title, location, disabled=false, path=null, companyId=null) {
        if (folderData.value.breadcrumbItems[position] && position !== -1) {
            folderData.value.breadcrumbItems[position].title = title;
            folderData.value.breadcrumbItems[position].disabled = disabled;
            return;
        }
        const href = "#"; location = '';
        const item = {
            'title': title,
            'disabled': disabled,
            'path': path,
            'companyId': companyId,
            'href': href + location,
            'position': folderData.value.breadcrumbItems.length
        }
        folderData.value.breadcrumbItems.push(item);
        folderData.value.currentPath = folderData.value.breadcrumbItems;
        folderData.value.historyBack.push(item);
    }

    getLoggedUserOrEmployer() {
        let loggedUser = UserService.getLoggedUser();
        loggedUser = this.getUserById(loggedUser.id);
        if (loggedUser && loggedUser.employer) {
            loggedUser = this.getUserById(loggedUser.employer);
        }
        return loggedUser;
    }
}

export function processDirectory(directory) {
    const reader = directory.createReader();
    return new Promise((resolve, reject) => {
        const iterations = [];
        function readEntries(){
            reader.readEntries(entries => {
                if(entries.length == 0){
                    resolve(Promise.all(iterations));
                } else {
                    iterations.push(Promise.all(entries.map(fileOrDir => {
                        if(fileOrDir.isFile){
                            // Handle the file as needed
                            folderData.value.uploadFileEntries.push(fileOrDir);
                        } else {
                            folderData.value.uploadDirectories.push(fileOrDir);
                            // Recursively process subdirectories
                            return processDirectory(fileOrDir);
                        }
                    })));
                    readEntries();
                }
            }, error => reject(error));
        }
        readEntries();
    });
}

export default new FolderService();

export const folderData = ref({
    contentItems: [],
    selectedItems: new Set(),
    clipboardItems: [],
    lastSelectedItem: null,
    currentPath: [],
    currentFile: null,
    currentCompany: null,
    deleteDialog: false,
    moveDialog: false,
    deleteFileId: false,
    addDialog: false,
    hideDirectories: false,
    moveItem: null,
    moveFilePath: null,
    fileName: "New Folder",
    actionType: null,
    editFileId: null,
    folderSearch: "",
    uploadFileEntries: [],
    uploadFiles: [],
    uploadDirectories: [],
    historyBack: [],
    filesToUpload: [],
    breadcrumbItems: [],

//    CONTEXT ITEMS

    folderContextMenuItems: [
        { actionId: 'open', disabled: false, title: 'open', icon: 'mdi-folder-arrow-right', onclick: (ev, item) => (new FolderService()).selectFolder(item) },
        { actionId: 'download', disabled: false, title: 'leftmenu.download', icon: 'mdi-folder-download', onclick: (ev, item) => (new FolderService()).downloadAll(item) },
        { actionId: 'copy', disabled: false, title: 'copy', icon: 'mdi-content-copy', onclick: (ev, item) => (new FolderService()).copyFiles(item) },
        { actionId: 'paste', disabled: false, title: 'paste', icon: 'mdi-content-paste', onclick: (ev, item) => (new FolderService()).paste() },
        { actionId: 'rename', disabled: false, title: 'rename', icon: 'mdi-pencil', onclick: (ev, item) => (new FolderService()).openRename(item) },
        { actionId: 'move', disabled: false, title: 'move', icon: 'mdi-folder-move', onclick: (ev, item) => (new FolderService()).openMove(item) },
        { actionId: 'delete', disabled: false, title: 'delete', icon: 'mdi-delete', onclick: (ev, item) => (new FolderService()).openDeleteDialog(ev, item) }
    ],
    mainContextMenuItems: [
        { actionId: 'paste', disabled: false, title: 'paste', icon: 'mdi-content-paste', onclick: (ev, item) => (new FolderService()).paste() },
        { actionId: 'newfolder', disabled: false, title: 'newfolder', icon: 'mdi-folder-plus', onclick: item => (new FolderService()).openCreate(item) },
        { actionId: 'uploadfile', disabled: false, title: 'uploadfile', icon: 'mdi-upload', onclick: () => document.getElementById("upload-files-input").click() },
        { actionId: 'uploadfolder', disabled: false, title: 'uploadfolder', icon: 'mdi-folder-upload', onclick: () => document.getElementById("upload-directory-input").click() },
    ],
    fileContextMenuItems: [
        { actionId: 'preview', disabled: false, title: 'preview', icon: 'mdi-file-find', onclick: (ev, item) => SelectionService.openFile(item) },
        { actionId: 'download', disabled: false, title: 'leftmenu.download', icon: 'mdi-download', onclick: (ev, item) => (new FolderService()).downloadFile(ev, item) },
        { actionId: 'downloadpdf', disabled: false, title: 'downloadpdf', icon: 'mdi-file-pdf-box', onclick: (ev, item) => (new FolderService()).downloadFilePdf(ev, item) },

        { actionId: 'copy', disabled: false, title: 'copy', icon: 'mdi-content-copy', onclick: (ev, item) => (new FolderService()).copyFiles(item) },
        { actionId: 'rename', disabled: false, title: 'rename', icon: 'mdi-pencil', onclick: (ev, item) => (new FolderService()).openRename(item) },
        { actionId: 'move', disabled: false, title: 'move', icon: 'mdi-file-move', onclick: (ev, item) => (new FolderService()).openMove(item) },
        { actionId: 'delete', disabled: false, title: 'delete', icon: 'mdi-delete', onclick: (ev, item) => (new FolderService()).openDeleteDialog(ev, item) }
    ]
});