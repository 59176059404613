import axios from 'axios';
import UserService from "@/services/UserService";
import {ClientFile} from "@/objects/ClientFile";
import {cart} from "@/services/cart";
import Helper from './Helper'
import i18n from "@/i18n";
import SubscriptionService from "@/services/SubscriptionService";
import router from "@/router";
import FolderService, {folderData} from "@/services/FolderService";

const FILE_STORAGE_API = Helper.getHost() + '/api/files';
const FOLDER_STORAGE_API = Helper.getHost() + '/api/directory';

class FileService{
    uploadedBy = 'None';
    files = [];
    REQUEST_MB_LIMIT = 25 * 1024 * 1024;

    uploadFiles(files, user, companyId, requestId, path){
        let formData = new FormData();
        this.uploadedBy = user;
        // const lastModified = files;
        files.forEach((clientFile) => {
            // formData.append('files', clientFile.file, clientFile.file.name);
            formData.append('fileNames', clientFile.file.name);
            formData.append('fileSizes', clientFile.file.size);
            formData.append('lastModified', clientFile.file.lastModifiedDate);
        });
        formData.append('userId', user.id);
        formData.append('requestId', requestId);
        formData.append('companyId', companyId);
        if (path)
            formData.append('path', path);
        return axios.post(FILE_STORAGE_API, formData, { headers: {'Content-Type': 'multipart/form-data', 'Company-Id': companyId}});
    }

    uploadFilesToCloud(files, companyId, user) {
        if (!files || files.length === 0) return;
        let formData = new FormData();
        this.uploadedBy = user;
        let ids = []
        files.forEach((clientFile) => {
            formData.append('files', clientFile.file, clientFile.file.name);
            ids.push(clientFile.id);
        });
        formData.append('userId', user.id);
        formData.append('ids', ids);

        return axios.post(FILE_STORAGE_API + "/cloud", formData, { headers: {'Content-Type': 'multipart/form-data', 'Company-Id': companyId}});
    }

    getSignedUrl(fileId) {
        return axios.get(FILE_STORAGE_API + "/signed/url", {headers: {'fileId': fileId, 'Access-Control-Allow-Origin': '*', 'access-control-allow-methods': '*'}})
    }

    getSignedUrlDownload(fileId) {
        return axios.get(FILE_STORAGE_API + "/signed/url/download", {headers: {'fileId': fileId, 'Access-Control-Allow-Origin': '*', 'access-control-allow-methods': '*'}})
    }

    getFileWithSignedUrl(url) {
        return axios.get(url, { headers: {
                'Content-Type': 'application/octet-stream',
                'Access-Control-Allow-Origin': '*',
                'access-control-allow-methods': '*'
            },
            responseType: "arraybuffer"
        });
    }

    putToSignedUrl(url, clientFile) {
        return axios.put(url, clientFile.file, { headers: {'Access-Control-Allow-Origin': '*', 'access-control-allow-methods': '*', 'content-type': 'application/octet-stream'}});
    }

    createDirectory(names, user, companyId, paths) {
        let formData = new FormData();

        formData.append('name', names);
        formData.append('userId', user.id);
        if (paths)
            formData.append('path', paths);
        formData.append('companyId', companyId);
        return axios.post(FOLDER_STORAGE_API, formData);
    }

    getFilesByCompany(companyIds) {
        return axios.get(FILE_STORAGE_API, {headers: {'Company-Id': companyIds}}).then(clientFiles => {
            console.log("Loaded " + clientFiles.data.length + " files for company with id " + companyIds);
            return clientFiles.data;
        });
    }

    getFilesByRequest(requestId) {
        return axios.get(FILE_STORAGE_API + "/request", {headers: {'requestId': requestId}}).then(clientFiles => {
            console.log("Loaded " + clientFiles.data.length + " files for request with id " + requestId);
            return clientFiles.data;
        });
    }

    deleteFile(fileIds) {
        console.log("Deleting file with id " + fileIds);
        return axios.delete(FOLDER_STORAGE_API, {headers: {'File-Ids': fileIds}});
    }

    resolveFile(fileId, resolved) {
        let formData = new FormData();
        formData.append('fileId', fileId);
        formData.append('isResolved', resolved);
        return axios.post(FILE_STORAGE_API+"/resolve", formData).then(clientFile => {
            console.log("Resolved file with id " + fileId);
            return clientFile.data;
        });
    }

    renameFile(fileId, name) {
        let formData = new FormData();
        formData.append('fileId', fileId);
        formData.append('name', name);
        return axios.post(FOLDER_STORAGE_API+"/rename", formData);
    }

    moveFile(fileIds, path) {
        let formData = new FormData();
        formData.append('fileIds', fileIds);
        formData.append('path', path);
        return axios.post(FOLDER_STORAGE_API+"/move", formData);
    }

    setDownloadedFile(fileId) {
        let formData = new FormData();
        formData.append('fileId', fileId);
        return axios.post(FILE_STORAGE_API+"/setDownloaded", formData);
    }

    getFileAsPdf(fileId) {
        return axios.get(FILE_STORAGE_API + "/pdf", {headers: {'fileId': fileId}})
    }

    getFileById(file) {
        if (file.fileSize > this.REQUEST_MB_LIMIT) {
            return this.downloadLargeFile(file.id);
        } else {
            return axios.get(FILE_STORAGE_API + "/id", {headers: {'fileId': file.id, 'getDataBytes': true}})
        }
    }

    getAllFilesByIds(fileIds) {
        if (this.getTotalFilesSize(fileIds) > this.REQUEST_MB_LIMIT) {
            return this.getAllFilesByIdsSignedUrl(fileIds).then((url) => {
                return this.getFileWithSignedUrl(url.data);
            });
        } else {
            return axios.get(FILE_STORAGE_API + "/ids", {headers: {'fileIds': fileIds}})
        }
    }

    getAllFilesByIdsSignedUrl(fileIds) {
        return axios.get(FILE_STORAGE_API + "/ids/signed", {headers: {'fileIds': fileIds}})
    }

    getAllFilesFromDirectory(fileIds) {
        if (this.getTotalFilesSize(fileIds) > this.REQUEST_MB_LIMIT) {
            return this.getAllFilesFromDirectorySignedUrl(fileIds).then((url) => {
                return this.getFileWithSignedUrl(url.data);
            });
        } else {
            return axios.get(FOLDER_STORAGE_API + "/download", {headers: {'fileIds': fileIds}})
        }
    }

    getAllFilesFromDirectorySignedUrl(fileIds) {
        return axios.get(FOLDER_STORAGE_API + "/download/signed", {headers: {'fileIds': fileIds}})
    }

    pasteFiles(fileIds, path, userId) {
        let formData = new FormData();
        formData.append('fileIds', fileIds);
        formData.append('path', path);
        formData.append('userId', userId);
        return axios.post(FOLDER_STORAGE_API + "/paste", formData);
    }

    // Utils

    loadFilesByCompanies() {
        cart.value.startLoadingVar('uploadTableLoading');
        cart.value.startLoadingVar('downloadTableLoading');
        // CompanyService.addDefaultCompanyToList();
        const companyIds = cart.value.companies.map(company => company.id);
        //If data was already loaded don't reload it
        if (cart.value.uploadTableData.length > 0) cart.value.uploadTableLoading = false;
        if (cart.value.downloadTableData.length > 0) cart.value.downloadTableLoading = false;
        if (cart.value.downloadTableData.length > 0 && cart.value.uploadTableData.length > 0) return;

        if (cart.value.company && cart.value.company.id) {
            this.getFilesByCompany(companyIds).then(files => {
                cart.value.filesData = files;
                cart.value.setDownloadTableData(files)
                cart.value.setUploadTableData(files)
                FolderService.loadAll(cart.value.company.id);
            });
        }
        cart.value.downloadTableLoading = false;
        cart.value.uploadTableLoading = false;
    }

    downloadLargeFile(fileId) {
        return this.getSignedUrlDownload(fileId).then(url => {
            return this.getFileWithSignedUrl(url.data);
        });
    }

    getFilesFromEvent(e) {
        if (!e) return;
        if (e.target && e.target.files) return e.target.files;
        if (e.dataTransfer && e.dataTransfer.files) return e.dataTransfer.files;
        const clipboard = e.clipboardData || window.clipboardData;
        if (clipboard && clipboard.files) return clipboard.files;
        return null;
    }

    getAndDownloadFile(file) {
        cart.value.appLoader = true;
        this.getFileById(file).then(response => {
            cart.value.appLoader = false;
            file = this.checkAndGetLargerFile(file, response);
            this.downloadFile(file, file.fileSize < this.REQUEST_MB_LIMIT);
        }).catch(() => {
            cart.value.appLoader = false;
        });
    }

    checkAndGetLargerFile(file, response) {
        if (file.fileSize > this.REQUEST_MB_LIMIT) {
            file.fileBytes = response.data;
            return file;
        }
        return response.data;
    }

    downloadFile(fileData, convertFromBase64 = true) {
        if (router.currentRoute.value.name === 'download' && !fileData.downloaded) this.setDownloadedFile(fileData.id);
        let bytes = fileData.fileBytes;
        if (convertFromBase64 && !isArrayBuffer(bytes))
            bytes = this.base64ToArrayBuffer(fileData.fileBytes);
        let blob = new Blob([bytes], {type: "octet/stream"});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileData.filename;
        link.click();
        // Clean up the URL.createObjectURL
        URL.revokeObjectURL(link.href);
    }

    base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const bytes = new Int8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    getFormattedDate(date) {
        if (typeof date === "string") {
            date = new Date(Date.parse(date));
        } else if (typeof date === "number") {
            date = new Date(date);
        } else if (date && date.seconds !== null && typeof date.seconds === "number") {
            date = new Date(date.seconds * 1000);
        } else if (!(date instanceof Date)) {return date}

        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd + " " + date.toLocaleTimeString("ro-RO");
    }

    getFilesSize() {
        if (!cart.value.uploadTableData) {
            return -1;
        }
        const sum = cart.value.uploadTableData.reduce((accumulator, object) => {
            return accumulator + object.fileSize;
        }, 0);
        return sum;
    }

    createFiles(files, requestId=null, paths=null, company=null) {
        let filesArray = Array.from(files);
        let user = UserService.getLoggedUser();
        this.filesToSend = [];
        if (!company)
            company = cart.value.company;
        // cart.value.startLoadingVar('appLoader');
        cart.value.appLoader = true;

        const totalFileSize = this.getFilesSize();
        if (SubscriptionService.verifyTotalFileSize(totalFileSize)) {
            cart.value.appLoader = false;
            cart.value.showAlertDetails(i18n.global.t('subscription.error.totalfile1') + cart.value.selectedSubscription.totalFileSize + i18n.global.t('subscription.error.totalfile2'), true);
            return;
        }

        if (SubscriptionService.verifyNumberOfFiles()) {
            cart.value.appLoader = false;
            cart.value.showAlertDetails(i18n.global.t('subscription.error.totalfile1') + cart.value.selectedSubscription.totalFileSize + " " + i18n.global.t('leftmenu.documents').toLowerCase(), true);
            return;
        }

        filesArray.forEach((file) => {
            if (!file.type.match('image.*|pdf.*|text.*|doc.*|docx.*|excel|video.*|audio.*')) {
                cart.value.appLoader = false;
                cart.value.showAlertDetails(i18n.global.t('upload.extension.invalid'));
                let uploadFile = new ClientFile("failed_id", company, file, false);
                uploadFile.failedUpload = true;
                this.addFileToUploadWindow(uploadFile);
                return;
            }

            if (SubscriptionService.verifyFileSize(file.size)) {
                cart.value.appLoader = false;
                cart.value.showAlertDetails(i18n.global.t('subscription.error.largefile1') + cart.value.selectedSubscription.maxFileSize + i18n.global.t('subscription.error.largefile2'), true)
                let uploadFile = new ClientFile("failed_id", company, file, false);
                uploadFile.failedUpload = true;
                this.addFileToUploadWindow(uploadFile);
                return;
            }
            this.filesToSend.push(new ClientFile(this.id, company, file, false));
        });
        if (!user) {
            cart.value.appLoader = false;
            return
        }
        return this.sendFiles(this.filesToSend, user, company, requestId, paths);
    }

    sendFiles(filesToSend, user, company, requestId, paths) {
        cart.value.showUploadFilesWindow = true;
        const companyIds = cart.value.companies.map(company => company.id);
        return this.uploadFiles(filesToSend, user, company.id, requestId, paths).then((files) => {
            files.data.forEach((file) => {
                cart.value.filesData.push(file);
            });
            FolderService.loadAll(cart.value.company.id, folderData.value.currentFile ? folderData.value.currentFile.path : null);
            this.getFilesByCompany(companyIds).then(files => {
                cart.value.setUploadTableData(files);
                cart.value.setDownloadTableData(files);
                cart.value.appLoader = false;
            });
            let fileIds = files.data.map(file => file.id);
            let largerFiles = [];
            filesToSend.forEach(fileSent => {
                fileSent.id = fileIds[filesToSend.indexOf(fileSent)];
                this.addFileToUploadWindow(fileSent);
                if (fileSent.file.size > this.REQUEST_MB_LIMIT) {
                    largerFiles.push(fileSent);
                }
            });
            //Handle larger than 30mb files separately
            largerFiles.forEach(largeFile => {
                filesToSend.splice(largeFile, 1);
                this.handleLargerFile(largeFile);
            });
            if (!filesToSend || filesToSend.length === 0) return;
            //Handles the rest of the files
            this.uploadFilesToCloud(filesToSend, company.id, user).then(response => {
                filesToSend.forEach(fileSent => {
                    let file = folderData.value.filesToUpload.find(f => f.id === fileSent.id);
                    file.uploaded = true;
                    cart.value.showAlertDetails(i18n.global.t('fileorfiles') + i18n.global.t('was') + i18n.global.t('uploaded') + i18n.global.t('withsuccess'))
                });
            });
        }).catch(err => {
            console.log(err);
            cart.value.showAlertDetails(i18n.global.t('loading.failed'));
            cart.value.appLoader = false;
        });
    }

    handleLargerFile(clientFile) {
        let id = clientFile.id;
        this.getSignedUrl(id).then(url => {
            let file = folderData.value.filesToUpload.find(f => f.id === clientFile.id);
            this.putToSignedUrl(url.data, clientFile).then(response => {
                file.uploaded = true;
            }).catch(() => {
                file.failedUpload = true;
            });
        });
    }

    addFileToUploadWindow(fileSent) {
        cart.value.showUploadFilesWindow = true;
        folderData.value.filesToUpload.push(fileSent);
        // let seconds = 30;
        // if (fileSent.file.size < this.REQUEST_MB_LIMIT) {
        //     seconds =
        // }
        //Set the failed upload icon after x seconds
        setTimeout(() => {
            let file = folderData.value.filesToUpload.find(f => f.id === fileSent.id);
            if (!file.uploaded) {
                file.failedUpload = true;
            }
        }, 300 * 1000);
    }

    filterFilesRole(files, role) {
        return files.filter(fileData => {
            let uploadedById = UserService.getUserByIdLocally(fileData.uploadedById);
            if (!uploadedById || !uploadedById.role) return;
            return cart.value.companies.find(company => company.id === fileData.companyId || company === fileData.companyId ||
                    company.id === fileData.fromCompanyId || company === fileData.fromCompanyId) &&
                uploadedById.role === role
        })
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    getUploadFiles() {
        const role = UserService.getLoggedUser().role;
        return cart.value.filesData.filter(fileData => {
            let uploadedById = UserService.getUserByIdLocally(fileData.uploadedById);
            if (!uploadedById || !uploadedById.role) return;
            // return true
            return uploadedById.role === role
        });
    }

    getDownloadFiles() {
        let loggedUser = UserService.getLoggedUserOrEmployer();
        return cart.value.downloadTableData.filter(fileData => {
            return fileData.companyId === loggedUser.defaultCompanyId
                && !fileData.directory
                && fileData.companyId !== fileData.fromCompanyId;
        });
    }

    downloadAllFiles(view) {
        let fileIds = []

        if (cart.value.gridView) {
            fileIds = cart.value.folderViewFiles
                .filter(fileData => {
                    return fileData.filename.toLowerCase()
                        .includes(cart.value.search.toLowerCase());
                })
                .map(fileData => fileData.id).join(', ');
        } else if (view === 'upload') {
            fileIds = cart.value.uploadTableDataFiltered
                .filter(fileData => {
                    return fileData.filename.toLowerCase()
                        .includes(cart.value.search.toLowerCase());
                })
                .map(fileData => fileData.id).join(', ');
        } else if (view === 'download') {
            fileIds = cart.value.downloadTableDataFiltered
                .filter(fileData => {
                    return fileData.filename.toLowerCase()
                        .includes(cart.value.search.toLowerCase());
                })
                .map(fileData => fileData.id).join(', ');
        }

        if (fileIds === null || fileIds === [] || fileIds === '') {
            console.log("Empty file ids");
            cart.value.appLoader = false;
            cart.value.showAlertDetails(i18n.global.t('downloadall.none'))
            return null;
        }
        if (!Array.isArray(fileIds)) fileIds = fileIds.split(", ");
        return this.getAllFilesByIds(fileIds)
    }

    getTotalFilesSize(fileIds) {
        if (!fileIds || fileIds.length === 0 || !Array.isArray(fileIds)) return 0;
        let totalSize = 0;
        fileIds.forEach(fileId => {
            let file = cart.value.filesData.find(f => f.id === fileId);
            if (file && file.fileSize)
                totalSize += file.fileSize;
        });
        return totalSize;
    }

    getAllFiles() {
        return cart.value.filesData.filter(file => {
            return !file.directory;
        });
    }

    validPreview(filename) {
        let extension = filename.substring(filename.lastIndexOf('.') + 1);
        let validExtensions = ['pdf', 'xlsx', 'xls', 'doc', 'docx', 'txt', 'log', 'csv', 'xml', 'ppt', 'pptx', 'png',
            'jpg', 'jpeg', 'tiff', 'svg', 'gif', 'mp4', 'mov', 'mp3', 'aac', 'wav', 'flac', 'm4a'];
        return validExtensions.includes(extension);
    }

    validPdfExport(filename) {
        let extension = filename.substring(filename.lastIndexOf('.') + 1);
        let validExtensions = ['pdf', 'xlsx', 'xls', 'doc', 'docx', 'txt', 'log', 'csv', 'xml', 'ppt', 'pptx', 'png',
            'jpg', 'jpeg', 'tiff', 'svg', 'gif'];
        return validExtensions.includes(extension);
    }

    deleteFileById(deleteFileId) {
        cart.value.startLoadingVar('uploadTableLoading');
        this.deleteFile(deleteFileId).then(() => {
            cart.value.showAlertDetails(i18n.global.t('file') + i18n.global.t('was') + i18n.global.t('deleted2') + i18n.global.t('withsuccess'))
            const deleted = cart.value.uploadTableData.find(data => data.id === deleteFileId);
            const index = cart.value.uploadTableData.indexOf(deleted);
            if (index !== -1) {
                cart.value.uploadTableData.splice(index, 1);
            }
            cart.value.uploadTableLoading = false;
        });
    }
}

export default new FileService();

function isArrayBuffer(variable) {
    return variable instanceof ArrayBuffer && variable.constructor === ArrayBuffer;
}
