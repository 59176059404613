<template>
  <div></div>
</template>

<script>
import router from "@/router";
import {cart} from "@/services/cart";
import UserService from "@/services/UserService";

export default {
  name: "ActivateAccount",
  mounted() {
    this.uuid = this.$route.query.uuid;
    if (!this.uuid) router.push({path: "login"});
    UserService.activateAccount(this.uuid).then(result => {
      cart.value.activatedAccountSuccessfully = result.data;
      router.push({path: "login"});
    })
  },
  data() {
    return {
      uuid: false,
    }
  }
}
</script>

<style scoped>

</style>