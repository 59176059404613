import {ref} from "vue";
import i18n from "@/i18n";

export const rules = ref({
    emailRules: [
        value => {
            if (value) return true

            return i18n.global.t('email.required')
        },
        value => {
            if (/.+@.+\..+/.test(value)) return true

            return i18n.global.t('email.required')
        },
    ],
    email: value => (/.+@.+\..+/.test(value)) || i18n.global.t('email.valid'),
    pass: value => (/(?=[A-Za-z0-9@$!%*#?~(&)+=^_-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?~(&)+=^_-])(?=.{12,}).*$/.test(value)) || i18n.global.t('pass'),
    required: value => !!value || i18n.global.t('required'),
    passLength: v => v.length >= 12 || i18n.global.t('pass.length'),
    cui: v => (/^[0-9]{1,9}$/).test(v)  || i18n.global.t('rule.cui'),
    registry: v => (/^([J|F|C])[0-9]{2}\/[0-9]{1,6}\/[0-9]{4}$/.test(v)) || i18n.global.t('rule.registry'),
    iban: v => (!v || (v.length <= 33 && v.length) >= 15 || v.length === 0) || i18n.global.t('rule.iban'),
    â: v => (!v || (v.length <= 50 && v.length) || v.length === 0) || i18n.global.t('rule.max20Chars'),
    phone: v => (!v || (/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v)) || v.length === 0) || i18n.global.t('rule.phone'),
});