
import {cart} from "@/services/cart";
import router from "@/router";
import UserService from "@/services/UserService";

class TutorialService {

    highlightElements() {
        let elements = document.getElementsByClassName("tutorial-step-" + cart.value.tutorialCurrentStep);
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.zIndex = 1250;
            switch (cart.value.tutorialCurrentStep) {
                case 2: case 3: case 4: case 5: case 6:
                    // case 8: case 9:
                    elements[i].style.position = 'relative';
                    elements[i].style.backgroundColor = 'white';
                    elements[i].style.pointerEvents = 'none';
                    break;
                case 10:
                    elements[i].style.zIndex = '1250 !important';
                    elements[i].style.pointerEvents = 'none';
                    break;
            }
            if (cart.value.tutorialCurrentStep === 2) {
                elements[i].style.padding = '20px 0';
            }
        }
    }

    unhighlightElements() {
        let elements = document.getElementsByClassName("tutorial-step-" + cart.value.tutorialCurrentStep);
        for (let i = 0; i < elements.length; i++) {
            // elements[i].style.zIndex = 1000;
            elements[i].style.removeProperty('z-index');
            switch (cart.value.tutorialCurrentStep) {
                case 2: case 3: case 4: case 5: case 6:
                    // case 8: case 9:
                    elements[i].style.position = 'sticky';
                    elements[i].style.removeProperty('background-color');
                    elements[i].style.pointerEvents = 'auto';
                    break;
                case 10:
                    elements[i].style.zIndex = '1004';
                    elements[i].style.pointerEvents = 'auto';
                    break;
            }
            if (cart.value.tutorialCurrentStep === 2) {
                elements[i].style.removeProperty('padding');
            }
        }
    }

    previous() {
        if (cart.value.tutorialCurrentStep === 1) return;
        this.unhighlightElements();
        this.hideTutorialBox();
        cart.value.tutorialCurrentStep --;
        if (cart.value.tutorialCurrentStep === 2) router.push({name: 'overview'})
        if (cart.value.tutorialCurrentStep === 4) router.push({name: 'download'})
        if (cart.value.tutorialCurrentStep === 5) router.push({name: 'upload'})
        if (cart.value.tutorialCurrentStep === 6) cart.value.tutorialDetailsDialog = false;
        if (cart.value.tutorialCurrentStep === 7) {
            router.push({name: 'requests'});
            setTimeout(() => {}, 250);
        }
        // if (cart.value.tutorialCurrentStep === 9) router.push({name: 'invoices'})
        if (cart.value.tutorialCurrentStep === 8) {
            this.detailsDialog = false;
            if (!cart.value.companies || cart.value.companies.length < 2) {
                cart.value.company = {name: 'Firma Ex', id: 'tutorial'};
                cart.value.companies = [{name: 'Firma Ex', id: 'tutorial'}, {name: 'Tutorial 2', id: 'tutorial2'}]
            }
        }
        if (cart.value.tutorialCurrentStep === 9) router.push({name: 'companies'})
        this.highlightElements();
        this.showTutorialBox();
    }

    next() {
        if (cart.value.tutorialCurrentStep === 8) {
            if (this.isFreeUser()) {
                this.finalizeTutorial();
                return;
            }
        }
        if (cart.value.tutorialCurrentStep === this.totalSteps) return;
        this.unhighlightElements();
        this.hideTutorialBox();
        cart.value.tutorialCurrentStep ++;
        if (cart.value.tutorialCurrentStep === 3) router.push({name: 'download'})
        if (cart.value.tutorialCurrentStep === 5) router.push({name: 'upload'})
        if (cart.value.tutorialCurrentStep === 6) router.push({name: 'requests'})
        if (cart.value.tutorialCurrentStep === 7) cart.value.tutorialDetailsDialog = true;
        // if (cart.value.tutorialCurrentStep === 8) router.push({name: 'invoices'})
        if (cart.value.tutorialCurrentStep === 8) {
            if (!cart.value.companies || cart.value.companies.length < 2) {
                setTimeout(() => {
                    cart.value.companies = [{name: 'Firma Ex', id: 'tutorial'}, {name: 'Tutorial 2', id: 'tutorial2'}]
                }, 200);
            }
            if (!this.isFreeUser()) router.push({name: 'companies'});
        }
        if (cart.value.tutorialCurrentStep === 10) router.push({name: 'people'})
        this.highlightElements();
        this.showTutorialBox();
    }

    showTutorialBox() {
        const tutorialBox = document.getElementById("tutorial-" + cart.value.tutorialCurrentStep);
        if (!tutorialBox) return;
        tutorialBox.style.display = "block";
    }

    hideTutorialBox() {
        const tutorialBox = document.getElementById("tutorial-" + cart.value.tutorialCurrentStep);
        tutorialBox.style.display = "none";
    }

    isFreeUser() {
        const user = UserService.getLoggedUser();
        return user.subscriptionType && (user.subscriptionType.toLowerCase() === 'free' || user.subscriptionType.toLowerCase() === 'freeemployee')
    }

    finalizeTutorial() {
        this.unhighlightElements();
        cart.value.startTutorial = false;
        this.skipTutorialModal = false;
        cart.value.tutorialCurrentStep = 1;
        UserService.tutorialDone(UserService.getLoggedUser().id);
        cart.value.company = null;
        router.push('overview');
    }
}

export default new TutorialService();