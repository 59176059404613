<template>
  <v-card class="top-bar rounded-0 tutorial-step-1 tutorial-step-8">
    <v-toolbar app color="primary"
               height="48"
               elevation="2" dense fixed
                style="position: fixed; top: 0;"
    >
      <v-spacer v-if="mq.current !== 'mobile'"></v-spacer>
      <div :class="mq.current === 'mobile' ? 'app-title-mobile' : 'app-title'">
        <v-img @click="goToOverview" aspect-ratio="16/9" alt="logo" :width="mq.current !== 'mobile' ? 40 : 40" :src="require('@/assets/logo-white.svg')"></v-img>
        <span @click="goToOverview" :style="mq.current === 'mobile' ? 'margin-left: 5px;' : 'margin-left: 20px; margin-top: 8px;'" v-if="mq.current !== 'mobile'">
          {{ $t("topbar.appname") }}
<!--          <span style="font-size: 8px;">BETA</span>-->
        </span>
        <v-btn v-if="mq.current !== 'mobile' && !isFreeSub" size="smaller" class="account-button" style="background: transparent;">
          <v-icon color="yellow-darken-1" alt="premium">mdi-crown</v-icon>
          <tooltip-message :message="$t('premium.user')"></tooltip-message>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-select
          class="company-select"
          v-if="cart.company && cart.companies.length > 0 && !register"
          @update:modelValue="changeCompany"
          :label="$t('company')"
          :items="filterCompanies"
          v-model="cart.company.id"
          item-title="name"
          item-value="id"
          density="compact"
          variant="solo-filled"
          style="margin: 23px 10px 0 10px; max-width: 350px;"
          color="primary"
      >
        <template v-slot:prepend-item>
          <v-text-field v-model="companySearch" :label="$t('leftmenu.search')" hide-details variant="solo" density="compact"></v-text-field>
        </template>
        <template v-slot:selection="">
          <span v-if="cart.company" :style="cart.company.name.length > 18 ? 'font-size: small;' : ''"> {{ cart.company.name.length > 20 ? cart.company.name.substring(0, 20) + ".." : cart.company.name }} </span>
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :subtitle="item.cui"></v-list-item>
        </template>
      </v-select>
      <v-spacer></v-spacer>
      <div v-if="!register" style="display: contents; margin-right: 5px;">
<!--        <locale-switcher v-if="mq.current !== 'mobile'"></locale-switcher>-->
        <v-btn style="background: rgb(var(--v-theme-success)); color: white;" v-if="mq.current !== 'mobile' && isFreeSub"
               size="smaller" @click="showUpgradeScreen = true" class="account-button moving-image" elevation="3">
          <v-icon>mdi-arrow-up-thick</v-icon>
          <tooltip-message :message="$t('request') + ' Upgrade'"></tooltip-message>
        </v-btn>
        <support-overlay v-if="mq.current !== 'mobile'"></support-overlay>
<!--        <v-btn v-if="mq.current !== 'mobile'" size="smaller" @click="toggleTheme(); toggleThemeIcon();" class="theme-btn account-button">-->
<!--          <v-icon>{{ themeIcon }}</v-icon>-->
<!--        </v-btn>-->
        <v-btn v-if="mq.current !== 'mobile'" size="smaller" @click="goToSettings" class="settings-btn account-button">
          <v-icon> mdi-cog </v-icon>
        </v-btn>

        <v-btn v-if="!cart.iconAvailable" border size="smaller" class="account-button" :style="{backgroundColor: randomColor}" @click="toggleAccountDialog">
          {{ getInitials() }}
        </v-btn>
        <v-btn v-if="cart.iconAvailable" border size="smaller" class="account-button" @click="toggleAccountDialog">
          <v-img class="profile-picture" :src="cart.logo" style="padding: 5px;"></v-img>
        </v-btn>
      </div>
      <v-btn v-if="register" @click="goToOverview">{{ $t('login') }}</v-btn>
    </v-toolbar>
  </v-card>

  <v-dialog
            v-model="accountDialog"
            rounded
            class="account-card-parent"
  >
    <v-card class="account-card-container"
        position="absolute"
        max-width="400">
      <div class="account-card-header">
        <v-row style="padding: 8px 15px;" v-if="mq.current === 'mobile'">
          <v-btn size="smaller" @click="goToSettings" class="settings-btn account-button" style="margin: 10px 0 0 5px;">
            <v-icon> mdi-cog </v-icon>
          </v-btn>
<!--          <locale-switcher style="z-index: 1001;"></locale-switcher>-->
          <v-spacer></v-spacer>
          <support-overlay style="margin: 10px 5px 0 0; z-index: 1001;"></support-overlay>
<!--          <v-btn size="smaller" @click="toggleTheme(); toggleThemeIcon();" class="theme-btn account-button" style="margin: 10px 0 0 0; z-index: 1001;">-->
<!--            <v-icon>{{ themeIcon }}</v-icon>-->
<!--          </v-btn>-->
        </v-row>
      </div>
      <v-card-item class="profile-header" style="padding: 0; z-index: 10; margin: 10px 0;">
        <v-icon class="account-card-profile-image" :style="mq.current === 'mobile' ? 'margin-left: 150%' : ''">mdi-account</v-icon>
<!--        <v-img class="account-card-profile-image" :src="cart.logo" style="padding: 5px;" :style="mq.current === 'mobile' ? 'margin-left: 150%' : ''"></v-img>-->
        <span v-if="mq.current !== 'mobile'" style="font-size: 16px; margin-left: 10px; color: white;">{{ name }}</span>
      </v-card-item>
      <v-card-item v-if="mq.current === 'mobile'" class="center-text" style="padding: 0; z-index: 10; margin: 5px 0 0 0;">
        <span style="font-size: 16px; margin-left: 10px;">{{ name }}</span>
      </v-card-item>
      <v-card-item class="center-text" style="margin: 0 0 10px 0; padding: 0;" v-if="admin">
        <span style="font-size: small; font-weight: bold; color: #756cec">Admin</span>
      </v-card-item>
      <v-card-item style="font-size: small; white-space: nowrap; margin-top: 10px;">
        <v-icon class="account-card-item-image">mdi-mail</v-icon>
        <span>{{ email }}</span>
      </v-card-item>
<!--      <v-card-item style="font-size: small;">
        <v-icon class="account-card-item-image">mdi-account-tie</v-icon>
        <span>{{ role }}</span>
      </v-card-item>-->
<!--      <v-card-item style="font-size: small; overflow: hidden; white-space: nowrap;">-->
<!--        <v-icon class="account-card-item-image">mdi-domain</v-icon>-->
<!--        <span>{{ companies }}</span>-->
<!--      </v-card-item>-->
      <v-card-item v-if="cart.mycompany && cart.mycompany.id" style="font-size: small; overflow: hidden; white-space: nowrap;">
        <v-icon class="account-card-item-image">mdi-home</v-icon>
        <span>{{ cart.mycompany.name }}</span>
      </v-card-item>
      <v-card-item style="font-size: small; overflow: hidden; white-space: nowrap; cursor: pointer;" @click="goToRequests()" id="account-requests">
        <tooltip-message :message="$t('requests.move')"></tooltip-message>
        <v-icon class="account-card-item-image">mdi-file-find</v-icon>
        <span>{{ requestsNumber + $t('requests.toresolve') }}</span>
      </v-card-item>
      <v-card-item style="font-size: small; overflow: hidden; white-space: nowrap; cursor: pointer;" @click="goToDownloads()" id="account-requests">
        <tooltip-message :message="$t('downloads.move')"></tooltip-message>
        <v-icon class="account-card-item-image">mdi-file-download</v-icon>
        <span>{{ downloadsNumber + $t('downloads.account') }}</span>
      </v-card-item>
      <v-spacer></v-spacer>
      <v-card-item class="center-text">
        <v-btn size="small" @click="logout" style="overflow: inherit; margin: 10px 0 10px 0;">
          <v-icon>mdi-logout</v-icon>
          Logout
        </v-btn>
      </v-card-item>
    </v-card>
  </v-dialog>
  <v-alert v-model="notif.show"
           :title="notif.title"
           density="compact"
           border="top"
           border-color="primary"
           :text="notif.text"
           elevation="4"
           class="general-alert">
    <v-btn size="x-small" icon="mdi-close" class="btn-alert-close" @click="notif.show = false" elevation="1"></v-btn>
    <br v-if="notif.showUpgrade"/>
    <v-btn v-if="notif.showUpgrade" size="smallest" @click="showUpgradeScreen = true; notif.show = false;" class="btn-upgrade-alert" elevation="2">
      <v-icon>mdi-arrow-up-thick</v-icon>
      <span>Upgrade</span>
      <tooltip-message :message="$t('request') + ' Upgrade'"></tooltip-message>
    </v-btn>
  </v-alert>
  <v-overlay
      :model-value="cart.appLoader"
      persistent
      class="align-center justify-center"
  >
    <v-progress-circular
        color="primary"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>

  <v-dialog
      v-model="showUpgradeScreen"
      max-width="500px"
      class="icarus-dialog"
      persistent
  >
    <upgrade-subscription @upgradeDialog="showUpgradeScreen = false"/>
  </v-dialog>
  <UploadLoaderWindow></UploadLoaderWindow>

  <v-dialog eager v-model="cart.filePreviewDialog">
    <preview-dialog :previewFileSrc="cart.previewFileSrc" :previewFileSrcDoc="cart.previewFileSrcDoc" @filePreviewDialog="cart.filePreviewDialog = false"></preview-dialog>
  </v-dialog>


</template>

<script>
import UserService from "@/services/UserService";
import { useTheme } from 'vuetify'
import { cart } from '@/services/cart';
import router from "@/router";
import SupportOverlay from "@/components/mini/SupportOverlay";
import UpgradeSubscription from "@/components/mini/UpgradeSubscription";
import FileService from "@/services/FileService";
import CompanyService from "@/services/CompanyService";
import SubscriptionService from "@/services/SubscriptionService";
import $cookies from "vue-cookie";
import TooltipMessage from "@/components/mini/Tooltip";
import RequestService from "@/services/RequestService";
import Helper from "@/services/Helper";
import InvoiceService from "@/services/InvoiceService";
import FolderService from "@/services/FolderService";
import Settings from "@/components/Settings";
import SettingsService from "@/services/SettingsService";
import i18n from "@/i18n";
import UploadLoaderWindow from "@/components/fileComponents/UploadLoaderWindow";
import PreviewDialog from "@/components/mini/PreviewDialog";
import DataSseStream from "@/services/DataSseStream";

export default {
  name: "TopBar",
  components: {TooltipMessage, SupportOverlay, UpgradeSubscription, UploadLoaderWindow, PreviewDialog},
  inject: ['mq'],
  props: {
    register: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const theme = useTheme()
    const notif = cart.value.notification

    return {
      theme,
      notif,
      cart,
      toggleTheme() {
        cart.value.isDark = theme.global.current.value.dark;
        theme.global.name.value = cart.value.isDark ? 'mainTheme' : 'mainDarkTheme';
        $cookies.set('theme', theme.global.name.value);
      }
    }
  },
  data: () => ({
      username: "User",
      name: "User",
      email: "Email",
      role: "Client",
      themeIcon: 'mdi-moon-waning-crescent',
      randomColor: 'red',
      accountDialog: false,
      iconAvailable: false,
      showUpgradeScreen: false,
      showAlert: false,
      alertTitle: "Title",
      alertText: "Alert text",
      admin: false,
      companySearch: "",
      companies: 'Companies',
      // themeIcon: 'mdi-moon-waning-crescent'
      // tabs: ["Download", "Upload"]
  }),
  computed: {
    filterCompanies() {
      if (this.companySearch.length > 1) {
        let items = cart.value.companies.filter(company => {
          if (!company || !company.name) return false;
          return company.name.toLowerCase().indexOf(this.companySearch.toLowerCase()) > -1 ||
                company.cui.indexOf(this.companySearch.toLowerCase()) > -1
        });
        if (items.length > 0)
          return items.filter(company => company.name);
        // else
        //   return [];
      }
      if (cart.value.mycompany) {
        // const index = cart.value.companies.indexOf(cart.value.mycompany);
        // cart.value.companies.splice(index,1);
        // cart.value.companies.unshift(cart.value.mycompany);

        // cart.value.company = cart.value.companies[0];
        // this.changeCompany(cart.value.company.id);
      }
      return cart.value.companies.filter(company => company.name);
    },
    requestsNumber() {
      let user = UserService.getLoggedUser();
      return cart.value.requestsData.filter(request =>
          cart.value.companies.find(company => company.id === request.companyId || company === request.companyId) &&
          (user.defaultCompanyId === request.companyId || user.defaultCompanyId === request.fromCompanyId) &&
          !request.resolved
      ).length;
    },
    downloadsNumber() {
      return FileService.getDownloadFiles().length;
    },
    isFreeSub() {
      return SubscriptionService.isFreeUser();
    }
  },
  mounted() {
    // DataSseStream.subscribe();
    if (this.register) return;
    let user = UserService.getLoggedUser();
    if (user) {
      this.username = user.name + ", " + user.role
      this.name = user.name
      this.email = user.email
      this.role = user.role
      this.admin = user.admin
      if ($cookies.get('theme')) {
        this.theme.global.name.value = $cookies.get('theme');
        cart.value.isDark = $cookies.get('theme') !== 'mainTheme';
      }
      UserService.getCompanies(user.id).then(companies => {
        if (!companies || !companies.data) return;
        cart.value.companies = companies.data;
        cart.value.filterCompanies = cart.value.companies;
        const companiesIds = cart.value.companies.map(company => company.id).join(', ');
        if (cart.value.company == null) {
          cart.value.company = companies.data[0];
        }

        // Load the files
        this.companies = cart.value.companies.map(company => company.name).join(', ');
        // DataSseStream.subscribe(companiesIds);

        const companyId = localStorage.getItem("selectedCompanyId");
        if (companyId && companiesIds.includes(companyId)) {
          this.changeCompany(companyId, false);
        }

        UserService.getUsersByCompany(companiesIds).then(users => {
          cart.value.usersByCompany = users.data;
          users.data.forEach(user => {
            cart.value.usersCache.push(user);
          });
          this.loadDefaultCompany();
          this.loadFiles();
        });
        this.loadRequests();
        // this.loadInvoices();
      });
      //Load user settings
      this.applyUserSettings();
      UserService.getUserEmployees(user.id).then(employees => {
        if (!employees || !employees.data) return;
        cart.value.employees = employees.data;
      });
      SubscriptionService.getAllDetails().then(subs => {
        cart.value.subscriptions = subs.data.reverse();
      });
      SubscriptionService.setActiveSub(user.id);
      this.generateColorFromName();
    } else {
      console.log("TopBar - Logged out")
      UserService.logout();
      router.push({ path: '/login' });
    }
  },
  methods: {
    getReversedRole(role) {
      if (role === 'Accountant') {
        return 'Client';
      } else if (role === 'Client') {
        return 'Accountant';
      }
    },
    loadDefaultCompany() {
      CompanyService.loadDefaultCompany();

      // if (!cart.value.mycompany || Object.keys(cart.value.mycompany).length === 0 || cart.value.mycompany.id === '') {
        // let user = UserService.getLoggedUser();
        // UserService.getUserById(user.id).then(async userData => {
          // if (cart.value.logo && cart.value.iconAvailable) return;
          // cart.value.logo = require('@/assets/images/account.svg');
          // if (!userData.data.logoFileId) {
          //   cart.value.iconAvailable = false;
          //   return;
          // }
          // FileService.getFileById(userData.data.logoFileId).then(clientFile => {
          //   if (!clientFile) return;
          //   let extension = clientFile.data.filename.substring(clientFile.data.filename.indexOf('.') + 1);
          //   if (extension === 'svg') extension += '+xml';
          //   cart.value.logo = 'data:image/' + extension + ';base64,' + clientFile.data.fileBytes;
          //   cart.value.iconAvailable = true;
          // });
        // });
      // }
    },
    getCompanyById(compId) {
      return CompanyService.getCompanyById(compId);
    },
    loadRequests() {
      if (cart.value.requestsData.length === 0) {
        const companyIds = cart.value.companies.map(company => company.id);
        RequestService.getByCompany(companyIds).then(requests => {
          cart.value.requestsData = requests.data;
        });
      }
    },
    loadInvoices() {
      if (!cart.value.companies || cart.value.companies.length === 0) return;
      if (cart.value.invoicesData.length === 0) {
        const companyIds = cart.value.companies.map(company => company.id);
        InvoiceService.getByCompany(companyIds).then(invoices => {
          cart.value.invoicesData = invoices.data;
        });
      }
    },
    loadFiles() {
      FileService.loadFilesByCompanies();
    },
    toggleThemeIcon() {
      cart.value.isDark = !cart.value.isDark;
      Helper.selectMenuItem(router.currentRoute.value.name);
      this.themeIcon = cart.value.isDark ? 'mdi-white-balance-sunny' : 'mdi-moon-waning-crescent';
    },
    getInitials() {
      let names = this.name.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    generateColorFromName() {
      let val = 0;
      for(let i = 0; i < this.name.length; i++){
        let code = this.name.charCodeAt(i);
        val += code;
      }
      const randomColor = Math.floor(val).toString(16);
      this.randomColor = "#" + randomColor;
    },
    toggleAccountDialog() {
      this.accountDialog = !this.accountDialog;
    },
    logout() {
      cart.value.appLoader = true;
      UserService.logout();
    },
    goToOverview() {
      if (this.register) {
        router.push({ path: '/login' })
        return;
      }
      router.push({ path: '/overview' })
    },
    goToRequests() {
      cart.value.filterCompanies = cart.value.companies;
      localStorage.removeItem("selectedCompanyId");
      router.push({ path: '/requests' })
    },
    goToDownloads() {
      cart.value.filterCompanies = cart.value.companies;
      localStorage.removeItem("selectedCompanyId");
      router.push({ path: '/download' })
    },
    changeCompany(companyId, all = false) {
      CompanyService.changeCompany(companyId, all);
    },
    goToSettings() {
      Helper.selectMenuItem('settings');
      router.push({ path: 'settings' });
    },
    applyUserSettings() {
      const settings = SettingsService.getUserSettings();
      i18n.global.locale.value = settings.Language;
      this.theme.global.name.value = settings.Theme;
      cart.value.isDark = settings.Theme !== 'mainTheme';
    }
  }
}
</script>

<style scoped>

.company-select:deep(.v-field__field) {
  height: 36px;
}

.company-select:deep(.v-field__input) {
  padding-top: 4px;
}

.company-select:deep(.v-field-label--floating) {
  top: 0 !important;
}

.theme-btn {
  /*position: absolute;*/
  /*right: 100px;*/
  margin-right: 10px;
}

.account-button {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  font-size: medium;
  z-index: 1215;
}

.top-bar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1010;
}

.account-card-parent:deep(.v-overlay__content) {
  top: 0;
  /*position: absolute;*/
}

.account-card-container {
  width: 250px;
  height: 360px;
  right: 0;
  bottom: -60px;
  /*position: sticky;*/
  /*position: -webkit-sticky;*/
  top: 60px;
}

.profile-header:deep(.v-card-item__content) {
  display: contents;
}

.account-card-profile-image:deep(img) {
  padding: 5px;
}

.account-card-profile-image {
  border: 2px solid rgb(var(--v-theme-secondary));
  border-radius: 50px;
  background-color: white;
  width: 60px;
  height: 60px;
  font-size: 40px;
  margin-left: 20px;
}

.account-card-profile-image.v-theme--mainDarkTheme {
  background-color: white;
  color: rgb(var(--v-theme-secondary));
  border: 2px solid rgb(var(--v-theme-secondary));
}

.profile-picture {
  border: 2px solid white;
  border-radius: 50px;
  background-color: transparent;
  width: 40px;
  height: 40px;

}

.center-text {
  text-align: center;
}

.account-card-header {
  position: absolute;
  /*background: linear-gradient(to right,  #756cec, #2e73ee, #756cec);*/
  background-image: linear-gradient(rgb(var(--v-theme-gradient1)), rgb(var(--v-theme-gradient2)), rgb(var(--v-theme-gradient1)));

  /*background-image: url("https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg");*/
  width: 100%;
  height: 80px;
}

.account-card-item-image {
  margin-right: 5px;
  color: darkgray;
}

#account-requests:hover {
  opacity: 0.6;
}

.app-title-mobile {
  cursor: pointer;
  font-size: larger;
  margin-left: 10px;
  display: flex;
}

.app-title {
  cursor: pointer;
  font-size: larger;
  /*margin: 0 50%;*/
  margin: 0 20px;
  position: absolute;
  display: flex;
}

.account-button {
  margin: 3px;
}

.settings-btn {
  margin-right: 10px;
  z-index: 1001;
}

.moving-image {
  -webkit-animation: action 1s infinite  alternate;
  animation: action 1s infinite  alternate;
  animation-duration: 10s;
}

.general-alert {
  z-index: 3010;
  position: fixed;
  top: 40px;
  margin: 20px max(calc(50% - 200px), 20px);
  max-width: 80%;
  width: 400px;
  padding: 20px 35px 10px 15px;
}

.btn-upgrade-alert {
  background: rgb(var(--v-theme-success));
  color: white;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 2px;
  font-size: 13px;
}

.btn-alert-close {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 10;
  background: #eeeeee;
}

@-webkit-keyframes action {
  0% { transform: translateY(5px); }
  5% { transform: translateY(-5px); }
  10% { transform: translateY(0px); }
  100% { transform: translateY(0px); }
}

@keyframes action {
  0% { transform: translateY(5px); }
  5% { transform: translateY(-5px); }
  10% { transform: translateY(0px); }
  100% { transform: translateY(0px); }
}

</style>