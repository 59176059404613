export class ClientFile {
    id;
    company;
    uploaded;
    file;

    constructor(id, company, file, uploaded) {
        this.id = id;
        this.company = company;
        this.file = file;
        this.uploaded = uploaded;
    }
}