<template>
  <v-layout>
    <v-navigation-drawer
        v-model="selectedNav"
        v-if="mq.current !== 'mobile'"
        disable-resize-watcher
        disable-route-watcher
        permanent
        id="left-menu"
        width="200px"
        style="max-width: 200px; min-width: 200px; background: rgb(var(--v-theme-leftMenu))"
        elevation="2"
    >
      <v-list
          bg-color="primary" height="48">
        <v-list-item
            class="page-title-container"
        >
          <span>Icarus</span>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-divider></v-divider>

      <v-list density="compact" ref="menu-items" nav>
        <div>
          <v-list-item id="upload" prepend-icon="mdi-upload" :disabled="isFree" :title="$t('leftmenu.upload')" value="upload" active-color="primary" @click="changeView('upload')"></v-list-item>
          <tooltip-message :message="!isFree ? $t('upload.file') : $t('subscription.notavailable')"></tooltip-message>
        </div>
        <div>
          <v-list-item id="download" prepend-icon="mdi-download" :title="$t('leftmenu.download')" value="download" active-color="primary" ref="myBtn" @click="changeView('download')"></v-list-item>
        </div>
        <div>
          <v-list-item id="documents" prepend-icon="mdi-text-box-multiple" :disabled="isFree" :title="$t('leftmenu.documents')" value="documents" active-color="primary" @click="changeView('documents')"></v-list-item>
          <tooltip-message :message="!isFree ? $t('documents.info') : $t('subscription.notavailable')"></tooltip-message>
        </div>

        <div>
          <v-badge v-if="getRequestsNumber > 0" :content="getRequestsNumber" class="badge-request" color="primary"></v-badge>
          <v-list-item id="requests" prepend-icon="mdi-file-find" :disabled="isFree" :title="$t('leftmenu.requests')" value="requests" active-color="primary" @click="changeView('requests')"></v-list-item>
          <tooltip-message :message="!isFree ? $t('leftmenu.requests.tooltip') : $t('subscription.notavailable')"></tooltip-message>
        </div>
        <div style="display: none;">
          <v-list-item id="invoices" prepend-icon="mdi-receipt-text-arrow-right" :hidden="isFree || !invoiceSub" :title="$t('leftmenu.invoices')" value="invoices" active-color="primary" @click="changeView('invoices')"></v-list-item>
          <tooltip-message :message="!isFree && invoiceSub ? $t('leftmenu.invoice.tooltip') : $t('subscription.notavailable')"></tooltip-message>
        </div>
      </v-list>

      <v-divider></v-divider>

      <v-list nav class="bottom-logout" :height="70 + (!isFree && !isFreeEmployee ? 90 : 0) + 'px'">
        <v-divider></v-divider>

        <v-list density="compact" ref="menu-items" nav>
          <v-list-item id="overview" prepend-icon="mdi-chart-bar" :title="$t('leftmenu.overview')" value="overview" active-color="primary" ref="myBtn" @click="changeView('overview')"></v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list density="compact" ref="menu-items" nav>
          <div v-if="!isFree && !isFreeEmployee">
            <v-list-item id="people" prepend-icon="mdi-account-group" :title="$t('employees')" value="people-manager" active-color="primary" @click="changeView('people')"></v-list-item>
            <v-list-item id="companies" prepend-icon="mdi-domain" :title="$t('leftmenu.clients')" value="companies-manager" active-color="primary" @click="changeView('companies')"></v-list-item>
          </div>
<!--          <v-list-item id="settings" prepend-icon="mdi-cog" :title="$t('leftmenu.settings')" value="settings" active-color="primary" ref="myBtn" @click="changeView('settings')"></v-list-item>-->
        </v-list>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation
        permanent
        v-if="mq.current === 'mobile'"
        class="bottom-phone-nav"
    >
      <v-btn @click="changeView('upload')" size="small" class="bottom-nav-button">
        <v-icon color="primary">mdi-upload</v-icon>
      </v-btn>
      <v-btn @click="changeView('download')" size="small" class="bottom-nav-button">
        <v-icon color="primary">mdi-download</v-icon>
      </v-btn>
<!--      <v-btn @click="changeView('documents')" size="small" class="bottom-nav-button">-->
<!--        <v-icon color="primary">mdi-text-box-multiple</v-icon>-->
<!--      </v-btn>-->
      <v-btn @click="changeView('requests')" size="small" class="bottom-nav-button">
        <v-badge v-if="getRequestsNumber > 0" :content="getRequestsNumber" class="badge-request-mobile" color="primary"></v-badge>
        <v-icon color="primary">mdi-file-find</v-icon>
      </v-btn>
<!--      <v-btn @click="changeView('invoices')" size="small" class="bottom-nav-button">-->
<!--        <v-icon color="primary">mdi-receipt-text-arrow-right</v-icon>-->
<!--      </v-btn>-->
      <v-spacer></v-spacer>
      <v-btn @click="changeView('overview')" size="small" class="bottom-nav-button">
        <v-icon color="primary">mdi-chart-bar</v-icon>
      </v-btn>
      <v-btn @click="changeView('people')" v-if="!isFree && !isFreeEmployee" size="small" class="bottom-nav-button">
        <v-icon color="primary">mdi-account-group</v-icon>
      </v-btn>
      <v-btn @click="changeView('companies')" v-if="!isFree && !isFreeEmployee" size="small" class="bottom-nav-button">
        <v-icon color="primary">mdi-domain</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-layout>
</template>

<script setup>

import {cart} from '@/services/cart';
import {useRoute} from "vue-router/dist/vue-router";
import {watch} from "vue";
import TooltipMessage from "@/components/mini/Tooltip";

const route = useRoute();

watch(() => route.name, () => {
  console.debug(`MyCoolComponent - watch route.name changed to ${route.name}`);
});
</script>

<script>
import UserService from "@/services/UserService";
import router from "@/router";
import CompanyService from "@/services/CompanyService";
import SubscriptionService from "@/services/SubscriptionService";
import Helper from "@/services/Helper";

export default {
  name: "LeftMenu",
  inject: ['mq'],
  data() {
    return {
      selectedNav: [0],
      selected: true,
      mini: true,
      user: null,
      admin: false,
      companySearch: ""
    }
  },
  mounted() {
    this.user = UserService.getLoggedUser();
    this.admin = this.user.admin;
    //Used when a page refresh is happening
    Helper.selectMenuItem(router.currentRoute.value.name);
    // UserService.fetchIsUserActive(this.user);
  },
  methods: {
    changeView(view) {
      Helper.selectMenuItem(view);
      router.push({ path: view });
    },
    beautifyPageName(pageName) {
      return pageName.substring(pageName.indexOf("-") + 1);
    },
    getIcon(pageName) {
      switch (pageName) {
        case 'download': return 'mdi-download';
        case 'upload': return 'mdi-upload';
        case 'screen-select': return 'mdi-swap-horizontal';
        case 'settings': return 'mdi-cog';
        case 'companies': return 'mdi-domain';
        case 'people': return 'mdi-account-group';
        case 'admin': return 'mdi-shield-crown';
        case 'requests': return 'mdi-file-find';
        case 'overview': return 'mdi-chart-bar';
        case 'invoices': return 'mdi-chart-bar';
      }
    },
    changeCompany(companyId) {
      this.companySearch = "";
      CompanyService.changeCompany(companyId);
    }
  },
  computed: {
    filterCompanies() {
      if (this.companySearch.length > 1) {
        let items = cart.value.companies.filter(company => company.name.toLowerCase().indexOf(this.companySearch.toLowerCase()) > -1);
        if (items.length > 0)
          return items;
      }
      return cart.value.companies;
    },
    getRequestsNumber() {
      if (!cart.value.requestsData) return 0;
      const todoRequests = cart.value.requestsData.filter(requestData => {
        let loggedUser = UserService.getLoggedUserOrEmployer();
        if (!loggedUser || !loggedUser.defaultCompanyId) return false;
        return cart.value.filterCompanies.find(company => company.id === requestData.fromCompanyId || company === requestData.fromCompanyId)
            && !requestData.resolved
            && requestData.createdById !== UserService.getLoggedUser().id
            && (loggedUser.defaultCompanyId === requestData.companyId || loggedUser.defaultCompanyId === requestData.fromCompanyId)
      });
      return todoRequests.length;
    },
    invoiceSub() {
      return cart.value.invoiceSub;
    },
    isFree() {
      return !UserService.isUserActive();
    },
    isFreeEmployee() {
      return SubscriptionService.isFreeEmployee();
    },
    isActive() {
      return UserService.isUserActive();
    }
  }
}
</script>

<style scoped>

.bottom-logout {
  position: absolute;
  bottom: 0;
  width: 200px;
  padding: 0;
}

.page-title-container i {
  margin-right: 10px;
  color: lightgray;
}

.bottom-nav-button {
  padding: 0;
  max-width: none !important;
  min-width: 12vw !important;
}

.bottom-phone-nav {
  width: 100vw !important;
}

.bottom-phone-nav > div {
  display: block !important;
}

.badge-request {
  position: absolute;
  right: 10px;
  margin-top: 5px;
  opacity: 0.7;
}

.badge-request-mobile {
  position: absolute;
  right: 8px;
  top: 8px;
  margin-top: 5px;
  opacity: 0.7;
}

</style>