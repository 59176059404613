import axios from 'axios';
import Helper from './Helper'
import ProductService from "@/services/ProductService";
import {cart} from "@/services/cart";

const INVOICE_API_BASE_URL = Helper.getHost() + '/api/invoice';

class InvoiceService{
    selectedInvoice = null;

    add(email, invoice) {
        return axios.post(INVOICE_API_BASE_URL, invoice, { headers: {'email': email}});
    }

    edit(invoice) {
        return axios.put(INVOICE_API_BASE_URL, invoice);
    }

    remove(id) {
        return axios.delete(INVOICE_API_BASE_URL, { headers: {'invoiceId': id}});
    }

    getById(id) {
        return axios.get(INVOICE_API_BASE_URL + "/id", {headers: {'invoiceId': id}});
    }

    getByCompany(companyId) {
        return axios.get(INVOICE_API_BASE_URL + "/company",{headers: {'companyIds': companyId}});
    }

    generateInvoiceFile(invoice, companyId) {
        if (!companyId) return;
        return axios.post(INVOICE_API_BASE_URL + "/file", invoice, { headers: {'companyId': companyId}});
    }

    // Utils


    loadAvailableProducts(companyId) {
        if (cart.value.availableProducts.length !== 0) return cart.value.availableProducts;
        ProductService.getByCompany(companyId).then(products => {
            cart.value.availableProducts = [];
            products.data.forEach(product => {
                if (product.um && product.quantity || cart.value.availableProducts.filter(p => p.id === product.id).length > 1) return;
                cart.value.availableProducts.push(product);
            })
        })
        cart.value.availableProducts = cart.value.availableProducts.filter((item,index) => cart.value.availableProducts.indexOf(item) === index);
    }

    setSelectedInvoice(invoice) {
        this.selectedInvoice = invoice;
    }

    getSelectedInvoice() {
        return this.selectedInvoice;
    }
}

export default new InvoiceService();