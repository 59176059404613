<template>
  <div class="screen-select content-page" style="height: -webkit-fill-available;">
    <TopBar/>
    <LeftMenu/>
    <InvoicesManagement/>
    <TutorialOverlay/>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import LeftMenu from "@/components/LeftMenu";
import TutorialOverlay from "@/components/TutorialOverlay";
import {defineAsyncComponent} from "vue";


const InvoicesManagement = defineAsyncComponent(() =>
    import('@/components/invoice/Invoices')
)

export default {
  name: "InvoicesView",
  components: {TutorialOverlay, LeftMenu, TopBar, InvoicesManagement}
}
</script>

<style scoped>

</style>