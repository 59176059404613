<template>
  <div :id="mq.current === 'mobile' ? 'admin-download-container-mobile' : 'admin-download-container'" class="content-page-main">
    <table-filters search-on companies-on month-on :resolved-on="mq.current !== 'mobile'" download-all-on class="tutorial-step-3"></table-filters>
    <DownloadTableComponent v-if="!cart.gridView"/>
    <folder-system v-if="cart.gridView"/>
  </div>
</template>

<script setup>

import TableFilters from "@/components/TableFilters";
import DownloadTableComponent from "@/components/fileComponents/DownloadTableComponent";
import {cart} from "@/services/cart";
import FolderSystem from "@/components/fileComponents/FolderSystem";

</script>

<script>
import UserService from "@/services/UserService";

export default {
  name: "AdminDownload",
  inject: ['mq'],
  data: () => ({
  }),
  mounted() {
    cart.value.showResolved = true;
    let user = UserService.getLoggedUser();
    cart.value.currentPage = 'admin-download';
  },
  methods: {
  }
}
</script>

<style scoped>
  #admin-download-container {
    margin: 50px 50px 0 250px;
  }

  #admin-download-container-mobile {
    padding: 10px;
    font-size: small;
  }

  #admin-download-container-mobile td {
    font-size: small;
    padding: 0 5px;
  }

  #admin-download-container-mobile .v-table {
    overflow: scroll !important;
  }

  .custom-table-row {
    font-size: small;
  }

  @media print {
    @page {
      margin: 0.3in 1in 0.3in 1in !important
    }
  }

  .v-table:deep(div) {
    overflow: visible !important;
  }
</style>