import axios from 'axios';
import Helper from './Helper'
import {cart} from "@/services/cart";
import FileService from "@/services/FileService";
import UserService from "@/services/UserService";

const SUBSCRIPTION_API_BASE_URL = Helper.getHost() + '/api/subscription';
const INVOICE_API_BASE_URL = Helper.getHost() + '/api/invoice';

class SubscriptionService{
    userItem = null;
    companyItem = null;
    subItem = null;
    freeSub = {
        maxCompanies: 0,
        maxFileSize: "0",
        maxUsers: 0,
        name: "Free Tier",
        price: 0,
        subscriptionType: "Free",
        totalFileSize: "0"
    }

    getAllDetails() {
        return axios.get(SUBSCRIPTION_API_BASE_URL + "/all");
    }

    getByUserId(userId) {
        return axios.get(SUBSCRIPTION_API_BASE_URL + "/user", { headers: {'userId': userId}});
    }

    getLinkByUserId(userId) {
        return axios.get(SUBSCRIPTION_API_BASE_URL + "/link/user", { headers: {'userId': userId}});
    }

    getToken(userId) {
        return axios.get(SUBSCRIPTION_API_BASE_URL + "/link/token", { headers: {'userId': userId}});
    }

    isActive(subLinkId) {
        return axios.get(SUBSCRIPTION_API_BASE_URL + "/active", { headers: {'subId': subLinkId}});
    }

    prepareRegisterData(data, isUpgrade) {
        return axios.post(SUBSCRIPTION_API_BASE_URL + "/register", data, { headers: {'isUpgrade': isUpgrade.toString()}});
    }

    saveRegisterData(uuid) {
        return axios.post(SUBSCRIPTION_API_BASE_URL + "/register/save", null, { headers: {'uuid': uuid}});
    }

    remove(userId, subscriptionId) {
        return axios.delete(SUBSCRIPTION_API_BASE_URL, { headers: {'subscriptionId': subscriptionId, 'userId': userId}});
    }

    removeByUserId(userId) {
        return axios.delete(SUBSCRIPTION_API_BASE_URL + "/user", { headers: {'userId': userId}});
    }

    upgradeSubFree(uuid) {
        return axios.post(SUBSCRIPTION_API_BASE_URL + "/upgrade/free", null, { headers: {'uuid': uuid}});
    }

    upgradeSub(subDetails) {
        let data = {
            'user': UserService.getLoggedUser(),
            'company': cart.value.mycompany,
            'subscriptionDetails': subDetails
        }
        return axios.post(SUBSCRIPTION_API_BASE_URL + "/upgrade", data);
    }

    setActiveSub(userId) {
        this.getByUserId(userId).then(sub => {
            if (sub.data === null || sub.data === '') {
                cart.value.selectedSubscription = this.freeSub;
                return;
            }
            cart.value.selectedSubscription = sub.data;
            cart.value.invoiceSub = false;
            this.getLinkByUserId(userId).then(subLink => {
                subLink.data.forEach(subL => {
                    let subDetails = cart.value.subscriptions.find(s => s.id === subL.subscriptionId);
                    if (subL.parentSubscriptionId && subDetails.subscriptionType === 'FreeEmployee') {
                        subDetails = cart.value.subscriptions.find(s => s.id === subL.parentSubscriptionId);
                    }
                    // if (subDetails.subscriptionType === 'Invoice') {
                    //     cart.value.invoiceSub = true;
                    //     return;
                    // }
                    cart.value.selectedSubscription = subDetails;
                    cart.value.selectedSubscription.expirationTime = FileService.getFormattedDate(subL.expirationTime);
                    cart.value.selectedSubscription.enabled = subL.enabled;
                    cart.value.selectedSubscription.subLinkId = subL.id;
                })
            });
        })
    }

    isFreeUser() {
        if (cart.value.selectedSubscription && cart.value.selectedSubscription.subscriptionType)
            return !UserService.getUserSubType() || cart.value.selectedSubscription.subscriptionType.toLowerCase() === 'free'
        return !UserService.getUserSubType() || UserService.getUserSubType().toLowerCase() === 'free'
    }

    isFreeEmployee() {
        // if (!cart.value.selectedSubscription || !cart.value.selectedSubscription.subscriptionType) return true;
        // const subscriptionType = cart.value.selectedSubscription.subscriptionType;
        // console.log(subscriptionType);
        // return subscriptionType && subscriptionType.toLowerCase() === 'freeemployee'
        return UserService.getUserSubType() && UserService.getUserSubType().toLowerCase() === 'freeemployee'
    }

    isExpired() {
        if (!cart.value.selectedSubscription || this.isFreeUser()) return false;

        let expirationTime = new Date(cart.value.selectedSubscription.expirationTime);
        const now = new Date();
        return now > expirationTime
    }

    verifyFileSize(size) {
        return size > this.sizeFromMb(cart.value.selectedSubscription.maxFileSize);
    }

    verifyTotalFileSize(size) {
        return size > this.sizeFromGb(cart.value.selectedSubscription.totalFileSize);
    }

    verifyNumberOfFiles() {
        return cart.value.selectedSubscription.subscriptionType === 'Free' &&
            FileService.getAllFiles().length > cart.value.selectedSubscription.totalFileSize;
    }

    validateUserNumber(userLength) {
        return userLength < cart.value.selectedSubscription.maxUsers;
    }

    validateCompaniesNumber(companiesLength) {
        return companiesLength < cart.value.selectedSubscription.maxCompanies;
    }

    sizeFromMb(size) {
        return size * 1000000;
    }

    sizeToMb(size) {
        return (size / 1000000).toFixed(2);
    }

    sizeFromGb(size) {
        return this.sizeFromMb(size) * 1000;
    }

    sizeToGb(size) {
        return (this.sizeToMb(size) / 1000).toFixed(2);
    }

    prepareData(userItem, mycompany, selectedSub, isUpgrade = false) {
        let data = {
            'user': userItem,
            'company': mycompany,
            'subscriptionDetails': selectedSub
        }
        return this.prepareRegisterData(data, isUpgrade);
    }

    clearData() {
        this.userItem = null;
        this.companyItem = null;
        this.subItem = null;
    }
}

export default new SubscriptionService();