<template>
  <div :class="['request-dragging-overlay', dragging ? 'request-dragging-overlay-drag' : '']" v-if="dragging"
       style="position: absolute; background-color: rgb(var(--v-theme-secondary));" @dragleave.self="dragExit"
       @dragover.prevent @dragenter.prevent @drop.prevent @drop="uploadFiles($event, requestDetails.id)"
  >
    <div class="dropZone-info" style="pointer-events: none;">
      <v-card
          class="mx-auto"
          :width="mq.current === 'mobile' ? '80%' : 500"
          color="transparent"
          style="box-shadow: none; color: white;"
          :loading="loading"
      >
        <template v-slot:title>
          <span style="color: #ECEFF1;">{{ $t('upload.file') }}</span>
        </template>

        <v-card-text>
          <span style="color: #ECEFF1;">{{ $t('upload.drop') }}</span>
          <br>
          <span style="color: #ECEFF1;">{{ $t('upload.request') }}</span>
          <br>
          <span style="color: #ECEFF1;">{{ $t('upload.request.info') }}</span>
          <br>
          <span style="color: #ECEFF1;">{{ $t('upload.request.info2') }}</span>
        </v-card-text>
      </v-card>
    </div>
  </div>
  <v-card @dragenter="dragEnter" :style="dragging ? 'z-index: -1' : 'z-index: 10'">
    <div class="details-container" style="display: contents;">
      <div class="request-header">
        <div style="display: flex;">
          <span style="font-size: 12px; font-weight: bold;">{{ requestDetails.companyName }}</span>
          <v-spacer></v-spacer>
          <span style="font-size: 12px; font-weight: bold; margin-right: 10px;">{{ requestDetails.createTimeFormatted ? requestDetails.createTimeFormatted.substring(0, 10) : ''}}</span>
        </div>
        <div style="display: flex;">
          <span style=" margin: 5px 10px 5px 10px;" :style="mq.current === 'mobile' ? 'font-size: large;' : ' font-size: x-large;'">{{ requestDetails.title}}</span>
          <v-spacer></v-spacer>
          <span style="font-weight: bold; font-size: 14px; padding-top: 14px; text-align: right;" :style="'color: ' + getPriorityColor(requestDetails.priority)">{{ $t('priority') + " " + $t('priorities.' + requestDetails.priority.toLowerCase()) }}</span>
        </div>
      </div>

      <div class="request-category" v-if="mq.current !== 'mobile'">
        <v-btn size="x-small" icon="mdi-list-status" color="amber-darken-4" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
        <span style="margin: 5px calc(50% - 23px);">Status</span>
<!--        <span class="details-name" style="margin: 10px 0 0 0;">Status</span>-->
        <v-stepper :model-value="getCurrentStep()" alt-labels hide-actions
                   :color="getCurrentStep() === 2 ? 'green' : ''"
                   style="overflow: unset; margin: 0 10px 10px 15px; box-shadow: none;">
          <v-stepper-header style="overflow: unset; box-shadow: none;">
            <v-stepper-item color="primary" :complete="stepComplete('New')" :title="$t('statuses.new')" value="1"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item color="primary" :complete="stepComplete('InProgress')" :title="$t('statuses.inprogress')" value="2"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item color="primary" :complete="stepComplete('Done')" :title="$t('statuses.done')" value="3"></v-stepper-item>
          </v-stepper-header>
        </v-stepper>
      </div>

      <div class="request-category">
<!--        <span class="details-name" style="margin: 10px 0 0 0;">{{ $t('description') }}</span>-->
        <v-btn size="x-small" icon="mdi-text" color="brown-lighten-2" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
        <span style="margin: 5px calc(50% - 35px);">{{ $t('description') }}</span>
        <span class="overflow-auto">{{ requestDetails.details }}</span>
      </div>

      <div class="request-files request-category" style="display: block;">
        <v-overlay
            :opacity="1"
            v-model="loading"
            class="align-center justify-center"
        >
          <v-progress-circular indeterminate size="64">
            Loading...
          </v-progress-circular>
        </v-overlay>
<!--        <span class="details-name" style="margin-bottom: 10px;">{{ $t('files') }}</span>-->
        <v-btn size="x-small" icon="mdi-file-multiple" color="indigo-lighten-2" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
        <span style="margin: 5px calc(50% - 24px);">{{ $t('files') }}</span>
        <label style="margin: 10px calc(50% - 46px) 20px; display: block;">
          <div class="request-file-upload">
            <v-icon>mdi-file-upload</v-icon>
            <span>Upload</span>
          </div>
          <input type="file" multiple id="file-input" @change="uploadFiles($event, requestDetails.id)" style="display:none;" />
        </label>
        <span style="font-size: small;">{{ $t('upload.drop') }}</span>
        <v-card :loading="loading" :class="files.length ? 'request-files-container' : ''">
          <v-chip v-for="file in files" :key="file.id" style=" margin: 3px 5px; width: fit-content;">
            {{ file.filename.length > 40 ? file.filename.substring(0, 40) + "..." + file.filename.substring(file.filename.length-5) : file.filename }}
            <v-btn size="xx-small" icon="mdi-download" @click="downloadFile(file)" style="margin-left: 7px; padding: 4px; font-size: x-small;">
              <v-icon>mdi-download</v-icon>
              <tooltip-message :message="$t('downloadfile')"/>
            </v-btn>
            <v-btn size="xx-small" icon="mdi-delete" @click="deleteDialog = true; deleteFileId = file;" style="margin-left: 7px; padding: 4px; font-size: x-small;">
              <v-icon>mdi-delete</v-icon>
              <tooltip-message :message="$t('deletefile')"/>
            </v-btn>
            <v-dialog
                v-model="deleteDialog"
                max-width="500px"
                class="icarus-dialog"
            >
              <v-card>
                <span size="big" class="dialog-title">{{ $t('file.delete.confirmation') }}</span>
                <v-card-actions>
                  <v-btn class="cancel-btn" variant="tonal" @click="deleteDialog = false">{{ $t('cancel') }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="success-btn" style="background: rgb(var(--v-theme-error))" variant="tonal" @click="removeFile(file)">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-chip>
        </v-card>
      </div>
      <div class="request-response request-category">
<!--        <span class="details-name" style="margin: 10px 0 0 0;">{{ $t('response') }}</span>-->
        <v-btn size="x-small" icon="mdi-forum" color="light-green-darken-1" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
        <span style="margin: 5px calc(50% - 32px);">{{ $t('response') }}</span>
        <v-textarea variant="outlined"
                    hide-details
                    v-model="cart.requestDetailsResponseMessage">
        </v-textarea>
      </div>
      <div class="request-details request-category">
<!--        <span class="details-name">{{ $t('details') }}</span>-->
        <v-btn size="x-small" icon="mdi-information" color="purple-lighten-3" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
        <span style="margin: 5px calc(50% - 23px);">{{ $t('details') }}</span>
        <div style="padding: 5px; border: 1px solid #989898; border-radius: 5px;">
          <v-row class="details-row">
            <span class="details-name">Status: </span>
            <span class="details-value">{{ getStatus(requestDetails.status) }}</span>
          </v-row>
          <v-row class="details-row">
            <span class="details-name">{{ $t('createdBy') + ": " }}</span>
            <span class="details-value">{{ requestDetails.createdBy }}</span>
          </v-row>
          <v-row class="details-row">
            <span class="details-name">{{ $t('assignedTo') + ": " }}</span>
            <span class="details-value" v-if="requestDetails.assignedTo">{{ requestDetails.assignedTo }}</span>
          </v-row>
          <v-row class="details-row">
            <span class="details-name">{{ $t('creationTime') + ": " }}</span>
            <span class="details-value">{{ requestDetails.createTimeFormatted }}</span>
          </v-row>
          <v-row class="details-row">
            <span class="details-name">{{ $t('modifiedTime') + ": " }}</span>
            <span class="details-value">{{ requestDetails.updateTimeFormatted }}</span>
          </v-row>
          <v-row class="details-row">
            <span class="details-name">{{ $t('resolved') + ": " }}</span>
            <span class="details-value">{{ requestDetails.resolved == true ? $t('yes') : $t('no') }}</span>
          </v-row>
          <v-row class="details-row" v-if="requestDetails.resolved">
            <span class="details-name">{{ $t('resolved.by') + ": " }}</span>
            <span class="details-value">{{ requestDetails.resolvedBy }}</span>
          </v-row>
          <v-row class="details-row" v-if="requestDetails.resolved">
            <span class="details-name">{{ $t('resolved.at') + ": " }}</span>
            <span class="details-value">{{ requestDetails.resolvedTimeFormatted }}</span>
          </v-row>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup>
import { defineProps } from 'vue';
import {cart} from "@/services/cart";
import TooltipMessage from "@/components/mini/Tooltip";

const props = defineProps(['requestDetails'])

</script>

<script>
import FileService from "@/services/FileService";
import i18n from "@/i18n";

export default {
  name: "RequestDetails",
  inject: ['mq'],
  data: () => ({
    dragging: false,
    loading: false,
    deleteDialog: false,
    deleteFileId: null,
    files: []
  }),
  mounted() {
    if (!this.$props.requestDetails.id) return;
    cart.value.startLoading(this);
    cart.value.requestDetailsResponseMessage = this.$props.requestDetails.responseMessage ? this.$props.requestDetails.responseMessage : '';
    FileService.getFilesByRequest(this.$props.requestDetails.id).then(fls => {
      this.files = fls;
      this.loading = false;
    });
  },
  methods: {
    stepComplete(status) {
      if (this.$props.requestDetails.status === 'New') {
        return false;
      }
      if (this.$props.requestDetails.status === 'Done') {
        return true;
      }
      if (this.$props.requestDetails.status === 'InProgress') {
        return status === 'New';
      }
    },
    getCurrentStep() {
      switch (this.$props.requestDetails.status) {
        case 'New': return 0;
        case 'Done': return 2;
        case 'InProgress': case 'Blocked': return 1;
        default: return 0;
      }
    },
    getPriorityColor(priority) {
      switch (priority){
        case "Low": return "#b5e884";
        case "Normal": return "";
        case "High": return "#ffc83e";
        case "Critical": return "#b62525";
      }
      return "";
    },
    uploadFiles(e, reqId) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      FileService.createFiles(files, reqId).then(() => {
        console.log("Done");
        FileService.getFilesByRequest(this.$props.requestDetails.id).then(fls => {
          this.files = fls;
          this.loading = false;
        });

      });
      cart.value.showAlertDetails(i18n.global.t('fileorfiles') + i18n.global.t('was') + i18n.global.t('uploaded') + i18n.global.t('withsuccess'))
      this.dragging = false;
    },
    removeFile(file) {
      this.deleteDialog = false;
      FileService.deleteFile(this.deleteFileId.id).then(() => {
        const removedFile = this.files.find(x => x.id === this.deleteFileId.id);
        if (removedFile) {
          this.files.splice(removedFile, 1);
        }
        this.loading = false;
        cart.value.showAlertDetails(i18n.global.t('fileorfiles') + i18n.global.t('was') + i18n.global.t('deleted') + i18n.global.t('withsuccess'))
      }).catch(err => {
        cart.value.showAlertDetails(i18n.global.t('loading.failed'));
      });
    },
    downloadFile(fileData) {
      FileService.getAndDownloadFile(fileData);
    },
    dragExit() {
      this.dragging = false;
    },
    dragEnter() {
      this.dragging = true;
    },
    getStatus(status) {
      switch (status) {
        case "InProgress": return "In Progress";
      }
      return status;
    },
  }
}
</script>

<style scoped>

.request-header {
  display: grid;
  padding: 10px 10px 0 10px;
  background: rgb(var(--v-theme-primary));
  color: white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2400;
}

.request-category {
  display: grid;
  /*margin: 10px;*/
  border: 1px solid rgb(var(--v-theme-leftMenu));
  padding: 10px;
  border-radius: 5px;
  /*box-shadow: 1px 2px 3px 3px #e6e6e6;*/
  margin: 10px 5px;
  /*background: rgb(var(--v-theme-leftMenu));*/
}

.details-name {
  font-weight: bold;
  margin-right: 5px;
}
.details-row {
  margin: 4px 5px;
}

.request-files-container {
  display: grid;
  padding: 5px 0;
  border: 1px solid #989898;
  border-radius: 5px;
  overflow: hidden;
}

.request-dragging-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity .5s ease;
  opacity: 0;
}

.request-dragging-overlay-drag {
  opacity: 1 !important;
  z-index: 1000;
  background: rgb(var(--v-theme-secondary));
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.request-file-upload {
  width: 96px;
  height: 36px;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 5px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.request-file-upload:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}
</style>