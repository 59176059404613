<template>
  <div :class="mq.current === 'mobile' ? 'filter-container-mobile' : 'filter-container'">

    <v-menu v-if="uploadDocumentsBtnOn">
      <template v-slot:activator="{ props }">
        <v-btn color="primary" elevation="1" v-bind="props" style="margin: 14px 10px 10px 0;">
          <v-icon class="clickable-link-icon">mdi-upload</v-icon>
          <span>{{ $t('leftmenu.upload') }}</span>
          <tooltip-message :message="$t('documents.upload.message')"/>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="(item, index) in uploadTypes"
            :key="index"
            :value="index"
            @click="item.onclick()"
        >
          <v-list-item-title>
            <v-icon style="opacity: 0.4; margin-right: 5px;">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-col :cols="mq.current === 'mobile' ? auto : 3"  v-if="searchOn" :style="mq.current === 'mobile' ? 'margin: 10px 0;' : ''">
      <v-text-field
          v-model="cart.search"
          :prepend-icon="mq.current === 'mobile' ? '' : 'mdi-magnify'"
          :label="$t('filters.search')"
          single-line
          clearable="true"
          density="compact"
          variant="solo-filled"
          hide-details
      >
        <tooltip-message :message="$t('searchinfo')"/>
      </v-text-field>
    </v-col>
    <v-col :cols="mq.current === 'mobile' ? auto : 3" v-if="companiesOn && companies.length > 1 && mq.current !== 'mobile'">
      <tooltip-message :message="$t('companyinfo')"/>
      <v-select
          :items="companies"
          :prepend-icon="mq.current === 'mobile' ? '' : 'mdi-office-building'"
          :label="$t('companies')"
          v-model="cart.filterCompanies"
          item-title="name"
          item-value="id"
          density="compact"
          variant="solo-filled"
          @update:modelValue="selectCompany"
          multiple
          hide-details
      >
        <template v-slot:selection="{item, index}">
          <v-chip v-if="selectedAll && index === 0" size="small">
            {{ $t('filters.all') }}
          </v-chip>
          <v-chip v-if="!selectedAll && index < 1 && item.title" size="small">
            {{ item.title }}
          </v-chip>
          <span v-if="!selectedAll && index === 1" style="font-size: small;" class="text-grey text-caption-custom align-self-center">
            (+{{ cart.filterCompanies.length-1 }} more)
          </span>
        </template>
        <template v-slot:prepend-item>
          <v-list-item
              :title="$t('filters.selectall')"
              @click="toggle"
          >
            <template v-slot:prepend >
              <v-checkbox-btn
                  :color="selectedSome ? 'indigo-darken-4' : undefined"
                  :indeterminate="selectedSome && !selectedAll"
                  :model-value="selectedSome"
              ></v-checkbox-btn>
            </template>
          </v-list-item>

          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </v-col>
    <v-col :cols="mq.current === 'mobile' ? auto : 2" v-if="statusOn && mq.current !== 'mobile'">
      <tooltip-message :message="$t('statusinfo')"/>
      <v-select
          :items="statuses"
          label="Status"
          v-model="cart.filterStatus"
          item-title="name"
          item-value="value"
          density="compact"
          variant="solo-filled"
          multiple
          hide-details
      >
        <template v-slot:selection="{item, index}">
          <v-chip v-if="selectedAllStatuses && index === 0" size="small">
            {{ $t('filters.all') }}
          </v-chip>
          <v-chip v-if="!selectedAllStatuses && index < 1" size="small">
            {{ item.title }}
          </v-chip>
          <span v-if="!selectedAllStatuses && index === 1" style="font-size: small;" class="text-grey text-caption-custom align-self-center">
            (+{{ cart.filterStatus.length-1 }} more)
          </span>
        </template>
        <template v-slot:prepend-item>
          <v-list-item
              :title="$t('filters.selectall')"
              @click="toggleStatus"
          >
            <template v-slot:prepend >
              <v-checkbox-btn
                  :color="selectedSomeStatuses ? 'indigo-darken-4' : undefined"
                  :indeterminate="selectedSomeStatuses && !selectedAllStatuses"
                  :model-value="selectedSomeStatuses"
              ></v-checkbox-btn>
            </template>
          </v-list-item>

          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </v-col>
    <v-col :cols="mq.current === 'mobile' ? auto : 2" v-if="priorityOn && mq.current !== 'mobile'">
      <tooltip-message :message="$t('priorityinfo')"/>
      <v-select
          :items="priorities"
          label="Priority"
          v-model="cart.filterPriority"
          item-title="name"
          item-value="value"
          density="compact"
          variant="solo-filled"
          multiple
          hide-details
      >
        <template v-slot:selection="{item, index}">
          <v-chip v-if="selectedAllPriorities && index === 0" size="small">
            {{ $t('filters.all') }}
          </v-chip>
          <v-chip v-if="!selectedAllPriorities && index < 1" size="small">
            {{ item.title }}
          </v-chip>
          <span v-if="!selectedAllPriorities && index === 1" style="font-size: small;" class="text-grey text-caption-custom align-self-center">
            (+{{ cart.filterPriority.length-1 }} more)
          </span>
        </template>
        <template v-slot:prepend-item>
          <v-list-item
              :title="$t('filters.selectall')"
              @click="togglePriority"
          >
            <template v-slot:prepend >
              <v-checkbox-btn
                  :color="selectedSomePriorities ? 'indigo-darken-4' : undefined"
                  :indeterminate="selectedSomePriorities && !selectedAllPriorities"
                  :model-value="selectedSomePriorities"
              ></v-checkbox-btn>
            </template>
          </v-list-item>

          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </v-col>
    <div :class="mq.current === 'mobile' ? 'row-container-mobile' : 'row-container'">
      <v-col :cols="mq.current === 'mobile' ? auto : 2" v-if="monthOn" @click="toggleMonthModal" style="min-width: 150px">
        <tooltip-message :message="$t('monthinfo')"/>
        <v-text-field
            v-model="cart.filterDate"
            :prepend-icon="mq.current === 'mobile' ? '' : 'mdi-calendar'"
            variant="solo-filled"
            :label="$t('filters.uploadmonth')"
            readonly
            hide-details
            density="compact">
        </v-text-field>
      </v-col>
      <v-col :cols="mq.current === 'mobile' ? auto : 1" v-if="resolvedOn" style="min-width: 110px;">
        <v-checkbox
            v-model="cart.showResolved"
            density="compact"
        >
          <template v-slot:label>
            <span style="font-size: small;">{{$t('filters.resolved')}}</span>
            <tooltip-message :message="$t('showresolved')"/>
          </template>
        </v-checkbox>
      </v-col>
      <v-spacer class="rectangle-select"></v-spacer>
      <v-col :cols="mq.current === 'mobile' ? auto : 1" v-if="downloadAllOn" style="max-width: 64px;">
        <v-btn color="primary" size="small" icon="mdi-folder-download" @click="downloadFiles">
          <v-icon>mdi-folder-download</v-icon>
          <tooltip-message :message="$t('downloadall.tooltip')"/>
        </v-btn>
      </v-col>
      <v-col :cols="mq.current === 'mobile' ? auto : 1" v-if="gridListOn" style="min-width: 110px; display: flex; margin-top: 5px;" :style="mq.current === 'mobile' ? 'padding: 13px 0;' : ''">
        <v-btn id="view-list" class="active-view" color="primary"  style="border-radius: 5px 0 0 5px; opacity: 0.7;" @click="changeGridListView('list')">
          <v-icon>mdi-format-list-bulleted-square</v-icon>
          <tooltip-message :message="$t('listview.tooltip')"/>
        </v-btn>
        <v-btn id="view-grid" color="primary"  style="border-radius: 0 5px 5px 0; opacity: 0.7;" @click="changeGridListView('grid')">
          <v-icon>mdi-grid</v-icon>
          <tooltip-message :message="$t('gridview.tooltip')"/>
        </v-btn>
      </v-col>
    </div>
    <v-col :cols="mq.current === 'mobile' ? auto : 2" v-if="myCompanyBtnOn" class="myFilesBtn" style="display: none;">
      <v-btn @click="goToMyFiles" class="clickable-link" elevation="1">
        <v-icon class="clickable-link-icon">mdi-home</v-icon>
        <span>{{$t('folder.home.text')}}</span>
        <tooltip-message :message="$t('folder.home')"/>
      </v-btn>
    </v-col>
    <v-dialog
        v-model="selectMonthModal"
        location="right"
        transition="dialog-top-transition"
        class="month-modal-parent"
    >
      <v-card width="fit-content"
              class="month-modal">
        <v-checkbox
            v-model="cart.showAllMonths"
            density="compact"
            hide-details
            @click="toggleAllMonths"

        >
          <template v-slot:label>
            <span style="font-size: small;">{{$t('filters.selectall')}}</span>
            <tooltip-message :message="$t('showallmonths')"/>
          </template>
        </v-checkbox>
        <month-picker @change="filterData" style="margin: 20px;" no-default clearable :variant="cart.isDark ? 'dark' : 'default'"></month-picker>

<!--        <v-date-picker-->
<!--            color='primary'-->
<!--            scrollable-->
<!--            type='month'-->
<!--            view-mode='months'-->
<!--            @update:month="filterData"-->
<!--            @change="filterData"-->
<!--        ></v-date-picker>-->
      </v-card>
    </v-dialog>

  </div>
</template>

<script setup>
  import {cart} from "@/services/cart";
  import { MonthPicker } from 'vue-month-picker';
  import TooltipMessage from "@/components/mini/Tooltip";
</script>

<script>

import i18n from "@/i18n";
import FileService from "@/services/FileService";
import router from "@/router";
import FolderService from "@/services/FolderService";

export default {
  name: "TableFilters",
  inject: ['mq'],
  data: () => ({
    selectMonthModal: false,
    prevMonth: null,
    uploadTypes: [],
    // statuses: ["New", "InProgress", "Blocked", "Done"],
    // priorities: ["Low", "Normal", "High", "Critical"],
  }),
  props: {
    uploadDocumentsBtnOn: Boolean,
    searchOn: Boolean,
    monthOn: Boolean,
    resolvedOn: Boolean,
    statusOn: Boolean,
    priorityOn: Boolean,
    gridListOn: Boolean,
    companiesOn: Boolean,
    myCompanyBtnOn: Boolean,
    downloadAllOn: Boolean
  },
  mounted() {
    if (cart.value.filterCompanies == null || cart.value.filterCompanies.length === 0)
      cart.value.filterCompanies = cart.value.companies;
    this.prevMonth = cart.value.filterDate;

    if (this.gridListOn)
      cart.value.gridView ? this.changeGridListView('grid') : this.changeGridListView('list');

    if (this.uploadDocumentsBtnOn) {
      this.uploadTypes = [
        { title: i18n.global.t('uploadfile'), icon: 'mdi-file-upload', onclick: () => document.getElementById("upload-files-input").click() },
        { title: i18n.global.t('uploadfolder'), icon: 'mdi-folder-upload', onclick: () => document.getElementById("upload-directory-input").click() },
      ]
    }
  },
  computed: {
    companies() {
      return cart.value.companies.filter(company => company.name);
    },
    priorities() {
      return [
        {value:"Low", name: i18n.global.t('priorities.low')},
        {value:"Normal", name: i18n.global.t('priorities.normal')},
        {value:"High", name: i18n.global.t('priorities.high')},
        {value:"Critical", name: i18n.global.t('priorities.critical')}];
    },
    statuses() {
      return [
        {value:"New", name: i18n.global.t('statuses.new')},
        {value:"InProgress", name: i18n.global.t('statuses.inprogress')},
        {value:"Blocked", name: i18n.global.t('statuses.blocked')},
        {value:"Done", name: i18n.global.t('statuses.done')}];
    },
    selectedAll () {
      return cart.value.filterCompanies.filter(company => company.name).length === this.companies.filter(company => company.name).length
    },
    selectedSome () {
      return cart.value.filterCompanies.filter(company => company.name).length >= 1
    },
    selectedAllStatuses () {
      return cart.value.filterStatus.length === this.statuses.length
    },
    selectedSomeStatuses () {
      return cart.value.filterStatus.length >= 1
    },
    selectedAllPriorities () {
      return cart.value.filterPriority.length === this.priorities.length
    },
    selectedSomePriorities () {
      return cart.value.filterPriority.length >= 1
    },
  },
  methods: {
    filterData (date) {
      let month = date.monthIndex;
      if (date.monthIndex < 10) month = "0" + month;
      cart.value.filterDate = date.year + "-" + month;
      this.prevMonth = cart.value.filterDate;
      cart.value.showAllMonths = false;
      // this.selectMonthModal = !this.selectMonthModal;
    },
    toggleMonthModal() {
      this.selectMonthModal = !this.selectMonthModal;
    },
    toggle () {
      if (this.selectedAll) {
        cart.value.filterCompanies = []
      } else {
        cart.value.filterCompanies = this.companies.slice()
      }
    },
    toggleStatus () {
      if (this.selectedAllStatuses) {
        cart.value.filterStatus = []
      } else {
        cart.value.filterStatus = this.statuses.slice()
      }
    },
    togglePriority () {
      if (this.selectedAllPriorities) {
        cart.value.filterPriority = []
      } else {
        cart.value.filterPriority = this.priorities.slice()
      }
    },
    toggleAllMonths() {
      if (!cart.value.showAllMonths) {
        cart.value.filterDate = i18n.global.t('filters.all');
      } else {
        cart.value.filterDate = this.prevMonth;
      }
      this.selectMonthModal = !this.selectMonthModal;
    },
    changeGridListView(view) {
      document.getElementsByClassName('active-view').item(0).classList.remove('active-view')
      document.getElementById('view-' + view).classList.add('active-view');
      cart.value.gridView = view === 'grid';
    },
    goToMyFiles() {
      FolderService.loadCompanies();
      FolderService.resetBreadcrumbs(1);
    },
    downloadFiles() {
      console.log("Downloading all files from this view.");
      cart.value.appLoader = true;
      const filesPromise = FileService.downloadAllFiles(router.currentRoute.value.name);
      if (filesPromise === null) {
        cart.value.appLoader = false;
        return;
      }
      filesPromise.then(bytes => {
        cart.value.appLoader = false;
        if (!bytes || !bytes.data) return;
        const fileData = {
          filename: 'icarus_downloaded_files.zip',
          fileBytes: bytes.data
        }
        FileService.downloadFile(fileData);
      });
    },
    selectCompany(companyId) {
      localStorage.setItem("selectedCompanyId", companyId);
    }
  }
}
</script>

<style scoped>

.month-modal-parent:deep(.v-overlay__content) {
  top: 60px;
}

.month-modal {
  position: absolute;
  right: 0;
  display: flex;
}

.filter-container {
  display: flex;
}

.filter-container.v-theme--mainDarkTheme {
  color: white;
}

.filter-container-mobile {
  margin-bottom: 20px;
}

.row-container {
  display: contents;
}

.row-container-mobile {
  display: flex;
  margin: -20px 0;
}

.active-view {
  opacity: 1 !important;
}

.clickable-link-icon {
  margin-right: 5px;
  margin-bottom: 2px;
}

.clickable-link {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
  width: max-content;
  height: 40px;
  /*background: rgb(var(--v-theme-primary));*/
  /*color: white;*/
}

.clickable-link:hover {
  opacity: 0.9;
}

.myFilesBtn {
  padding-left: 0;
  padding-right: 0;
  max-width: 140px;
}

</style>