export default {
  "topbar.appname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icarus"])},
  "leftmenu.documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documente"])},
  "leftmenu.people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaboratori"])},
  "leftmenu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratori"])},
  "leftmenu.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "leftmenu.people.performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performanțele angajaților"])},
  "leftmenu.people.performance.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereri completate"])},
  "leftmenu.people.performance.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișiere încărcate"])},
  "leftmenu.people.performance.receivedfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișiere primite"])},
  "leftmenu.people.performance.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme asociate"])},
  "leftmenu.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme"])},
  "leftmenu.clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienți"])},
  "leftmenu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setări"])},
  "leftmenu.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina mea"])},
  "leftmenu.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarcă"])},
  "leftmenu.client.downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcat de "])},
  "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcat"])},
  "premium.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator premium"])},
  "free.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator gratuit"])},
  "upgrade.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade disponibil"])},
  "client.downloaded.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descărcat de o persoană atribuită acestei firme."])},
  "wasnot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nu a fost"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Către"])},
  "leftmenu.downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De descărcat"])},
  "leftmenu.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încarcă"])},
  "leftmenu.uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încarcate"])},
  "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcare fișiere"])},
  "leftmenu.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereri"])},
  "overview.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereri nerezolvate"])},
  "leftmenu.invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturi"])},
  "leftmenu.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați o companie după nume sau CUI"])},
  "leftmenu.requests.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați-vă cererile"])},
  "leftmenu.invoice.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați-vă facturile"])},
  "preview.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Există o eroare la afișarea conținutului acestui fișier. Pentru a accesa conținutul acestuia vă rugăm să îl descărcați."])},
  "preview.error.size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiunea fișierului este prea mare pentru vizualizare."])},
  "overview.companies.tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA-ul firmelor"])},
  "overview.companies.notva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără TVA"])},
  "overview.companies.withtva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cu TVA"])},
  "filters.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caută"])},
  "filters.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate"])},
  "filters.selectall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectează tot"])},
  "filters.uploadmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lună Încărcare"])},
  "filters.resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișează rezolvate"])},
  "requests.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apăsați pentru a vedea cererile"])},
  "requests.toresolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cereri de rezolvat"])},
  "requests.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereri completate"])},
  "requests.todo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereri curente"])},
  "files.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișiere în aplicație"])},
  "clients.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clienți"])},
  "files.mb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiunea maximă a fișierelor"])},
  "downloads.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apăsați pentru a vedea fișierele gata pentru descărcare"])},
  "downloads.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" fișiere de descărcat"])},
  "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun fișier disponibil"])},
  "nodata.people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio persoană disponibil"])},
  "nodata.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio firmă disponibil"])},
  "servererror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A apărut o problemă la comunicarea cu serverul."])},
  "downloadpdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcă PDF"])},
  "downloadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descărcați fișierul"])},
  "resolvefile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcați fișierul ca și rezolvat. Acest lucru îl va ascunde din tabel."])},
  "resolverequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcați cererea ca și rezolvată. Acest lucru o va ascunde din tabel."])},
  "showallmonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați date din toate lunile"])},
  "showresolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați elementele marcate ca și Rezolvat"])},
  "searchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați după orice informație ce se regăsește în tabel"])},
  "statusinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrați după status. Puteți filtra datele după mai multe statusuri în același timp."])},
  "priorityinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrați după prioritate. Puteți filtra datele după mai multe priorități în același timp."])},
  "companyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrați după firmă. Puteți filtra datele după mai multe firme în același timp."])},
  "monthinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrați după luna de încărcare. Acest filtru va arăta doar fișierele încărcate în luna selectată."])},
  "themeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbați între modul luminos și întunecat"])},
  "norequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio cerere disponibilă"])},
  "noinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicio factură disponibilă"])},
  "noproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun produs disponibil"])},
  "noproduct.selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun produs selectat"])},
  "products.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produsele disponibile  "])},
  "support.mail.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerere suport Icarus"])},
  "support.mail.body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completați mai jos informații despre problema pe care o întâmpinați"])},
  "support.mail.bodySubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doresc să mă înscriu următorului abonament "])},
  "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrici"])},
  "nocompany.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu puteți încărca fișiere până nu sunteți asociat unei firme."])},
  "nocompany.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu puteți adăuga cereri până nu sunteți asociat unei firme."])},
  "nocompany.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți adăuga o firmă din ecranul \"Clienți\"."])},
  "subdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaliile abonamentului"])},
  "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitate"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerere"])},
  "request2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cererea"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factură"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produsul"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înainte"])},
  "finnish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizați"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Înapoi"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Închide"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omite"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereți asistență"])},
  "tutorial.restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reîncepeți tutorialul"])},
  "tutorial.invoices.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta este ecranul unde puteți efectua gestiunea facturilor. De aici puteți genera, modifica și șterge facturi pentru firmele dumneavoastră. Pentru a putea adăuga produse într-o factuă este necesară crearea acestora în secțiunea de gestiune a produselor."])},
  "tutorial.invoices.buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a crea o factură sau pentru a gestiona produsele unei firme puteți folosi unul dintre aceste butoane. Cel din dreapta vă permite gestiunea produselor companiei selectate. După introducerea unor produse, puteți crea o factură și să adăugați produsele create folosind butonul din stânga."])},
  "tutorial.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să renunțați la tutorial? Veți putea oricând să îl refaceți din pagina de setări."])},
  "tutorial.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bine ați venit la Icarus Docs! Pentru a vă ajuta să vă familiarizați cu aplicația am pregătit acest tutorial. În partea de sus a aplicației găsiți butoanele pentru asistență, setări și detaliile utilizatorului."])},
  "tutorial.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina principală Icarus Docs este centrul de control pentru gestionarea activității. Aici găsiți informații despre activitățile necesare, datele prezente în aplicație și detaliile abonamentului."])},
  "tutorial.download.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta este pagina pentru descărcarea fișierelor. Puteți utiliza aceste filtre pentru a obține fișierele pe care le căutați cu ușurință."])},
  "tutorial.download.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta este tabelul cu fișierele disponibile pentru descărcare. Aici primiți fișiere de la clienții respectiv colaboratorii dumneavoastră. Acestea pot fi vizualizate, descărcate sau transformate în format PDF. În acest tabel nu vor apărea fișierele încărcate de dumneavoastră sau angajații dumneavoastră."])},
  "tutorial.upload.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta este pagina pentru încărcarea fișierelor. Aici se pot încărca fișiere pentru o firmă specifică. Toți clienții atribuiți acestei firme vor putea viziona fișierele în pagina lor de Descărcare."])},
  "tutorial.requests.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta este pagina de gestiune a cererilor. Aici puteți crea o cerere pentru un fișier sau o informație de la clienții sau colaboratorii dumneavostră. Pentru a adăuga informații, fișiere sau pentru a rezolva o cerere, faceți click pe una dintre acestea."])},
  "tutorial.requests.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta este panoul cu detalii despre cererile dumneavoastră. Faceți click pe una dintre cereri pentru a ajunge la acest panou. În acest ecran găsiți mai multe detalii despre cerere, puteți adăuga fișiere și informații relevante. Fișierele încărcate aici pot fi vizionate mai apoi și în ecranul de Încărcări."])},
  "tutorial.people.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta este pagina de gestiune a persoanelor. Aici puteți adăuga, modifica și șterge utilizatori. De asemenea aici se poate face legătura între persoane și firme, urmând pașii din editarea unui utilizator. Puteți viziona și șterge legăturile persoanelor apăsând pe aceștia."])},
  "tutorial.companies.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta este pagina de gestiune a firmelor. Aici puteți adăuga, modifica și șterge firme. Aceste firme vor fi disponibile mai apoi pentru încărcare de fișiere și pentru asocierea cu colaboratorii dumneavoastră. Puteți selecta firma în care doriți să încărcați fișiere și cereri în colțul din stânga jos."])},
  "tutorial.companies.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folosind acest element puteți selecta firma cu care doriți să comunicați. În cadrul acesteia puteți încărca fișiere respectiv crea cereri. Această selecție va fi disponibilă când aveți mai multe firme asociate."])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["și"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lună"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume"])},
  "companies.assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme asociate"])},
  "companies.unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme disponibile"])},
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenume"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plătiți"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbă"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temă de culoare"])},
  "register.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cont"])},
  "register.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmă"])},
  "register.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plată"])},
  "register.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizare"])},
  "register.mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La finalul procesului de înregistrare veți primi un email pentru a vă seta parola."])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă"])},
  "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifică"])},
  "email.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de email există deja în aplicație. Vă rugăm schimbați parola acesteia sau contactați-ne."])},
  "email.wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa de email nu există în aplicație."])},
  "email.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fost trimis un email de confirmare la adresa de email introdusă."])},
  "successemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-ul a fost trimis cu succes."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "passwordnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolă nouă"])},
  "password.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmați noua parolă"])},
  "passwordchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetați parola"])},
  "passwordnewtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setați o parolă nouă"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume Firmă"])},
  "companyCUI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUI Firmă"])},
  "companyRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Număr Registru"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Județ"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localitate"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresă"])},
  "uploaddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată încărcare"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată"])},
  "mycompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma mea"])},
  "uploadby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcat de"])},
  "creationTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată încărcare"])},
  "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată modificare"])},
  "lastmodified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultima modificare"])},
  "previewfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afișați și printați fișierul"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizualizați"])},
  "deletefile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți acest fișier"])},
  "addrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați o nouă cerere"])},
  "addinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați o nouă factură"])},
  "manageproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionați produsele pentru firma selectată"])},
  "deleterequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți această cerere"])},
  "deleteinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți această factură"])},
  "editrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați această cerere"])},
  "editinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați această factură"])},
  "addcompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați o nouă firmă"])},
  "tvacompanyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest checkbox indică dacă firma este sau nu plătitoare de TVA"])},
  "deletecompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți această firmă"])},
  "editcompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați această firmă"])},
  "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creați un nou utilizator"])},
  "deleteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți acest utilizator"])},
  "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați acest utilizator"])},
  "edit.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificați firmele asociate acestui utilizator"])},
  "removecompanyassociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ștergeți asocierea dintre utilizator si această firmă. Acest lucru nu va șterge compania."])},
  "clickuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți click pe un utilizator ca să afișați firmele asociate acestuia."])},
  "clickrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faceți click pe o cerere ca să îi afișați detaliile si fișierele asociate acestuia."])},
  "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvat"])},
  "resolved2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvată"])},
  "resolved.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvat de"])},
  "resolved.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată rezolvare"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmă"])},
  "assignme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuie-mă acestei cereri"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rol"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primit"])},
  "directories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directoare"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișiere"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișierul"])},
  "nofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niciun fișier selectat."])},
  "directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director"])},
  "directory.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutați un director după numele acestuia"])},
  "directory.nodirectory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există niciun director."])},
  "directory.nofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există niciun fișier."])},
  "directory.addfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a adăuga un fișier sau director apăsați click dreapta și selectați opțiunea dorită. De asemenea puteți trage în această fereastră direct fișierele sau directorul dorit."])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pentru "])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bine ați venit, "])},
  "fileorfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișierul/Fișierele"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angajat"])},
  "accountant2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contabil"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "admin2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratorul"])},
  "Accountant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angajat"])},
  "employee2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angajatul"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angajați"])},
  "employees.clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angajați și clienți"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aici"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formular"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaugă"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu"])},
  "was": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a fost"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" încărcat"])},
  "companyassoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asocierea firmei"])},
  "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" început"])},
  "started2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" începută"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" creată"])},
  "created2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" creat"])},
  "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" editată"])},
  "edited2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" editat"])},
  "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ștearsă"])},
  "deleted2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" șters"])},
  "withsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" cu succes"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editează"])},
  "myCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvează"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["până la"])},
  "daysleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zile rămase"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renunță"])},
  "anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oriunde"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titlu"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["din"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalii"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriere"])},
  "priorities.low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mică"])},
  "priorities.normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normală"])},
  "priorities.high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mare"])},
  "priorities.critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critică"])},
  "statuses.inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["În progres"])},
  "statuses.underdev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această funcționalitate este în curs de dezvoltare."])},
  "statuses.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nou"])},
  "statuses.blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocat"])},
  "statuses.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezolvat"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioritate"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Răspuns"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat de"])},
  "paymentDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termen de plată"])},
  "dateDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dată scadentei"])},
  "atPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" la încasare"])},
  "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mențiuni"])},
  "assignedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atribuit lui"])},
  "user.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalii utilizator"])},
  "user.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă este ceva în neregulă cu detaliile dumneavoastră vă rugăm sa apăsați buton Cereți Asistență."])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamente"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonament"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schimbați abonamentul"])},
  "subscription.upgrade.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul a fost schimbat cu success."])},
  "subscription.upgrade.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plata se va face automat din cardul înregistrat."])},
  "subscription.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă aveți un cod de înregistrare, introduceți-l aici:"])},
  "subscription.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulați abonamentul"])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reînnoiți abonamentul"])},
  "subscription.beta.wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codul pentru testarea beta este invalid."])},
  "subscription.cancel.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O să ne fie dor de tine"])},
  "subscription.cancel.confirm.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul va mai funcționa până la finalul perioadei achitate."])},
  "subscription.cancel.confirm.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să anulați abonamentul la Icarus?"])},
  "subscription.fail.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzacție eșuată"])},
  "subscription.fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul nu a putut fi înregistrat"])},
  "subscription.fail.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dacă aceasta a fost din greșeală, vă rugăm reluați procesul de înregistrare sau "])},
  "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contactați-ne"])},
  "setting.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primiți notificări pe email"])},
  "subscription.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonamentul a fost înregistrat cu succes!"])},
  "subscription.success.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echipa Icarus va mulțumește pentru încrederea acordată și speră să fiți mai productivi cu soluția noastră"])},
  "subscription.success.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a efectua logarea în aplicație, vă rugăm să vă confirmați contul pe adresa de email."])},
  "subscription.onlydownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți descărca fișiere"])},
  "subscription.allowall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți descărca, încărca fișiere și crea cereri"])},
  "subscription.totalFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiu total pe cloud"])},
  "subscription.maxFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiune maximă a fișierelor "])},
  "subscription.maxUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" utilizatori"])},
  "subscription.filelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Maximum 100 documente"])},
  "subscription.maxCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" firme"])},
  "subscription.nolimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fără limită de "])},
  "subscription.name.Tier4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporație"])},
  "subscription.name.Tier3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companie Mare"])},
  "subscription.name.Tier2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companie Medie"])},
  "subscription.name.Tier1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companie Mică"])},
  "subscription.name.FreeEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel Gratuit"])},
  "subscription.name.Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel Gratuit"])},
  "subscription.space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" folosiți"])},
  "subscription.notavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a accesa această funcționalitate, abonați-vă."])},
  "subscription.error.largefile1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a încărca fișiere mai mari de "])},
  "subscription.error.largefile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" megabytes, abonați-vă la un plan mai mare."])},
  "subscription.error.totalfile1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ați ajuns la limita de depozitare în cloud de "])},
  "subscription.error.totalfile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" megabytes, abonați-vă la un plan mai mare pentru a o crește."])},
  "subscription.error.people1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a adăuga mai mult de "])},
  "subscription.error.people2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" angajați, abonați-vă la un plan mai mare."])},
  "subscription.error.people3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți introduce doar clienți deoarece ați ajuns la limita de "])},
  "subscription.error.companies2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" firme, abonați-vă la un plan mai mare."])},
  "data.upload.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipurile de fișiere suportate de aplicație sunt CSV și XLSX (Excel). Pentru a realiza cu succes încărcarea datelor din fișier, asigurați-vă că ordinea coloanelor este următoarea: "])},
  "data.upload.tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA (1 dacă compania este plătitoare de TVA, 0 în caz contrar)"])},
  "data.upload.click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apasă-mă ca să încărcați un fișier"])},
  "data.upload.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folosiți această funcționalitate ca să încărcați un fișier cu mai "])},
  "data.upload.nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați un fișier pentru a vedea datele acestuia"])},
  "data.upload.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datele au fost încărcate cu succes!"])},
  "email.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmpul email este gol."])},
  "email.valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câmpul trebuie să fie o adresă de email validă."])},
  "pass.length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numărul minim de caractere este 12."])},
  "rule.cui.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUI-ul nu a fost găsit în baza de date. Vă rugăm să completați manual datele."])},
  "rule.cui.server.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pare că serverele ANAF sunt inactive, vă rugăm să reîncercați mai târziu."])},
  "rule.cui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiunea între 1 și 9 cifre."])},
  "rule.registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să fie de forma Jxx/xxxx/xxxx unde x reprezintă o cifră."])},
  "rule.iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să aibă dimensiunea între 15 și 33 cifre."])},
  "rule.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să fie un număr de telefon valid."])},
  "rule.max20Chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trebuie să aibă maxim 50 de caractere."])},
  "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola nu îndeplinește condițiile minime."])},
  "listview.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest tip de afișare va organiza fișiere într-un tabel făcând mai ușoara centralizarea acestora"])},
  "gridview.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest tip de afișare va organiza fișiere într-un sistem de tip directoare pentru o experiență similară cu sistemele de fișiere"])},
  "downloadall.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folosiți acest buton pentru a descărca toate fișierele prezente în acest view. Fișierele vor fi descărcate bazat pe filtrele aplicate."])},
  "downloadall.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a descărca mai multe fișiere vă rugăm să setați filtrele corecte. Niciun fișier nu a fost selectat pentru descărcare."])},
  "form.validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm validați formularul."])},
  "form.company.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma există deja în aplicație."])},
  "companies.add.obs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obs: Adăugarea unei noi companii va crea și un utilizator nou cu rolul Client asociat acesteia"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesar"])},
  "pass.min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel puțin 12 caractere"])},
  "pass.symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel puțin 1 simbol"])},
  "pass.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel puțin 1 cifră"])},
  "pass.uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel puțin 1 majusculă"])},
  "pass.lowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel puțin 1 literă mică"])},
  "pass.match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolele trebuie să fie identice"])},
  "more.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mulți "])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizator"])},
  "user2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizatorul"])},
  "more.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multe "])},
  "loading.failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcarea datelor a eșuat!"])},
  "documents.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toate documentele disponibile pentru dumneavoastră"])},
  "upload.files.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fișiere încărcate în firma "])},
  "upload.files.dateinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" și data "])},
  "upload.files.info.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a vizualiza fișierele unei firme diferite selectați-o din colțul stânga jos."])},
  "upload.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați un fișier"])},
  "upload.drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dați drumul fișierelor oriunde pe ecran ca să le încărcați"])},
  "upload.request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pentru cererea selectată"])},
  "upload.request.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest fișier va fi atribuit și companiei din care face cererea parte"])},
  "upload.request.info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["și poate fi vizionat de asemenea din ecranul de Încărcări"])},
  "upload.dropfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dați drumul fișierului "])},
  "upload.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pe această pagină sau dați click pe acest chenar ca să încărcați un fișier"])},
  "upload.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pentru compania selectată"])},
  "upload.extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipurile de fișiere suportate: pdf, txt, docx, xlsx, xml, csv, pptx, imagini, video, audio"])},
  "upload.extension.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm încărcați fișiere cu una dintre tipurile valide de fișiere: pdf, txt, docx, xlsx, xml, csv, pptx, imagini, video, audio"])},
  "upload.extension.invalid.get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensie invalidă pentru fișierul selectat. Tipurile valide de fișiere sunt: pdf, txt, docx, xlsx, xml, csv, pptx, imagini, video, audio"])},
  "upload.size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dimensiunea maximă de fișier: "])},
  "upload.camera.scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanați un document"])},
  "upload.camera.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm asigurați-vă că un document a fost identificat."])},
  "upload.camera.capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturează"])},
  "upload.camera.finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați imaginea capturată"])},
  "upload.camera.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați imaginea"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nume fișier"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preț"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantitate"])},
  "um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U.M."])},
  "tvaType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotă TVA"])},
  "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conține"])},
  "containsTva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conține TVA"])},
  "products.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produse disponibile"])},
  "products.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produsul există deja în listă"])},
  "employee.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un colaborator cu această adresă de email deja există."])},
  "products.available.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adăugați acest produs la factura curentă."])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produse"])},
  "login.register.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificarea utilizatorului a eșuat."])},
  "login.remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ține-mă minte"])},
  "login.forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ți-ai uitat parola?"])},
  "login.signin.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentificați-vă în cont"])},
  "login.signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifică-te"])},
  "login.activate.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activare contului a fost efectuată cu succes."])},
  "login.activate.fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token-ul pentru activarea contului a expirat. Vă puteți activa contul schimbând parola actuală."])},
  "request.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crează o cerere nouă"])},
  "invoices.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crează o factură nouă"])},
  "invoices.products.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionează produsele "])},
  "invoices.products.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceste produse vor putea fi adăugate în facturile firmei selectate"])},
  "company.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folosiți această funcționalitate pentru a completa câmpurile automat după CUI-ul firmei"])},
  "company.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaugă o firmă nouă"])},
  "company.new.cui.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduceți CUI-ul companiei și apăsați butonul din dreapta câmpului pentru a completa automat detaliile acesteia."])},
  "employee.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adaugă un "])},
  "request.new.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această cerere va fi trimisă colaboratorilor pentru a fi rezolvată"])},
  "invoice.new.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Această factură va fi trimisă clienților firmei selectate"])},
  "login.register.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu aveți un cont? Obțineți unul completând acest "])},
  "company.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți această companie?"])},
  "request.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți această cerere?"])},
  "invoice.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți această factură?"])},
  "products.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți acest produs?"])},
  "subscribe.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să renunțați subscripție?"])},
  "subscribe.delete.confirmation.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta va continua sa fie activă până la expirarea timpului"])},
  "employee.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți acest colaborator?"])},
  "file.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunteți sigur că doriți să ștergeți acest fișier? Această acțiune este ireversibilă iar fișierul va fi șters și de pe cloud."])},
  "folder.create.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferiți un nume noului director"])},
  "folder.home.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spațiul meu"])},
  "folder.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mergeți la directorul firmei dumneavoastră"])},
  "folder.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați directorul în care să fie mutat"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mută"])},
  "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redenumește"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Șterge"])},
  "newfolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director Nou"])},
  "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați Fișier"])},
  "uploadfolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați Director"])},
  "foldercreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director creat cu succes!"])},
  "companyselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vă rugăm să selectați o companie apăsând dublu click pe aceasta."])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deschide"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiază"])},
  "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lipește"])},
  "folder.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locație curentă"])},
  "folder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectați un director pentru a afișa calea acestuia."])},
  "folder.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numele directorului"])},
  "peoplemanager.companies.associated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acest utilizator nu are nicio firmă atribuită încă!"])},
  "peoplemanager.companies.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puteți trage o firmă peste unul dintre angajați pentru a realiza o asociere"])},
  "peoplemanager.companies.message.mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asocierea companiilor este imposibilă în varianta mobilă a aplicației. Va rugăm sa folosiți un alt dispozitiv pentru a realiza asocierea companiilor."])},
  "peoplemanager.companies.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu există firme atribuite utilizatorului dumneavoastră. Mergeți la secțiunea Firme ca să adăugați!"])},
  "screenselect.download.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificați fișierele pregătite pentru descărcare"])},
  "screenselect.upload.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați fișiere pe cloud"])},
  "documents.upload.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Încărcați fișiere sau directoare pe cloud"])},
  "screenselect.requests.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifică și rezolvă cererile atribuite ție"])}
}