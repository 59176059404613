import axios from 'axios';
import $cookies from "vue-cookie";
import Helper from './Helper'
import {cart} from "@/services/cart";
import router from "@/router";
import SubscriptionService from "@/services/SubscriptionService";
import {folderData} from "@/services/FolderService";

const USER_API_BASE_URL = Helper.getHost() + '/api/user';
const PASSWORD_API_BASE_URL = Helper.getHost() + '/api/user/password';
const LOGIN_API_BASE_URL = Helper.getHost() + '/api/login';
const LOGOUT_API_BASE_URL = Helper.getHost() + '/api/logout';
const COMPANY_API_BASE_URL = Helper.getHost() + '/api/company';

class UserService{
    formItem = null;

    loginUser(username, password) {
        return axios.post(LOGIN_API_BASE_URL, null, { headers: {'username': username, 'password': password}});
    }

    logoutUser() {
        return axios.post(LOGOUT_API_BASE_URL);
    }

    getUserByEmail(email) {
        return axios.get(USER_API_BASE_URL + "/email", { headers: {'email': email}});
    }

    getUserById(id) {
        return axios.get(USER_API_BASE_URL, { headers: {'userId': id}});
    }

    checkUserPasswordUuid(uuid) {
        return axios.get(PASSWORD_API_BASE_URL, { headers: {'uuid': uuid}});
    }

    activateAccount(uuid) {
        return axios.post(USER_API_BASE_URL + "/activate", null,{ headers: {'uuid': uuid}});
    }

    changePassword(uuid, password) {
        return axios.post(PASSWORD_API_BASE_URL, null,{ headers: {'uuid': uuid, 'password': password}});
    }

    previewFileData(file, userId) {
        let formData = new FormData();
        formData.append('file', file, file.name);
        return axios.post(USER_API_BASE_URL + "/file", formData, { headers: {'userId': userId}});
    }

    uploadFileData(fileData, employerId) {
        return axios.post(USER_API_BASE_URL + "/file/upload", fileData, { headers: {'employerId': employerId}});
    }

    downloadFileTemplate() {
        return axios.get(USER_API_BASE_URL + "/template").then(bytes => {
            let blob = new Blob([bytes.data], {type: "octet/stream"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "users_data_template.csv";
            link.click();
        });
    }

    tutorialDone(userId) {
        return axios.post(USER_API_BASE_URL + "/tutorial", null,{ headers: {'userId': userId}});
    }

    changePasswordEmail(email) {
        return axios.get(PASSWORD_API_BASE_URL + "/reset", { headers: {'email': email}});
    }

    getUserEmployees(id) {
        return axios.get(USER_API_BASE_URL + "/employees", { headers: {'userId': id}});
    }

    getUsersByCompany(id) {
        return axios.get(USER_API_BASE_URL + "s/company", { headers: {'companyIds': id}});
    }

    setUser(user) {
        $cookies.set("user.id", user.id)
        $cookies.set("user.username", user.username)
        $cookies.set("user.name", user.name)
        $cookies.set("user.email", user.email)
        $cookies.set("user.role", user.role)
        $cookies.set("user.admin", user.admin)
        if (user.subscription === null)
            $cookies.set("user.subscriptionType", 'Free')
        else
            $cookies.set("user.subscriptionType", user.subscription.subscriptionType)
    }

    setActiveUser(isActive) {
        $cookies.set("user.active", isActive);
        cart.value.activeUser = isActive;
    }

    isUserActive() {
        return cart.value.activeUser || $cookies.get("user.active") == 'true'
    }

    fetchIsUserActive(user, fn=() => {}) {
        SubscriptionService.getLinkByUserId(user.id).then(subLink => {
            this.setActiveUser(false)
            if (!subLink.data || subLink.data.length === 0) {
                return;
            }
            subLink.data.forEach(subL => {
                if (!subL.id) {
                    return;
                }
                SubscriptionService.isActive(subL.id).then(isActive => {
                    let activeCheck = false;
                    if (typeof isActive === 'boolean' || (isActive instanceof Boolean)) {
                        activeCheck = isActive;
                    } else {
                        activeCheck = isActive.data;
                    }
                    if (activeCheck) {
                        this.setActiveUser(true);
                        fn();
                    } else {
                        this.setActiveUser(false);
                    }
                });
            });
            fn();
        })
    }

    setLoggedUser(user) {
        this.loggedUser = user;
    }

    getLoggedUser() {
        if (this.loggedUser) return this.loggedUser;
        return {
            id: $cookies.get("user.id"),
            username: $cookies.get("user.username"),
            name: $cookies.get("user.name"),
            email: $cookies.get("user.email"),
            role: $cookies.get("user.role"),
            admin: ($cookies.get("user.admin") === "true"),
            subscriptionType: $cookies.get("user.subscriptionType"),
            active: $cookies.get("user.active"),
        }
    }

    getSession() {
        return $cookies.get("session");
    }

    getCompanies(userId) {
        return axios.get(USER_API_BASE_URL + "/companies", { headers: {'userId': userId}});
    }

    logout() {
        this.logoutUser().then(() => {
            this.clearCookies();
            this.loggedUser = null;
            cart.value.appLoader = false;
            folderData.value.filesToUpload = [];
            cart.value.showUploadFilesWindow = false;
            cart.value.appLoader = false;
            cart.value.company = null;
            cart.value.mycompany = null;
            cart.value.companies = [];
            localStorage.removeItem("selectedCompanyId");
            router.push({ path: '/login' });
        });
    }

    clearCookies() {
        $cookies.delete("user.id")
        $cookies.delete("user.username")
        $cookies.delete("user.name")
        $cookies.delete("user.email")
        $cookies.delete("user.role")
        $cookies.delete("user.admin")
        $cookies.delete("session")
        $cookies.delete("user.subscriptionType")
        $cookies.delete("user.active")
    }

    isUserLoggedIn() {
        return !!$cookies.get("user.id") || this.loggedUser
    }

    getUserSubType() {
        return $cookies.get("user.subscriptionType")
    }

    getAll() {
        return axios.get(USER_API_BASE_URL + "s");
    }

    add(employerId, user) {
        return axios.post(USER_API_BASE_URL + "/add", user, { headers: {'employerId': employerId}});
    }

    edit(user) {
        return axios.put(USER_API_BASE_URL, user);
    }

    setDefaultCompany(uuid, companyId) {
        return axios.put(USER_API_BASE_URL + "/company", null, { headers: {'companyId': companyId, 'uuid': uuid}});
    }

    remove(id) {
        return axios.delete(USER_API_BASE_URL, { headers: {'userId': id}});
    }

    associateCompany(userId, companyId) {
        return axios.post(USER_API_BASE_URL + "/associate/company", null, { headers: {'userId': userId, 'companyId': companyId}});
    }

    removeCompanyAssociation(userId, companyId) {
        return axios.delete(USER_API_BASE_URL + "/associate/company", { headers: {'userId': userId, 'companyId': companyId}});
    }

    deleteUserAssociationsOfCompany(companyId) {
        return axios.delete(COMPANY_API_BASE_URL + "/associations", { headers: {'companyId': companyId}});
    }

    getUserByIdLocally(userId) {
        if (!userId) return;
        // const user = this.getLoggedUser();
        // if (userId === user.id) return user;

        let employee = cart.value.usersCache.find(emp => emp.id === userId);

        if (employee || cart.value.usersIdCache.indexOf(userId) !== -1) return employee;
        cart.value.usersIdCache.push(userId);

        employee = cart.value.users.find(emp => emp.id === userId);
        if (employee) {
            this.addUserToCache(employee)
            return employee;
        }
        employee = cart.value.usersByCompany.find(emp => emp.id === userId);
        if (employee){
            this.addUserToCache(employee)
            return employee;
        }

        this.getUserById(userId).then(user => {
            this.addUserToCache(user.data)
            return user;
        });
    }

    addUserToCache(user) {
        // const emails = cart.value.usersCache.map(function(item) {
        //     return item['email'];
        // });
        // if (emails.indexOf(user.email) !== -1) return;
        cart.value.usersCache.push(user);
    }


    setFormItem(item) {
        this.formItem = item;
    }

    getFormItem() {
        return this.formItem;
    }

    getLoggedUserOrEmployer() {
        let loggedUser = this.getLoggedUser();
        loggedUser = this.getUserByIdLocally(loggedUser.id);
        if (loggedUser && loggedUser.employer) {
            loggedUser = this.getUserByIdLocally(loggedUser.employer);
        }
        return loggedUser;
    }

}

export default new UserService();