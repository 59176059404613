<template>
  <div id="screen-select-container" class="tutorial-step-2" style="margin-top: 10%;">
    <v-layout align-center row :class="mq.current === 'mobile' ? 'screen-select-buttons-container-mobile' : 'screen-select-buttons-container'">
      <v-hover v-slot="{ isHovering }">
        <v-card
            class="mx-auto screen-select-card"
            variant="outlined"
            :elevation="isHovering ? 16 : 4"
            @click="goToDownload"
        >
          <v-card-item >
            <div class="account-card-header"></div>
            <div class="center-text" style="margin-top: 10px;">
              <v-icon class="account-card-image">mdi-download</v-icon>
            </div>
            <div class="mb-1 center-text card-title" :style="cart.isDark ? 'color: white;' : 'color: black;'">
              <span style="font-size: large">{{ $t('leftmenu.download') }}</span>
            </div>
            <div class="text-caption-custom center-text card-text" :style="cart.isDark ? 'color: white;' : 'color: black;'">{{ $t('screenselect.download.message') }}</div>
          </v-card-item>
        </v-card>
      </v-hover>
      <v-hover v-slot="{ isHovering }">
        <v-card
            class="mx-auto screen-select-card"
            variant="outlined"
            :elevation="isHovering ? 24 : 6"
            @click="goToUpload"
            :disabled="isFree"
        >
          <v-card-item>
            <div class="account-card-header"></div>
            <div class="center-text" style="margin-top: 10px;">
              <v-icon class="account-card-image">mdi-upload</v-icon>
            </div>
            <div class="mb-1 center-text card-title" :style="cart.isDark ? 'color: white;' : 'color: black;'">
              <span style="font-size: large">{{ $t('leftmenu.upload') }}</span>
            </div>
            <div class="text-caption-custom center-text card-text" :style="cart.isDark ? 'color: white;' : 'color: black;'">{{ $t('screenselect.upload.message') }}</div>
          </v-card-item>
        </v-card>
      </v-hover>
      <v-hover v-slot="{ isHovering }">
        <v-card
            class="mx-auto screen-select-card"
            variant="outlined"
            :elevation="isHovering ? 24 : 6"
            @click="goToRequests"
            :disabled="isFree"
        >
          <v-card-item>
            <div class="account-card-header"></div>
            <div class="center-text" style="margin-top: 10px;">
              <v-icon class="account-card-image">mdi-file-find</v-icon>
            </div>
            <div class="mb-1 center-text card-title" :style="cart.isDark ? 'color: white;' : 'color: black;'">
              <span style="font-size: large">{{ $t('leftmenu.requests') }}</span>
            </div>
            <div class="text-caption-custom center-text card-text" :style="cart.isDark ? 'color: white;' : 'color: black;'">{{ $t('screenselect.requests.message') }}</div>
          </v-card-item>
        </v-card>
      </v-hover>
    </v-layout>
  </div>
</template>

<script setup>
import { cart } from '@/services/cart';
</script>

<script>
import router from "@/router";
import UserService from "@/services/UserService";
import SubscriptionService from "@/services/SubscriptionService";

export default {
  name: "ScreenSelect",
  inject: ["mq"],
  data() {
    return {
      user: null,
      isFree: true,
      subscriptionType: null,
      admin: true
    }
  },
  mounted() {
    this.user = UserService.getLoggedUser();
    this.admin = this.user.admin;
    this.subscriptionType = this.user.subscriptionType;
    this.isFree = !this.admin && SubscriptionService.isFreeEmployee(this.subscriptionType);
  },
  methods: {
    goToUpload() {
      router.push({ path: '/upload' })
    },
    goToDownload() {
      router.push({ path: '/download' })
    },
    goToRequests() {
      router.push({ path: '/requests' })
    }
  }
}
</script>

<style scoped>

.screen-select-card {
  width: 300px;
  height: 260px;
}

.center-text {
  text-align: center;
}

.account-card-image {
  border: 2px solid rgb(var(--v-theme-secondary));
  color: black;
  border-radius: 50px;
  background-color: white;
  width: 100px;
  height: 100px;
  font-size: 70px;
}

.account-card-header {
  position: absolute;
  /*background: linear-gradient(to right,  #756cec, #2e73ee, #756cec);*/
  /*background-image: linear-gradient(v-bind(customGradientColor), #078B91, v-bind(customGradientColor));*/
  background-image: linear-gradient(rgb(var(--v-theme-gradient1)), rgb(var(--v-theme-gradient2)), rgb(var(--v-theme-gradient1)));
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
}

.card-title {
  margin: 20px 0 20px 0;
}

.card-text {
  margin: 20px 0 20px 0;
}

/*.screen-select-card.v-theme--mainDarkTheme {*/
/*  color: white;*/
/*  background-color: rgb(35, 35, 35);*/
/*  border: 2px solid gray;*/
/*}*/

/*.account-card-image.v-theme--mainDarkTheme {*/
/*  background-color: #2e73ee;*/
/*  border: 2px solid #756cec;*/
/*}*/

.screen-select-buttons-container {
  display: flex;
}

.screen-select-buttons-container-mobile {
  display: block;
}

.screen-select-buttons-container-mobile .screen-select-card {
  width: 60%;
  height: 10%;
  margin-bottom: 8%;
}

.screen-select-buttons-container-mobile .account-card-header {
  height: 40px;
}

.screen-select-buttons-container-mobile .card-title {
  margin: 5% 0;
}

.screen-select-buttons-container-mobile .card-text {
  margin: 8% 0;
}

.screen-select-buttons-container-mobile .account-card-image {
  width: 60px;
  height: 60px;
  font-size: 40px;

}

.screen-select-buttons-container-mobile .v-card-item {
  padding: 0 10px;
}

</style>