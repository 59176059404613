<template>
  <div class="document-view content-page">
    <LeftMenu/>
    <TopBar/>
    <folder-system/>
    <TutorialOverlay/>
  </div>
</template>

<script setup>
</script>

<script>
import TopBar from '@/components/TopBar.vue'
import LeftMenu from "@/components/LeftMenu";
import {defineAsyncComponent} from "vue";
import TutorialOverlay from "@/components/TutorialOverlay";

const FolderSystem = defineAsyncComponent(() =>
    import('@/components/fileComponents/FolderSystem')
)

export default {
  name: "DocumentView",
  components: {
    TutorialOverlay,
    LeftMenu,
    TopBar,
    FolderSystem
  },
}
</script>

<style scoped>

</style>