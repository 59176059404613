<template>
  <iframe :src="homepage" style="width: 100%; height: 99vh; border: none;"></iframe>
</template>
<script setup>

</script>

<script>
import router from "@/router";

export default {
  name: "HomePage",
  data: () => ({
    homepage: "homepage.html"
  }),
  mounted() {
    this.homepage = router.currentRoute.value.name === 'homeEn' ? "homepage_en.html" : "homepage.html";
  }
}
</script>

<style scoped>

</style>