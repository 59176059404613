import axios from "axios";

class Helper {

    getHost() {
        // return 'http://192.168.1.217:8080';
        // return 'http://localhost:8080';
        // return 'https://icarus-data.oa.r.appspot.com'; //QA
        return 'https:///icarusdocs.oa.r.appspot.com'; //Prod
    }

    selectMenuItem(itemName) {
        setTimeout(() => {
            let element = document.getElementById(itemName);
            if (element) {
                element.classList.add("v-list-item--active")
                element.classList.add("text-primary")
            }
        }, 100)
    }

    sendFbEvent(track, page) {
        if (window.fbq) {
            window.fbq(track, page);
        }
    }

    getFileColorAndIcon(extension, isDirectory = false) {
        let color;
        let icon;
        switch (extension) {
            case 'pdf': icon = 'mdi-file-pdf-box'; color = '#B40C00'; break;
            case 'xlsx': case 'xls': icon = 'mdi-microsoft-excel'; color = '#077d3f'; break;
            case 'doc': case 'docx': icon = 'mdi-microsoft-word'; color = '#006993'; break;
            case 'txt': case 'log': icon = 'mdi-text-box-outline'; color = '#000'; break;
            case 'csv': icon = 'mdi-file-delimited-outline'; color = '#077d3f'; break;
            case 'xml': icon = 'mdi-file-xml-box'; color = '#077d3f'; break;
            case 'ppt': case 'pptx': icon = 'mdi-microsoft-powerpoint'; color = '#d44721'; break;
            case 'png': case 'jpg':case 'jpeg': case 'tiff': case 'svg': case 'gif': icon = 'mdi-file-image'; color = '#885ca7'; break;
            case 'mov': case 'mp4': icon = 'mdi-video'; color = '#ff4164'; break;
            case 'mp3': case 'aac': case 'wav': case 'wma': case 'flac': case 'm4a': icon = 'mdi-volume-medium'; color = '#5264cc'; break;
            default: color = '#000'; icon = isDirectory ? 'mdi-folder' : 'mdi-file';
        }
        return {icon: icon, color: color};
    }

    pingServer() {
        return axios.get(this.getHost() + "/api/health");
    }
}

export default new Helper()