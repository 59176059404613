<template>
  <v-card
      :class="mq.current === 'mobile' ? 'register-success-card-mobile' : 'register-success-card'"
  >
    <div class="overview-card request-category">
      <!--              <span class="overview-card-title">{{$t('register.account')}}</span>-->
      <v-icon size="x-large" style="margin: 0 calc(50% - 30px); cursor: default; font-size: 64px;" icon="mdi-check-circle"></v-icon>
      <span style="margin: 5px calc(50% - 38px);" class="overview-card-title">{{ $t('success') }}</span>
    </div>
    <div class="overview-card-content">
      <span style="height: fit-content; margin: 5px 0; color: rgb(var(--v-theme-success))"> {{ $t('subscription.success') }} </span>
      <span style="font-size: 14px; height: fit-content; margin: 5px 0; font-weight: bold;">{{ $t('subscription.success.continue') }}</span>
<!--      <span style="font-size: 14px; height: fit-content; margin: 5px 0;">{{ $t('email.success') }}</span>-->
      <span style="font-size: 12px; height: fit-content; margin-top: 40px;">{{ $t('subscription.success.info') }}</span>
    </div>
<!--    <div style="position: absolute; bottom: 10px; left: calc(50% - 53px);">-->
<!--      <v-btn prepend-icon="mdi-login" color="success" @click="goToLogin">Login</v-btn>-->
<!--    </div>-->
  </v-card>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import router from "@/router";
import UserService from "@/services/UserService";
import Helper from "@/services/Helper";
import SettingsService from "@/services/SettingsService";
import i18n from "@/i18n";

export default {
  name: "RegistrationSuccess",
  inject: ['mq'],
  mounted() {
    this.uuid = this.$route.query.uuid
    if (!this.uuid) router.push({path: "login"});
    SubscriptionService.saveRegisterData(this.uuid).then(result => {
      this.applyUserSettings();
      Helper.sendFbEvent("track", "RegistrationSuccess");
      if (!result || !result.data) {
        // router.push({path: "login"});
      }
    })
  },
  methods: {
    goToLogin() {
      router.push({name: 'login'})
    },
    applyUserSettings() {
      const settings = SettingsService.getUserSettings();
      i18n.global.locale.value = settings.Language;
      this.theme.global.name.value = settings.Theme;
    }
  }
}
</script>

<style scoped>
.register-success-card {
  min-width: 400px;
  height: 350px;
  margin: 10px calc(50% - 200px) 20px;
  /*padding: 20px;*/
  overflow: auto;
}

.register-success-card-mobile {
  width: 80%;
  margin: 10px 10% 20px;
  /*padding: 20px;*/
  overflow: auto;
}

.overview-card {
   margin-bottom: 20px;
 }

.request-category {
  display: grid;
  /*margin: 10px;*/
  border: 1px solid rgb(var(--v-theme-leftMenu));
  padding: 10px;
  border-radius: 5px;
  /*box-shadow: 1px 2px 3px 3px #e6e6e6;*/
  /*margin: 10px 5px;*/
  color: white;
  background: rgb(var(--v-theme-success));
}

.overview-card-title {
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
}

.overview-card-content {
  display: grid;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

</style>