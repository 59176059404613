<template>
  <div class="screen-select content-page" style="height: -webkit-fill-available;">
    <TopBar/>
    <ScreenSelect/>
    <TutorialOverlay/>
  </div>
</template>

<script>
// @ is an alias to /src
import ScreenSelect from '@/components/ScreenSelect.vue'
import TopBar from '@/components/TopBar.vue'
import TutorialOverlay from "@/components/TutorialOverlay";

export default {
  name: 'ScreenSelectView',
  components: {
    TopBar,
    ScreenSelect,
    TutorialOverlay
  }
}
</script>
