<template>
  <div class="content-page">
    <top-bar :register="true"></top-bar>
<!--    height="calc(100vh - 125px)"-->
    <v-card
        :class="mq.current === 'mobile' ? 'stepper-item-mobile' : 'stepper-item'" class="content-page-main"
        :height="getStepperHeight"
    >
      <v-stepper  :model-value="getCurrentStep" alt-labels
                  style="overflow: unset; box-shadow: none; height: -webkit-fill-available">
        <div
            style="position: -webkit-sticky; position: sticky; top: 0; z-index: 10; background: rgb(var(--v-theme-whiteBackground));">
          <v-stepper-header style="overflow: unset; box-shadow: none;">
            <v-stepper-item color="primary" :complete="stepComplete('1')" :title="mq.current === 'mobile' ? '' : $t('register.account')" value="1" style="margin-left: 0;"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item color="primary" :complete="stepComplete('2')" :title="mq.current === 'mobile' ? '' : $t('register.company')" value="2"></v-stepper-item>
            <v-divider></v-divider>
            <v-stepper-item color="primary" :complete="stepComplete('3')" :title="mq.current === 'mobile' ? '' : $t('subscription')" value="3"></v-stepper-item>
<!--            <v-divider></v-divider>-->
<!--            <v-stepper-item color="primary" :complete="stepComplete('4')" :title="mq.current === 'mobile' ? '' : $t('register.review')" value="4"></v-stepper-item>-->
          </v-stepper-header>
          <v-divider></v-divider>
        </div>
        <v-stepper-window style="min-height: -webkit-fill-available;"
                          :model-value="getCurrentStep"
                          v-touch="{left: () => prev, right: () => next}">
          <v-stepper-window-item
              value="1"
              class="register-window"
          >
            <v-form v-model="validUserForm" ref="userForm">
              <v-text-field
                  v-model="userItem.lastname" class="form-item"
                  :label="$t('name') + '*'"
                  clearable="true"
                  :rules="[requiredRule]"
                  density="compact"
                  variant="outlined"
              ></v-text-field>
              <v-text-field
                  v-model="userItem.firstname" class="form-item"
                  :label="$t('firstname') + '*'"
                  clearable="true"
                  :rules="[requiredRule]"
                  density="compact"
                  variant="outlined"
              ></v-text-field>
              <v-text-field
                  v-model="userItem.email" class="form-item"
                  label="Email*"
                  :rules="[requiredRule, emailRule]"
                  clearable="true"
                  density="compact"
                  variant="outlined"
              ></v-text-field>
              <v-text-field
                  v-model="userItem.phone" class="form-item"
                  :label="$t('telephone') + '*'"
                  :rules="[requiredRule, phoneRule]"
                  clearable="true"
                  density="compact"
                  variant="outlined"
              ></v-text-field>
              <span style="font-size: 13px; color: gray;">{{ $t('register.mail') }}</span>
            </v-form>
          </v-stepper-window-item>
          <v-stepper-window-item
              value="2"
              class="register-window"
          >
              <company-form title="" :item="companyItem" class="company-form" @interface="getChildInterface"
                            submitType="myCompany" :noActions="true" ref="companyForm"/>
          </v-stepper-window-item>
          <v-stepper-window-item
              value="3"
              class="register-window"
          >
<!--            <div>-->
<!--              <span style="font-size: 12px;">{{ $t('subscription.code') }}</span>-->
<!--              <div style="display: flex;">-->
<!--                <v-text-field-->
<!--                    v-model="betaCode"-->
<!--                    clearable="true"-->
<!--                    density="compact"-->
<!--                    variant="outlined"></v-text-field>-->
<!--                <v-btn @click="checkFreeCode" icon="mdi-magnify" color="primary"-->
<!--                       style="margin: 0 0 0 10px;" size="small">-->
<!--                  <v-icon>mdi-magnify</v-icon>-->
<!--                  <tooltip-message :message="$t('company.search')"/>-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </div>-->
            <v-divider></v-divider>
            <div v-for="(subscription, index) in subs" v-bind:key="subscription.id" style="width: -webkit-fill-available;">
              <v-card
                class="mx-auto screen-select-card"
                variant="outlined"
                :elevation="4"
                :style="index === 4 ? 'margin-right: 0 !important;' : ''"
                :color="getColor(index)"
                @click="selectSub(subscription, index)"
            >
              <v-card-item :id="'sub-' + index">
                <div class="account-card-header"></div>
                <div class="card-text-details">
                  <div class="mb-1 center-text card-title">
                    <span style="font-size: large">{{ $t('subscription.name.' + subscription.subscriptionType) }}</span>
                  </div>
                  <div class="center-text card-text"><b>{{ subscription.price }}</b> RON / {{ $t('month') }}</div>
                  <div class="text-caption-custom center-text card-info-text">
                    <b>{{ subscription.maxCompanies == '10000' ? $t('subscription.nolimit') : subscription.maxCompanies }}</b>{{ $t('subscription.maxCompanies') }}
                    {{ $t('and') }}
                    <b>{{ subscription.maxUsers }}</b>{{ $t('subscription.maxUsers') }}
                    {{ subscription.subscriptionType === 'Free' ? $t('subscription.filelimit').toLowerCase() : ''}}
                  </div>
                </div>
              </v-card-item>
            </v-card>
            </div>
          </v-stepper-window-item>
          <v-stepper-window-item
              value="4"
              class="register-window"
          >
            <div class="overview-card request-category">
<!--              <span class="overview-card-title">{{$t('register.account')}}</span>-->
              <v-btn size="x-small" icon="mdi-account" color="purple-lighten-3" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
              <span style="margin: 5px calc(50% - 22px);" class="overview-card-title">{{ $t('register.account') }}</span>
              <div class="overview-card-content">
                <span>{{$t('firstname') + ": " + userItem.firstname}}</span>
                <span>{{$t('name') + ": " + userItem.lastname}}</span>
                <span>{{$t('Email') + ": " + userItem.email}}</span>
                <span>{{$t('telephone') + ": " + (userItem.phone ? userItem.phone : $t('undefined'))}}</span>
              </div>
            </div>
            <div class="overview-card request-category">
              <v-btn size="x-small" icon="mdi-home" color="light-green-darken-1" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
              <span style="margin: 5px calc(50% - 27px);" class="overview-card-title">{{$t('register.company')}}</span>
              <div class="overview-card-content">
                <span>{{$t('name') + ": " + (cart.mycompany.name ? cart.mycompany.name : $t('undefined'))}}</span>
                <span>{{"CUI: " + (cart.mycompany.cui ? cart.mycompany.cui : $t('undefined'))}}</span>
                <span>{{"Administrator: " + (cart.mycompany.administrator ? cart.mycompany.administrator : $t('undefined'))}}</span>
                <span>{{$t('companyRegistry') + ": " + (cart.mycompany.registryNo ? cart.mycompany.registryNo : $t('undefined'))}}</span>
                <span>{{$t('county') + ": " + (cart.mycompany.county ? cart.mycompany.county : $t('undefined'))}}</span>
                <span>{{$t('city') + ": " + (cart.mycompany.city ? cart.mycompany.city : $t('undefined'))}}</span>
                <span>{{$t('address') + ": " + (cart.mycompany.address ? cart.mycompany.address : $t('undefined'))}}</span>
                <span>{{"IBAN: " + (cart.mycompany.iban ? cart.mycompany.iban : $t('undefined'))}}</span>
              </div>
            </div>
            <div class="overview-card request-category">
              <v-btn size="x-small" icon="mdi-timetable" color="indigo-lighten-2" style="margin: 0 calc(50% - 15px); cursor: default;"></v-btn>
              <span style="margin: 5px calc(50% - 52px);" class="overview-card-title">{{$t('subscription')}}</span>
              <div class="overview-card-content">
                <span>{{ selectedSub.maxCompanies + " " + $t('subscription.maxCompanies') }}</span>
                <span>{{ selectedSub.maxUsers + " " + $t('subscription.maxUsers') }}</span>
                <span>{{$t('subscription.maxFileSize') + " " + selectedSub.maxFileSize + " megabytes"}}</span>
                <span>
                  {{ selectedSub.subscriptionType === 'Free' ?
                    ($t('subscription.filelimit')) :
                    ($t('subscription.totalFileSize') + " " + selectedSub.totalFileSize + " gigabytes") }}
                </span>
                <span>{{$t('price') + " " + selectedSub.price + " RON"}}</span>
              </div>
            </div>
          </v-stepper-window-item>
        </v-stepper-window>
        <div :class="mq.current === 'mobile' ? 'register-actions-container-mobile' : 'register-actions-container'">
          <div style="display: flex; padding: 15px;">
            <v-btn size="small" @click="prev" prepend-icon="mdi-arrow-left" v-if="step !== 0" color="grey-lighten-1">{{ $t('back') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn size="small" @click="next" append-icon="mdi-arrow-right" v-if="step !== 2">{{ $t('next') }}</v-btn>
            <v-btn size="small" @click="finalize" append-icon="mdi-check-circle-outline" v-if="step === 2" color="success">{{ $t('pay') }}</v-btn>
          </div>
        </div>
      </v-stepper>
    </v-card>
  </div>
  <v-overlay
      :model-value="loading"
      persistent
      class="align-center justify-center"
  >
    <v-progress-circular
        color="primary"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup>
import { rules } from '@/services/rules';
import CompanyForm from "@/components/forms/CompanyForm";
import TopBar from "@/components/TopBar";
import {cart} from "@/services/cart";

</script>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import i18n from "@/i18n";
import Helper from "@/services/Helper";
import UserService from "@/services/UserService";

export default {
  name: "RegisterUser",
  inject: ['mq'],
  data: () => ({
    loading: false,
    step: 0,
    validUserForm: false,
    userItem: {},
    companyItem: {},
    requiredRule: rules.value.required,
    emailRule: rules.value.email,
    phoneRule: rules.value.phone,
    subs: [],
    betaSub: {},
    betaCode: "",
    subsToDisplay: ['Free', 'Tier1', 'Tier2', 'Tier3'],
    selectedSub: null
  }),
  mounted() {
    cart.value.mycompany = {};
    Helper.sendFbEvent("track", "StartRegister");
    this.loadSubs();
    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) {
      let elements = document.getElementsByClassName("register-actions-container");
      // let stepperItems = document.getElementsByClassName("stepper-item");
      if (elements.length === 0) return;
      elements[0].style.height = "56px";
      // stepperItems[0].style.height = "100vh";
      // console.log(stepperItems);
      // stepperItems.forEach(item => {
      //   item.style.height = "calc(-68px + 100vh)";
      // })
    }
  },
  computed: {
    getCurrentStep() {
      return this.step;
    },
    getStepperHeight() {
      return navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0 ? "100vh" : "calc(100vh - 125px)"
    }
  },
  methods: {
    loadSubs() {
      SubscriptionService.getAllDetails().then(subs => {
        this.subs = subs.data.reverse();
        this.subs = this.subs.filter(sub => {
          return this.subsToDisplay.includes(sub.subscriptionType);
        });
        this.betaSub = subs.data.find(sub => sub.subscriptionType === 'Beta');
      });
    },
    stepComplete(status) {
      return status <= this.step;
    },
    prev() {
      if (this.step > 0)
        this.step--;
    },
    next() {
      if (this.step === 0) {
        if (!this.validUserForm) {
          this.invalidateForm();
          this.$refs.userForm.validate();
          return;
        } else {
          UserService.getUserByEmail(this.userItem.email).then(response => {
            if (response !== null && response.data && response.data.id) {
              cart.value.showAlertDetails(i18n.global.t('email.exists'));
            }
          }).catch(() => {
            this.step++;
          });
        }
      }
      if (this.step === 1 && (!cart.value.mycompany || !cart.value.mycompany.cui || !this.validateCompany())) {
        this.invalidateForm();
        this.$refs.companyForm.$refs.form.validate();
        return;
      }
      if (this.step === 2 && (!this.selectedSub || !this.selectedSub.id)) {
        console.log("Invalid add form");
        cart.value.showAlertDetails(i18n.global.t('form.validate'));
        return;
      }
      if (this.step === 3) {
        Helper.sendFbEvent("track", "RegisterOveriew");
      }
      if (this.step !== 0 && this.step < 3)
        this.step++;
    },
    invalidateForm() {
      console.log("Invalid add form");
      cart.value.showAlertDetails(i18n.global.t('form.validate'));
    },
    validateCompany() {
      return cart.value.mycompany.name && cart.value.mycompany.cui && cart.value.mycompany.administrator &&
          cart.value.mycompany.registryNo && cart.value.mycompany.county && cart.value.mycompany.city &&
          cart.value.mycompany.address;
    },
    finalize() {
      console.log("Register complete");
      this.loading = true;
      this.userItem.role = 'Accountant';
      Helper.sendFbEvent("track", "RegisterOveriew");
      SubscriptionService.prepareData(this.userItem, cart.value.mycompany, this.selectedSub, false).then(url => {
        this.loading = false;
        window.open(url.data.replaceAll(/\s/g,''), "_self");
      });

    },
    startLoading() {
      this.loading = true;
    },
    getColor(index) {
      switch (index) {
        case 0: return '#a8a7a7';
        case 1: return 'rgb(var(--v-theme-success))';
        case 2: return 'rgb(var(--v-theme-primary))';
        case 3: return '#FFC400';
      }
    },
    selectSub(sub, index) {
      this.selectedSub = sub;
      for (let i = 0; i < this.subsToDisplay.length; i++) {
        let element = document.getElementById('sub-' + i);
        //Selected sub
        if (i === index) {
          element.style.background = this.getColor(i);
          element.style.color = "#fff";
        //  Other subs
        } else {
          element.style.color = this.getColor(i);
          element.style.background = "none";
        }
      }
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    validForm() {
      return this.$options.childInterface.validForm();
    },
    checkFreeCode() {
      if (this.betaCode === 'fe8cbb6d-16d1-4fd5-ad0a-7e4c62b30eed') {
        this.selectedSub = this.betaSub;
        this.next();
      } else {
        cart.value.showAlertDetails(i18n.global.t('subscription.beta.wrong'))
      }
    }
  }
}
</script>

<style scoped>
.stepper-item {
  min-width:400px;
  margin: 10px calc(50% - 325px) 20px;
  /*padding: 20px;*/
  overflow: auto;
  height: calc(100vh - 125px);
}

.stepper-item-mobile {
  /*padding: 20px;*/
  overflow: auto;
  height: calc(100vh - 125px);
}

.register-actions-container {
  position: fixed;
  bottom: 10px;
  /*background-color: white;*/
  width: 650px;
  background-color: rgb(var(--v-theme-primary));
  color: white;
  /*-webkit-transform: translateZ(0);*/
  -webkit-transform: translate3d(0,0,0);
}

.register-actions-container-mobile {
  position: fixed;
  bottom: 0;
  /*background-color: white;*/
  width: 100%;
  background-color: rgb(var(--v-theme-primary));
  color: white;
  -webkit-transform: translateZ(0);
  transform: translate3d(0,0,0);
}

.form-item {
  margin: 10px 0;
}

.v-stepper--alt-labels .v-stepper-item{
  flex-basis: 100px;
}

.v-stepper--alt-labels .v-stepper-header .v-divider {
  margin: 35px -30px 0;
}

.company-form {
  /*padding: 0 !important;*/
  padding-bottom: 20px;
}

.company-form:deep(.v-form) {
  width: auto !important;
  margin: 0 !important;
}

.account-card-image {
  border: 2px solid white;
  border-radius: 50px;
  background-color: rgb(var(--v-theme-secondary));
  width: 100px;
  height: 100px;
  font-size: 70px;
}

.account-card-header {
  position: absolute;
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
}

.screen-select-card {
  margin-bottom: 20px;
}

.overview-card {
  margin-bottom: 20px;
}

.request-category {
  display: grid;
  /*margin: 10px;*/
  border: 1px solid rgb(var(--v-theme-leftMenu));
  padding: 10px;
  border-radius: 5px;
  /*box-shadow: 1px 2px 3px 3px #e6e6e6;*/
  margin: 10px 5px;
  /*background: rgb(var(--v-theme-leftMenu));*/
}


.overview-card-title {
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
}

.overview-card-content {
  display: grid;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}
</style>