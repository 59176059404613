import Helper from "@/services/Helper";
import {cart} from "@/services/cart";
import FileService from "@/services/FileService";
import {folderData} from "@/services/FolderService";

const FILE_STREAM_API = Helper.getHost() + '/api/files/stream';

class DataSseStream {
    // subscribeCompanies(companyIds) {
    //     let source = new EventSource(FILE_STREAM_API + "/" + companyIds.replaceAll(" ", ""))
    //     source.addEventListener('FILE_DETAILS', function(e) {
    //         // console.log(e.data);
    //         // cart.value.startLoadingVar('uploadTableLoading');
    //         // cart.value.startLoadingVar('downloadTableLoading');
    //         // const companyIds = cart.value.companies.map(company => company.id);
    //         // //If data was already loaded don't reload it
    //         // if (cart.value.uploadTableData.length > 0) cart.value.uploadTableLoading = false;
    //         // if (cart.value.downloadTableData.length > 0) cart.value.downloadTableLoading = false;
    //         // if (cart.value.downloadTableData.length > 0 && cart.value.uploadTableData.length > 0) return;
    //
    //         if (cart.value.company && cart.value.company.id) {
    //             FileService.getFilesByCompany(companyIds).then(files => {
    //                 cart.value.setDownloadTableData(files)
    //                 cart.value.setUploadTableData(files.filter(file => file.companyId === cart.value.company.id))
    //                 cart.value.downloadTableLoading = false;
    //                 cart.value.uploadTableLoading = false;
    //             });
    //         }
    //     }, false);
    // }
    //
    // subscribe() {
    //     let source = new EventSource(FILE_STREAM_API)
    //     source.addEventListener('UploadInfo', (event) => {
    //         if (event.data) {
    //             let fileId = event.data;
    //             let file = folderData.value.filesToUpload.find(f => f.id === fileId);
    //             if (file) {
    //                 file.uploaded = true;
    //             }
    //         }
    //     });
    //
    //     source.onmessage = (event) => {
    //         console.log(event);
    //         if (event.name === 'UploadInfo') {
    //             console.log(event.data);
    //         }
    //     };
    //
    //     source.onerror = (error) => {
    //         console.error("Error occurred:", error);
    //         source.close();
    //     };
    // }
}

export default new DataSseStream();