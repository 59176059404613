export default {
  "topbar.appname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icarus"])},
  "leftmenu.documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "leftmenu.people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
  "leftmenu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators"])},
  "leftmenu.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "leftmenu.people.performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People performance"])},
  "leftmenu.people.performance.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests completed"])},
  "leftmenu.people.performance.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files uploaded"])},
  "leftmenu.people.performance.receivedfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files received"])},
  "leftmenu.people.performance.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies assigned"])},
  "leftmenu.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "leftmenu.clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "leftmenu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "leftmenu.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "leftmenu.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "leftmenu.client.downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded by "])},
  "downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded"])},
  "premium.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium user"])},
  "free.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free user"])},
  "upgrade.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade available"])},
  "client.downloaded.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["downloaded by a person assigned to this company."])},
  "wasnot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was not"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "leftmenu.downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To download"])},
  "leftmenu.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "leftmenu.uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
  "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading files"])},
  "leftmenu.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
  "leftmenu.invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
  "leftmenu.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a company by its name or CUI"])},
  "overview.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsolved requests"])},
  "leftmenu.requests.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your requests"])},
  "leftmenu.invoice.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your invoices"])},
  "preview.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is an error with the preview of this file content. In order to access it please download it first."])},
  "preview.error.size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size is too large for preview."])},
  "overview.companies.tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies by TVA"])},
  "overview.companies.notva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No TVA"])},
  "overview.companies.withtva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With TVA"])},
  "requests.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view requests"])},
  "requests.toresolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" requests to resolve"])},
  "requests.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed requests"])},
  "requests.todo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests todo"])},
  "files.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files in application"])},
  "files.mb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum file size in megabytes"])},
  "clients.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "downloads.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view files ready for download"])},
  "downloads.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" files to download"])},
  "filters.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "filters.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "filters.selectall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "filters.uploadmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Month"])},
  "filters.resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Resolved"])},
  "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
  "nodata.people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No person available"])},
  "nodata.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No company available"])},
  "servererror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error on the server side."])},
  "downloadpdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file as PDF"])},
  "downloadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file"])},
  "resolvefile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark the file as resolved, hiding it from this view"])},
  "resolverequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark the request as resolved, hiding it from this view"])},
  "showresolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the files marked as Resolved"])},
  "showallmonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show items from all of the months"])},
  "searchinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by any information found within the table"])},
  "statusinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by statuses. You can filter with multiple statuses at the same time."])},
  "priorityinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by priorities. You can filter with multiple priorities at the same time."])},
  "companyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by companies. You can filter with multiple companies at the same time."])},
  "monthinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by the month of upload. This will show only files uploaded in that month."])},
  "themeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change between light and dark mode"])},
  "norequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No request available"])},
  "noinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No invoice available"])},
  "noproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product available"])},
  "products.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available products "])},
  "support.mail.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icarus Support Ticket"])},
  "support.mail.body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill information below with the problem you encountered."])},
  "support.mail.bodySubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wish to subscribe to the following subscription "])},
  "metrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrics"])},
  "nocompany.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot upload files before being associated to a company."])},
  "nocompany.requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot manage requests before being associated to a company."])},
  "nocompany.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add a new company from \"Companies\" screen."])},
  "subdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription details"])},
  "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "request2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "finnish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request support"])},
  "tutorial.restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart tutorial"])},
  "tutorial.invoices.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesta este ecranul unde puteți efectua gestiunea facturilor. De aici puteți genera, modifica și șterge facturi pentru firmele dumneavoastră. Pentru a putea adăuga produse într-o factuă este necesară crearea acestora în secțiunea de gestiune a produselor."])},
  "tutorial.invoices.buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru a crea o factură sau pentru a gestiona produsele unei firme puteți folosi unul dintre aceste butoane. Cel din dreapta vă permite gestiunea produselor companiei selectate. După introducerea unor produse, puteți crea o factură și să adăugați produsele create folosind butonul din stânga."])},
  "tutorial.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to skip the tutorial? You will be able to restart it from the setting view."])},
  "tutorial.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Icarus Docs! This tutorial has been designed to assist you in becoming acquainted with the application. At the top of the application, you find the button for support, settings and details about your user account."])},
  "tutorial.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Icarus Docs home page. This is the control center for efficient document management. This is where you can find information regarding your recent activity, your plan and metrics."])},
  "tutorial.download.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the file download page. You can use these filters to get the files you're looking for easily."])},
  "tutorial.download.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The available files are shown in this table. All of the files that you have received from your clients or collaborators can be viewed, downloaded, and converted into PDF format from here. Your uploaded files won't show up in this table."])},
  "tutorial.upload.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page allows you to upload files. You can upload files for a particular business. The files are accessible to all users assigned to this business through their own Downloads page."])},
  "tutorial.requests.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the request management page. Here you can create a request for a file or information from your clients or collaborators. To add information, files or to solve a request, click on one of them."])},
  "tutorial.requests.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the request details panel. You can get to it by clicking on one of the requests in the table from the previous step. In this screen you will find more details of the request, you can add files and relevant information. Files uploaded here can also be viewed later on the Uploads screen."])},
  "tutorial.people.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the people management page. Here you can add, modify and delete users. You can also connect people and companies here, by dragging one of the companies over the desired person. You can view and delete people's links by clicking on them."])},
  "tutorial.companies.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the company management page. Here you can add, modify and delete companies. These companies will then be available to upload files and associate with your collaborators. In the lower-left corner, you can select the company where you want to upload files and applications."])},
  "tutorial.companies.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will be able to select the company where you want to upload files or create requests for. This selection will be available when you have multiple companies associated."])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "companies.assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned companies"])},
  "companies.unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available companies"])},
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color theme"])},
  "register.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "register.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "register.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "register.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "register.mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of the registration process you will receive an email to set a password."])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
  "email.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email address already exists in Icarus. Please change its password or contact us."])},
  "email.wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address does not exist in the application."])},
  "email.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A confirmation email was sent to the address."])},
  "successemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent successfully."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "passwordnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "password.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
  "passwordchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "passwordnewtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a new password"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "companyCUI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company CUI"])},
  "companyRegistry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry Number"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "registryNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry Number"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "uploaddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload date"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "mycompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My company"])},
  "uploadby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded by"])},
  "creationTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified at"])},
  "lastmodified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last modified"])},
  "previewfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview and print the file"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "deletefile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this file"])},
  "addrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new request"])},
  "addinvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new invoice"])},
  "manageproducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage products for the selected company"])},
  "deleterequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this request"])},
  "editrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this request"])},
  "addcompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new company"])},
  "tvacompanyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This check indicates if this company pays TVA"])},
  "_dragcompanyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag me on top of a user to assign me to him."])},
  "deletecompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this company"])},
  "editcompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this company"])},
  "adduser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new user"])},
  "deleteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this user"])},
  "edituser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this user"])},
  "removecompanyassociation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the association between the user and this company. This will not delete the company itself."])},
  "clickuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click a user to show the companies associated with it."])},
  "clickrequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click a request to show its details and the files associated with it."])},
  "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])},
  "resolved.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved by"])},
  "resolved.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved date"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "assignme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign me to this request"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
  "directories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directories"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "nofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file selected."])},
  "directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directory"])},
  "directory.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a directory by its name"])},
  "directory.nofile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no files in the selected directory."])},
  "directory.addfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add a file use right click and select the desired option. Also you can drag and drop your files directly on top of this window."])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" for "])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome, "])},
  "fileorfiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File(s)"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "employee2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
  "employees.clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees and clients"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["form"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "was": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" was "])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" uploaded"])},
  "companyassoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company association"])},
  "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" started"])},
  "started2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" started"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" created"])},
  "created2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" created"])},
  "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" edited"])},
  "edited2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" edited"])},
  "withsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" with success"])},
  "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleted"])},
  "deleted2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleted"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "myCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
  "daysleft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days left"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "anywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anywhere"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "priorities.low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "priorities.normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
  "priorities.high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "priorities.critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical"])},
  "statuses.inprogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "statuses.underdev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature is under development."])},
  "statuses.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "statuses.blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
  "statuses.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
  "paymentDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment days"])},
  "dateDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment due"])},
  "atPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" at purchase"])},
  "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
  "assignedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to"])},
  "user.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details"])},
  "user.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is something wrong with your details please press the Request Assistance button."])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade subscription"])},
  "subscription.upgrade.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription upgraded successfully."])},
  "subscription.upgrade.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment will be automatically done from your registered card. Are you sure you want to continue?"])},
  "subscription.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a beta testing code, please enter it here:"])},
  "subscription.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel subscription"])},
  "subscription.beta.wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid beta code provided."])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew subscription"])},
  "subscription.cancel.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are going to miss you"])},
  "subscription.cancel.confirm.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription will still be available until the expiration date."])},
  "subscription.cancel.confirm.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel your subscription for Icarus?"])},
  "subscription.fail.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction failed"])},
  "subscription.fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription was not registered"])},
  "subscription.fail.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this was a mistake, please go back to the registration and retry the process or "])},
  "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "setting.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive email notifications"])},
  "subscription.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription registered successfully!"])},
  "subscription.success.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icarus team wants to thank you for the trust you have in us and hopes you will be more productive using our solution"])},
  "subscription.success.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to continue with the login process please confirm your account with the email you provided."])},
  "subscription.onlydownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can only download files"])},
  "subscription.allowall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can download, upload files and create requests"])},
  "subscription.totalFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Gigabytes total cloud space"])},
  "subscription.maxFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Megabytes maximum file size"])},
  "subscription.maxUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" users number"])},
  "subscription.filelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Maximum 100 documents"])},
  "subscription.maxCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" companies number"])},
  "subscription.nolimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No "])},
  "subscription.name.Tier4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporation"])},
  "subscription.name.Tier3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big Company"])},
  "subscription.name.Tier2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium Company"])},
  "subscription.name.Tier1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small Company"])},
  "subscription.name.FreeEmployee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Tier"])},
  "subscription.name.Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Tier"])},
  "subscription.space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" gigabytes used"])},
  "subscription.notavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access this feature please subscribe."])},
  "subscription.error.largefile1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To upload files larger than "])},
  "subscription.error.largefile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" megabytes, subscribe to a higher level plan."])},
  "subscription.error.people1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add more than "])},
  "subscription.error.people2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" employees, subscribe to a higher level plan."])},
  "subscription.error.people3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only add more clients as you reached your limit of "])},
  "subscription.error.companies2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" companies, subscribe to a higher level plan."])},
  "data.upload.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported file types for data upload are CSV and XLSX (Excel). In order for data upload to work please make sure your columns are in the following order: "])},
  "data.upload.tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA(0 if company doesn't have TVA, 1 otherwise)"])},
  "data.upload.click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click me to upload a file"])},
  "data.upload.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this to upload a file with "])},
  "data.upload.nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file to view its data"])},
  "data.upload.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data successfully uploaded!"])},
  "email.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required."])},
  "email.valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must be valid."])},
  "pass.length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum number of characters is 12."])},
  "rule.cui.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUI value was not found in the database. Please enter the data manually."])},
  "rule.cui.server.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that ANAF servers are currently down. Please try again later."])},
  "rule.cui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must contain between 1 and 9 digits."])},
  "rule.registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must follow this format: Jxx/xxxx/xxxx where x represents a digit."])},
  "rule.iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must contain between 15 and 33 digits."])},
  "rule.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a valid phone number."])},
  "rule.max20Chars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must have at most 50 characters."])},
  "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password does not met minimum criteria."])},
  "listview.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will display the files in a table view making it easier to see all your files"])},
  "gridview.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will display the files in a folder view for a more familiar file system experience"])},
  "downloadall.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this button to download all the files contained in this view. Files will be downloaded based on the filters applied."])},
  "downloadall.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to download multiple files please provide the correct filters. No file was selected for download."])},
  "form.validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please validate the form."])},
  "form.company.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company already exists in the application."])},
  "companies.add.obs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obs: Adding a new company will also create a new user with Client role assigned to it."])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "pass.min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 12 characters"])},
  "pass.symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 symbol"])},
  "pass.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 digit"])},
  "pass.uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 uppercase character"])},
  "pass.lowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 lowercase character"])},
  "pass.match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
  "more.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multiple "])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "user2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "more.companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multiple "])},
  "loading.failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load data!"])},
  "documents.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the documents available for you"])},
  "upload.files.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files uploaded to company "])},
  "upload.files.dateinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and date "])},
  "upload.files.info.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view files for a different company, change the select value from bottom left corner."])},
  "upload.file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
  "upload.drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop anywhere on this screen to upload file(s)"])},
  "upload.request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the selected request"])},
  "upload.request.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will upload a new file to the company as well"])},
  "upload.request.info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and can be visible in the Uploads view"])},
  "upload.dropfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file "])},
  "upload.page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" on this page or click this panel to upload a file to company"])},
  "upload.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the selected company"])},
  "upload.extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["file types supported: pdf, txt, docx, xlsx, xml, csv, pptx, imagini, video, audio"])},
  "upload.extension.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload files with one of the valid file types: pdf, txt, docx, xlsx, xml, csv, pptx, imagini, video, audio"])},
  "upload.extension.invalid.get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid extension for the selected file. Valid file types are: pdf, txt, docx, xlsx, xml, csv, pptx, imagini, video, audio"])},
  "upload.size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum file size: "])},
  "upload.camera.scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan a document"])},
  "upload.camera.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that a document was identified."])},
  "upload.camera.capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take photo"])},
  "upload.camera.finalize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the document"])},
  "upload.camera.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload image"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U.M."])},
  "tvaType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA Type"])},
  "containsTva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains TVA"])},
  "products.available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available products"])},
  "products.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product is already in the list"])},
  "employee.exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A collaborator with the same email address already exists."])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "products.available.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this product to the current invoice."])},
  "login.register.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication has failed."])},
  "login.register.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account? Request one from"])},
  "login.invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email or password."])},
  "login.remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
  "login.forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "login.signin.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
  "login.signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "login.activate.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account activation was successful."])},
  "login.activate.fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token for account activation has expired. You can activate your account by resetting your password."])},
  "request.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new request"])},
  "invoices.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new invoice"])},
  "company.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use this functionality to auto complete the form fields based on the company CUI"])},
  "company.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new company"])},
  "company.new.cui.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company's CUI and press the button from the right of the field in order to automatically complete its details."])},
  "employee.new.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new "])},
  "request.new.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This request will be sent to your collaborators to be resolved"])},
  "invoice.new.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invoice will be sent to your clients"])},
  "invoices.products.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage products "])},
  "invoices.products.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This products will be available for the invoices of the selected company"])},
  "company.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this company?"])},
  "employee.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this employee?"])},
  "request.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this request?"])},
  "products.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this product?"])},
  "subscribe.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this subscription?"])},
  "subscribe.delete.confirmation.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will continue to be active until the time expires"])},
  "file.delete.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this file? This action is irreversible and your file will be deleted from the cloud."])},
  "folder.create.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set a name for your new directory"])},
  "folder.home.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My documents"])},
  "folder.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the directory containing your documents"])},
  "folder.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the folder to move into"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
  "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "newfolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Folder"])},
  "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload File"])},
  "uploadfolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Folder"])},
  "foldercreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder created successfully!"])},
  "companyselect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a company by double clicking it before uploading a document."])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste"])},
  "folder.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current location"])},
  "folder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a directory to show the path."])},
  "folder.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directory name"])},
  "peoplemanager.companies.associated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user doesn't have any companies associated yet!"])},
  "peoplemanager.companies.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can drag companies on top of your employees in order to assign them"])},
  "peoplemanager.companies.message.mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company assign functionality is not working on mobile. Please use another device to assign companies."])},
  "peoplemanager.companies.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that you have no companies associated to you. Go to Companies screen and add a few!"])},
  "screenselect.download.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the files that are ready for download"])},
  "screenselect.upload.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload files to the drive"])},
  "documents.upload.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload files or directories to cloud"])},
  "screenselect.requests.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check and resolved requests assigned to you"])}
}