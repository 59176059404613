<template>
  <v-card :class="mq.current === 'mobile' ? 'add-button-container-mobile' : 'add-button-container'" variant="tonal" color="secondary" :style="cart.isDark ? 'color: white !important;' : ''">
    <tooltip-message :message="tooltipMessage"/>
    <div style="display: grid;">
      <v-card-title>
        <v-icon :style="mq.current !== 'mobile' ? 'margin-right: 20px;' : 'font-size: 20px;'">{{ icon }}</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text style="font-weight: 500; padding-bottom: 10px; font-size: 16px;">{{ tooltipCompany }}</v-card-text>
      <v-card-text v-if="text && mq.current !== 'mobile'" style="padding-top: 0;">{{ text }}</v-card-text>
    </div>
  </v-card>
</template>

<script setup>
import TooltipMessage from "@/components/mini/Tooltip";
import { cart } from '@/services/cart';
</script>

<script>
export default {
  name: "AddCard",
  inject: ['mq'],
  props: {
    tooltipMessage: String,
    tooltipCompany: String,
    icon: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
  }
}
</script>

<style scoped>
.add-button-container {
  margin: 0 calc(50% - 250px);
  width: 500px;
  padding: 0 20px;
  text-align: center;
}

.add-button-container-mobile {
  margin: 0 5%;
  width: 90%;
  font-size: small;
}

</style>