<template>
  <div class="login">
    <LoginForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/Login.vue'

export default {
  name: 'LoginView',
  components: {
    LoginForm
  }
}
</script>
