import {cart} from "@/services/cart";
import i18n from "@/i18n";
import mammoth from "mammoth";
import {read, utils} from "xlsx";
import FileService from "@/services/FileService";
import Quill from "quill";

class PreviewService {

    previewFile(item) {
        cart.value.previewLoading = true;
        cart.value.filePreviewDialog = true;
        cart.value.previewFileSrc = "";
        cart.value.previewFileSrcDoc = null;
        let filename = item.filename ? item.filename : item.title;
        if (!filename) {
            this.catchPreviewException();
        }
        const fileDetails = this.getFileDetails(filename);

        if (fileDetails.type === 'other') {
            cart.value.previewPrintButton = true;
            if (item.fileSize > FileService.REQUEST_MB_LIMIT) {
                this.tooLargeException();
                return;
            }
            return FileService.getFileAsPdf(item.id).then(file => {
                this.setFileSrc(file, fileDetails)
            }).catch(e => {
                this.catchPreviewException();
            })
        } else {
            cart.value.previewPrintButton = false;
            return FileService.getFileById(item).then(response => {
                let file = item;
                file.data = FileService.checkAndGetLargerFile(item, response);
                let filesize = item.data.fileSize ? item.data.fileSize : file.data.fileSize;
                this.setFileSrc(file, fileDetails, filesize < FileService.REQUEST_MB_LIMIT);
            }).catch(e => {
                this.catchPreviewException();
            });
        }
    }

    setFileSrc(file, fileDetails, convertFromBase64 = true) {
        if (!file || !file.data) {
            cart.value.showAlertDetails(i18n.global.t('upload.extension.invalid.get'));
            cart.value.filePreviewDialog = false;
            this.filePreviewDialog = false;
            return;
        }
        cart.value.previewLoading = false;
        let previewItem = this.previewItem(file, fileDetails.mimeType, convertFromBase64);
        if (fileDetails.type === 'word') {
            mammoth.convertToHtml({arrayBuffer: previewItem.byteArray}).then(result => {
                cart.value.previewFileSrcDoc = result.value;
                // this.initializeEditableHtml(cart.value.previewFileSrcDoc);
            });
        } else if (fileDetails.type === 'excel') {
            const workbook = read(previewItem.byteArray, { type: 'array' });
            cart.value.previewFileSrcDoc = utils.sheet_to_html(Object.values(workbook.Sheets)[0], {bookType: 'html', MimeType: 'text/html', editable: false});
            addExcelStyles();
            // this.initializeEditableHtml(cart.value.previewFileSrcDoc);
        } else {
            cart.value.previewFileSrc = previewItem.url;
        }
    }

    initializeEditableHtml(html) {
        if (!this.quill) {
            this.quill = new Quill("#editor-container", {
                theme: 'snow',
            });
        }
        // console.log(html);
        // console.log(cart.value.previewFileSrcDoc);
        document.getElementById("editor-container").innerHTML = html;
        // document.getElementById("editor-container").innerHTML = 'asd';
        // console.log(document.getElementById("editor-container"));
    }

    previewItem(item, mimeType, convertFromBase64 = true) {
        if (!item.data.fileBytes) {
            cart.value.showAlertDetails(i18n.global.t('preview.error'))
            return;
        }
        let byteArray = item.data.fileBytes;
        if (convertFromBase64) {
            // Create a Blob object from the base64-encoded data
            let byteCharacters = atob(item.data.fileBytes);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            byteArray = new Uint8Array(byteNumbers);
        }
        const pdfBlob = new Blob([byteArray], {type: mimeType});

        // Create an object URL for the Blob
        return {'byteArray': byteArray, 'blob': pdfBlob, 'url': URL.createObjectURL(pdfBlob)};
    }

    tooLargeException() {
        cart.value.previewLoading = false;
        cart.value.filePreviewDialog = false;
        cart.value.showAlertDetails(i18n.global.t('preview.error.size'));
    }

    catchPreviewException() {
        cart.value.previewLoading = false;
        cart.value.filePreviewDialog = false;
        cart.value.showAlertDetails(i18n.global.t('upload.extension.invalid.get'));
    }

    getFileDetails(filename) {
        let type = 'other';
        let fileType = 'application/pdf';

        if (isVideo(filename)) {
            type = 'video';
            fileType = "video/mp4";
        }
        if (isAudio(filename)) {
            type = 'audio';
            fileType = 'audio/mp3';
        }
        if (isImage(filename)) {
            type = 'image';
            fileType = "image/jpeg";
        }
        if (isSvg(filename)) {
            type = 'svg';
            fileType = "image/svg+xml";
        }
        if (isWord(filename)) {
            type = 'word';
            fileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        }
        if (isExcel(filename)) {
            type = 'excel';
            fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        }
        // if (isPpt(filename)) {
        //     type = 'ppt';
        //     fileType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        // }
        return {'type': type, 'mimeType': fileType};
    }
}

export default new PreviewService();

function addExcelStyles() {
    let orig = cart.value.previewFileSrcDoc;
    let styles = `
        <style>
            table, tr:first-child {
              border: 1px solid darkgray;
              border-collapse: collapse;
            }
            table, tr:first-child td {
              border-left: 1px solid darkgray;
              border-right: 1px solid darkgray;
              padding: 5px;
            }
            table, tr {
              border: 1px solid lightgray;
              border-collapse: collapse;
            }
            table, td {
              border-left: 1px solid lightgray;
              border-right: 1px solid lightgray;
              padding: 5px;
            }
        </style>`;
    let xlsxWithStyles = orig.substring(0, 12)
        + styles + orig.substring(12);
    cart.value.previewFileSrcDoc = xlsxWithStyles;
}

function isVideo(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let videoExtension = ['mp4', 'mov'];
    return videoExtension.includes(extension);
}

function isAudio(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let audioExtensions = ['mp3', 'aac', 'wav', 'wma', 'flac', 'm4a'];
    return audioExtensions.includes(extension);
}

function isImage(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let imageExtension = ['png', 'jpg', 'jpeg', 'tiff', 'gif'];
    return imageExtension.includes(extension);
}

function isSvg(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let imageExtension = ['svg'];
    return imageExtension.includes(extension);
}

function isWord(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let officeExtension = ['doc', 'docx'];
    return officeExtension.includes(extension);
}

function isExcel(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let officeExtension = ['xls', 'xlsx'];
    return officeExtension.includes(extension);
}

function isPpt(filename) {
    let extension = filename.substring(filename.lastIndexOf('.') + 1);
    let officeExtension = ['ppt', 'pptx'];
    return officeExtension.includes(extension);
}