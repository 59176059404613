<template>
  <v-card height="100%" style="padding: 15px 20px;">
    <div :style="mq.current !== 'mobile' ? 'display: flex;' : 'display: block;'">
      <v-col :cols="mq.current !== 'mobile' ? 6 : 12">
        <span style="color: darkorange; margin-bottom: 30px; font-size: 15px;">
          {{ $t('data.upload.info') }}
          <b>{{ getColumnOrderString() }}</b>
        </span>
      </v-col>
      <v-col cols="4">
        <v-file-input :label="$t('data.upload.click')" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      v-model="chosenFile" @update:modelValue="getFileData"
                      density="compact" style="height: 40px;" variant="outlined"></v-file-input>
      </v-col>
      <v-col cols="2" v-if="mq.current !== 'mobile'">
        <v-btn @click="downloadTemplate" prepend-icon="mdi-download">Template</v-btn>
      </v-col>
    </div>
    <v-data-table
        :headers="itemHeaders"
        :items="items"
        density="compact"
        :loading="loading"
    >
      <template v-slot:no-data>
        <span>{{ $t('data.upload.nodata') }}</span>
      </template>
      <template v-slot:[`item.tva`]="{ item }">
        <v-checkbox v-model="item.tva" disabled="" hide-details density="compact">
        </v-checkbox>
      </template>
    </v-data-table>
<!--    TODO: Enable data upload-->
    <v-btn @click="uploadFileData" prepend-icon="mdi-upload" height="40px" v-if="items.length" style="margin: 20px calc(50% - 100px); width: 200px;">{{ $t('upload') }}</v-btn>
  </v-card>
</template>
<script setup>

</script>

<script>
import {cart} from "@/services/cart";
import i18n from "@/i18n";
import UserService from "@/services/UserService";
import CompanyService from "@/services/CompanyService";

export default {
  name: "DataFileUpload",
  props: {
    dataType: String
  },
  inject: ['mq'],
  data: () => ({
    chosenFile: null,
    loading: false,
    itemHeaders: [],
    items: [],
  }),
  methods: {
    getFileData() {
      if (!this.chosenFile) {
        cart.value.showAlertDetails(i18n.global.t('nofile'));
        return;
      } else if (!this.chosenFile[0]) {
        this.setItems([]);
        return;
      }
      this.loading = true;
      if (this.dataType === 'users') {
        UserService.previewFileData(this.chosenFile[0], UserService.getLoggedUser().id).then(items => {
          this.setHeaders(items.data[0]);
          this.setItems(items.data);
          this.loading = false;
        }).catch(() => {
          cart.value.showAlertDetails("Datele din fișier nu au putut fi interpretate.")
        });
      } else if (this.dataType === 'companies') {
        CompanyService.previewFileData(this.chosenFile[0], UserService.getLoggedUser().id).then(items => {
          this.setHeaders(items.data[0]);
          this.setItems(items.data);
          this.loading = false;
        }).catch(() => {
          cart.value.showAlertDetails("Datele din fișier nu au putut fi interpretate.")
        });
      } else {
        this.loading = false;
      }
    },
    uploadFileData() {
      this.loading = true;
      if (this.dataType === 'users') {
        UserService.uploadFileData(this.items, UserService.getLoggedUser().id).then((users) => {
          cart.value.showDataUpload = false;
          this.loading = false;
          users.data.forEach((user) => cart.value.employees.push(user))
          cart.value.showAlertDetails(i18n.global.t('data.upload.success'))
        });
      } else if (this.dataType === 'companies') {
        CompanyService.uploadFileData(this.items, UserService.getLoggedUser().id).then((companies) => {
          cart.value.showDataUpload = false;
          this.loading = false;
          companies.data.forEach((company) => cart.value.companies.push(company))
          cart.value.showAlertDetails(i18n.global.t('data.upload.success'))
        });
      }
    },
    setHeaders(item) {
      const keys = Object.keys(item);
      this.itemHeaders = [];
      keys.forEach(key => {
        if (key === 'id' || key === 'hadTutorial' || key === 'admin' || item[key] === null) return;
        this.itemHeaders.push({
          key: key,
          title: i18n.global.t(key),
          align: 'start',
        })
      });
    },
    setItems(items) {
      this.items = items;
    },
    getColumnOrderString() {
      let columnOrder = i18n.global.t('name') + ", ";
      if (this.dataType === 'users') {
        columnOrder += 'Email, ' + i18n.global.t('role')
      } else if (this.dataType === 'companies') {
        columnOrder += i18n.global.t('address') + ", Administrator, " + "cui, " + i18n.global.t('registryNo') + ", " + i18n.global.t('data.upload.tva')
      }
      return columnOrder;
    },
    downloadTemplate() {
      if (this.dataType === 'users') {
        UserService.downloadFileTemplate();
      } else if (this.dataType === 'companies') {
        CompanyService.downloadFileTemplate();
      }
    }
  }
}
</script>

<style scoped>

</style>