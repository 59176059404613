<template>
  <div class="admin-download content-page">
    <TopBar/>
    <LeftMenu/>
    <AdminDownload/>
    <TutorialOverlay/>
  </div>
</template>

<script>
// @ is an alias to /src
import AdminDownload from '@/components/fileComponents/AdminDownload.vue'
import TopBar from '@/components/TopBar.vue'
import LeftMenu from "@/components/LeftMenu";
import TutorialOverlay from "@/components/TutorialOverlay";

export default {
  name: 'AdminDownloadView',
  components: {
    TutorialOverlay,
    LeftMenu,
    TopBar,
    AdminDownload,
  }
}
</script>
