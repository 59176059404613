<template>
  <div :class="mq.current === 'mobile' ? 'settings-container-mobile' : 'settings-container'" class="content-page-main">
    <div :class="mq.current === 'mobile' ? 'screen-select-buttons-container-mobile' : 'screen-select-buttons-container'">
      <v-expansion-panels v-model="subPanel" multiple>
        <v-expansion-panel :title="$t('mycompany')">
          <v-expansion-panel-text>
             <div>
               <company-form :item="defaultCompany"
                             submitType="myCompany" :style="mq.current === 'mobile' ? '' : 'width: 700px;'"/>
               <div style="display: none;">
                 <v-file-input :label="'Logo'" style="max-width: 150px;height: 40px;" prepend-icon="mdi-camera"
                               accept="image/png, image/jpeg, image/bmp, image/svg" @update:modelValue="uploadIcon"
                               density="compact" variant="outlined" :readonly="subType === 'FreeEmployee'"></v-file-input>
                 <v-img style="max-width: 100px; margin-left: 20px;" :src="cart.logo"></v-img>
                 <v-spacer></v-spacer>
               </div>
             </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel :title="$t('subscriptions')" v-if="mq.current !== 'mobile' && subType !== 'FreeEmployee'" hidden>
          <v-expansion-panel-text>
             <div style="display: flex;">
                <div v-for="(subscription, index) in displaySubs" v-bind:key="subscription.id" style="width: -webkit-fill-available;">
                  <v-card
                      class="mx-auto screen-select-card"
                      variant="outlined"
                      :elevation="4"
                      :style="index === 4 ? 'margin-right: 0 !important;' : ''"
                      :color="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id ? 'secondary' : ''"
                  >
                    <v-card-item>
                      <div class="account-card-header"></div>
                      <div class="center-text" style="margin-top: 10px;">
                        <v-icon class="account-card-image" :color="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id ? 'white' : ''">{{ getIcon(subscription) }}</v-icon>
                      </div>
                      <div class="card-text-details">
                        <div class="mb-1 center-text card-title">
                          <span style="font-size: large">{{ $t('subscription.name.' + subscription.subscriptionType) }}</span>
                        </div>
                        <div v-if="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id">
                          <div class="text-caption-custom center-text ">{{ $t('active') }} {{ cart.selectedSubscription.expirationTime ? $t('until') + ' ' + cart.selectedSubscription.expirationTime : ''}}</div>
                          <div class="text-caption-custom center-text "><b>{{ currentSize }} </b> {{ $t('subscription.space')}}</div>
                        </div>
                        <div class="center-text card-text"><b><!--{{ subscription.price }}-->##</b>€ / {{ $t('month') }}</div>
                        <div class="text-caption-custom center-text card-info-text">{{ subscription.subscriptionType == 'FreeEmployee' ? $t('subscription.onlydownload') : $t('subscription.allowall') }}</div>
                        <div class="text-caption-custom center-text card-info-text">
                          <b>{{ subscription.totalFileSize == '5000' ? $t('subscription.nolimit') : subscription.totalFileSize }}</b>{{ $t('subscription.totalFileSize') }}</div>
                        <div class="text-caption-custom center-text card-info-text">
                          <b>{{ subscription.maxFileSize == '10000' ? $t('subscription.nolimit') : subscription.maxFileSize }}</b>{{ $t('subscription.maxFileSize') }}</div>
                        <div class="text-caption-custom center-text card-info-text">
                          <b>{{ subscription.maxUsers == '1000' ? $t('subscription.nolimit') : subscription.maxUsers }}</b>{{ $t('subscription.maxUsers')}}</div>
                        <div class="text-caption-custom center-text card-info-text">
                          <b>{{ subscription.maxCompanies == '10000' ? $t('subscription.nolimit') : subscription.maxCompanies }}</b>{{ $t('subscription.maxCompanies') }}</div>
                        <div class="spacer">
                        </div>
                      </div>
                    </v-card-item>
                    <v-card-actions>
                      <v-btn v-if="(!cart.selectedSubscription || cart.selectedSubscription.id !== subscription.id) && !isFree" color="secondary" @click="requestSupport(subscription)" style="margin: 0 auto;" variant="elevated">
                        {{ $t('support').substring(0, $t('support').indexOf(' ')) }}
                      </v-btn>
<!--                      <v-btn v-if="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id" @click="deleteDialog = true; subToDelete = subscription" style="margin: 0 auto;" variant="elevated">-->
<!--                        {{ $t('cancel') }}-->
<!--                      </v-btn>-->
                    </v-card-actions>
                  </v-card>
                </div>
              </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel :title="$t('user.details')">
          <v-expansion-panel-text style="padding: 10px 0;">
            <form>
              <v-row>
                <v-col cols="1" style="margin: 10px 0;"><span>{{ $t('name') }}</span></v-col>
                <v-col :cols="mq.current !== 'mobile' ? 2 : 8" :style="mq.current === 'mobile' ? 'margin-left: 50px;' : ''">
                  <v-text-field
                      v-model="user.name"
                      readonly
                      variant="outlined"
                      density="compact"
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" style="margin: 10px 0;"><span>Email</span></v-col>
                <v-col :cols="mq.current !== 'mobile' ? 2 : 8" :style="mq.current === 'mobile' ? 'margin-left: 50px;' : ''">
                  <v-text-field
                      v-model="user.email"
                      readonly
                      variant="outlined"
                      density="compact"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" style="margin: 10px 0;"><span>{{ $t('telephone') }}</span></v-col>
                <v-col :cols="mq.current !== 'mobile' ? 2 : 8" :style="mq.current === 'mobile' ? 'margin-left: 50px;' : ''">
                  <v-text-field
                      v-model="user.phone"
                      :rules="[phoneRule]"
                      variant="outlined"
                      density="compact"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="display: none;">
                <v-col cols="1" style="margin: 10px 0;"><span>{{ $t('leftmenu.people') }}</span></v-col>
                <v-col :cols="mq.current !== 'mobile' ? 2 : 6" :style="mq.current === 'mobile' ? 'margin-left: 90px;' : ''">
                  <v-text-field
                      v-model="cart.employees.filter(employee => employee.role === 'Accountant').length"
                      readonly
                      variant="outlined"
                      density="compact"
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row  style="display: none;">
                <v-col cols="1" style="margin: 10px 0;"><span>{{ $t('leftmenu.clients') }}</span></v-col>
                <v-col :cols="mq.current !== 'mobile' ? 2 : 6" :style="mq.current === 'mobile' ? 'margin-left: 90px;' : ''">
                  <v-text-field
                      v-model="cart.employees.filter(employee => employee.role === 'Client').length"
                      readonly
                      variant="outlined"
                      density="compact"
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row  style="display: none;">
                <v-col cols="1" style="margin: 10px 0;"><span>{{ $t('leftmenu.companies') }}</span></v-col>
                <v-col :cols="mq.current !== 'mobile' ? 2 : 6" :style="mq.current === 'mobile' ? 'margin-left: 90px;' : ''">
                  <v-text-field
                      v-model="cart.companies.length"
                      readonly
                      variant="outlined"
                      density="compact"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn @click="passwordReset()" style="margin-top: 20px;">{{ $t('passwordchange') }}</v-btn>
            </form>
            <span style="font-size: small;">{{ $t('user.help') }}</span>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel :title="$t('leftmenu.settings')">
          <v-expansion-panel-text>
            <v-form>
              <v-checkbox color="primary" v-model="getEmailNotification"
                          @update:modelValue="editEmailNotifications"
                          :label="$t('setting.email')"
                          hide-details density="compact" ></v-checkbox>

              <div style="display: flex;">
                <span style="padding-top: 12px;">{{ $t('language') + ": " }}</span>
                <locale-switcher style="margin-left: 10px;"></locale-switcher>
              </div>
              <div style="display: flex;">
                <span style="padding-top: 12px;">{{ $t('theme') + ": " }}</span>
                <theme-switcher style="margin-left: 10px;"/>
              </div>
            </v-form>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <div style="display: none; margin-top: 20px;" v-if="mq.current === 'mobile'" hidden>
        <div v-for="(subscription, index) in displaySubs" v-bind:key="subscription.id" style="width: -webkit-fill-available;">
          <v-card
              class="mx-auto screen-select-card"
              variant="outlined"
              :elevation="4"
              :style="index === 4 ? 'margin-right: 0 !important;' : ''"
              :color="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id ? 'secondary' : ''"
          >
            <v-card-item>
              <div class="account-card-header"></div>
              <div class="center-text" style="margin-top: 10px;">
                <v-icon class="account-card-image" :color="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id ? 'white' : ''">{{ getIcon(subscription) }}</v-icon>
              </div>
              <div class="card-text-details">
                <div class="mb-1 center-text card-title">
                  <span style="font-size: large">{{ $t('subscription.name.' + subscription.subscriptionType) }}</span>
                </div>
                <div v-if="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id">
                  <div class="text-caption-custom center-text ">{{ $t('active') }} {{ cart.selectedSubscription.expirationTime ? $t('until') + ' ' + cart.selectedSubscription.expirationTime : ''}}</div>
                  <div class="text-caption-custom center-text "><b>{{ currentSize }} </b> {{ $t('subscription.space')}}</div>
                </div>
                <div class="center-text card-text"><b><!--{{ subscription.price }}-->##</b>€ / {{ $t('month') }}</div>
                <div class="text-caption-custom center-text card-info-text">{{ subscription.subscriptionType == 'FreeEmployee' ? $t('subscription.onlydownload') : $t('subscription.allowall') }}</div>
                <div class="text-caption-custom center-text card-info-text">
                  <b>{{ subscription.totalFileSize == '5000' ? $t('subscription.nolimit') : subscription.totalFileSize }}</b>{{ $t('subscription.totalFileSize') }}</div>
                <div class="text-caption-custom center-text card-info-text">
                  <b>{{ subscription.maxFileSize == '10000' ? $t('subscription.nolimit') : subscription.maxFileSize }}</b>{{ $t('subscription.maxFileSize') }}</div>
                <div class="text-caption-custom center-text card-info-text">
                  <b>{{ subscription.maxUsers == '1000' ? $t('subscription.nolimit') : subscription.maxUsers }}</b>{{ $t('subscription.maxUsers')}}</div>
                <div class="text-caption-custom center-text card-info-text">
                  <b>{{ subscription.maxCompanies == '10000' ? $t('subscription.nolimit') : subscription.maxCompanies }}</b>{{ $t('subscription.maxCompanies') }}</div>
                <div class="spacer">
                </div>
              </div>
            </v-card-item>
            <v-card-actions>
              <v-btn v-if="!cart.selectedSubscription || cart.selectedSubscription.id !== subscription.id" color="secondary" @click="requestSupport(subscription)" style="margin: 10px auto;" variant="elevated">
                {{ $t('support').substring(0, $t('support').indexOf(' ')) }}
              </v-btn>
<!--              <v-btn v-if="cart.selectedSubscription && cart.selectedSubscription.id === subscription.id" @click="deleteDialog = true; subToDelete = subscription" style="margin: 10px auto;" variant="elevated">-->
<!--                {{ $t('cancel') }}-->
<!--              </v-btn>-->
            </v-card-actions>
          </v-card>
        </div>
      </div>
      <div style="display: inline-grid;">
        <v-btn style="margin-top: 30px;" prepend-icon="mdi-school" @click="restartTutorial" color="primary">{{ $t('tutorial.restart') }}</v-btn>
        <v-btn style="margin: 30px 0 0 0;" prepend-icon="mdi-account-question" @click="requestSupport" color="primary" v-if="!isFree">{{ $t('support') }}</v-btn>
        <div :style="isFreeEmployee ? 'display: none;' : mq.current === 'mobile' ? 'display: grid;' : 'display: flex;'">
          <v-btn :style="mq.current === 'mobile' ? 'margin: 30px 0;' : 'margin: 30px 0 50px 0;'" prepend-icon="mdi-cancel" @click="deleteDialog = true" color="error">{{ $t('subscription.cancel') }}</v-btn>
<!--          <v-btn :style="mq.current === 'mobile' ? 'margin-bottom: 60px;' : 'margin: 30px 0 50px 10px;'" prepend-icon="mdi-autorenew" @click="renewSub" color="primary">{{ $t('subscription.renew') }}</v-btn>-->
          <v-btn :style="mq.current === 'mobile' ? 'margin-bottom: 60px;' : 'margin: 30px 0 50px 10px;'" prepend-icon="mdi-arrow-up-bold-circle-outline" @click="showUpgradeScreen = true" color="light-green-darken-1">{{ $t('request') }} Upgrade</v-btn>
        </div>
      </div>
    </div>
  </div>
  <v-dialog
      v-model="deleteDialog"
      max-width="500px"
      class="icarus-dialog"
  >
    <v-card>
      <div class="confirmation-header">
        <span size="big">{{ $t('subscription.cancel.confirm') }}</span>
      </div>
      <span style="text-align: center; margin: 10px 0;">{{ $t('subscription.cancel.confirm.message') }}</span>
      <span style="font-size: small;text-align: center; margin: 10px 0;">{{ $t('subscription.cancel.confirm.info') }}</span>
      <v-card-actions style="background: white;">
        <v-btn class="cancel-btn" style="background: #ECEFF1;" color="success" variant="tonal" @click="deleteDialog = false">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success-btn delete-btn" variant="tonal" @click="unsubscribe">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
      v-model="showUpgradeScreen"
      max-width="500px"
      class="icarus-dialog"
      persistent
  >
    <upgrade-subscription @upgradeDialog="showUpgradeScreen = false"/>
  </v-dialog>
</template>

<script setup>

import {cart} from "@/services/cart";
import {rules} from "@/services/rules";
import CompanyForm from "@/components/forms/CompanyForm";
import UpgradeSubscription from "@/components/mini/UpgradeSubscription";

</script>

<script>

import SubscriptionService from "@/services/SubscriptionService";
import UserService from "@/services/UserService";
import FileService from "@/services/FileService";
import $cookies from "vue-cookie";
import i18n from "@/i18n";
import router from "@/router";
import CompanyService from "@/services/CompanyService";
import SettingsService from "@/services/SettingsService";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import ThemeSwitcher from "@/components/mini/ThemeSwitcher";

export default {
  name: 'SettingsForm',
  inject: ['mq'],
  data: () => ({
    subPanel: [0, 2, 3],
    companyItem: {},
    deleteDialog: false,
    showUpgradeScreen: false,
    subToDelete: null,
    companySearch: "",
    user: {},
    phoneRule: rules.value.phone,
  }),
  mounted() {
    this.user = UserService.getLoggedUser();
    CompanyService.loadDefaultCompanyDb(this.user);
    UserService.getUserById(this.user.id).then(user => {
      this.user.name = user.data.firstname ? (user.data.firstname + " " + user.data.lastname) : user.data.name;
      this.user.phone = user.data.phone;
    })
    this.loadSettings();
  },
  computed: {
    getEmailNotification() {
      if (!cart.value.settings || cart.value.settings.length === 0) return false;
      const disableEmails = cart.value.settings.find(setting => setting.name === 'DisableEmailNotification');
      return disableEmails ? (disableEmails.value !== 'true') : true;
    },
    currentSize() {
      const size = FileService.getFilesSize();
      let currentSize = SubscriptionService.sizeToGb(size) + " gigabytes";
      if (size < SubscriptionService.sizeFromGb(1)) {
        currentSize = SubscriptionService.sizeToMb(size) + " megabytes";
      }
      return currentSize;
    },
    subType() {
      return UserService.getLoggedUser().subscriptionType;
    },
    displaySubs() {
      let displayedSubs = cart.value.subscriptions.slice(2).slice(0, -1);
      return displayedSubs;
    },
    filterCompanies() {
      if (this.companySearch.length > 1) {
        let items = cart.value.companies.filter(company => company.name.toLowerCase().indexOf(this.companySearch.toLowerCase()) > -1);
        if (items.length > 0)
          return items;
      }
      return cart.value.companies;
    },
    getCompanyItem() {
      let user = UserService.getUserByIdLocally(this.user.id);
      if (user.defaultCompanyId) {
        return CompanyService.getCompanyById(user.defaultCompanyId);
      }
      return {}
    },
    isFreeEmployee() {
      return SubscriptionService.isFreeEmployee()
    },
    isFree() {
      return SubscriptionService.isFreeUser()
    }
  },
  methods: {
    loadSettings() {
      SettingsService.getByUserId(this.user.id).then(settings => {
        if (settings.data.length === 0) {
          this.addSetting('DisableEmailNotification', false);
        }
        cart.value.settings = settings.data;
      });
    },
    // subscribe(sub) {
    //   const subLink = {userId: this.user.id, subscriptionId: sub.id}
    //   //Delete the old subscription if the user already had one
    //   this.setActiveSubscription(sub);
    //   if (cart.value.selectedSubscription) {
    //     SubscriptionService.edit(subLink).then(() => {
    //       // this.setActiveSubscription(sub);
    //     });
    //   } else {
    //     SubscriptionService.add(subLink).then(() => {
    //       // this.setActiveSubscription(sub);
    //     });
    //   }
    // },
    unsubscribe() {
      this.deleteDialog = false;
      console.log("Canceling subscription " + cart.value.selectedSubscription.subLinkId + " for user " + this.user.id);
      SubscriptionService.remove(this.user.id, cart.value.selectedSubscription.subLinkId).then(() => {
        // this.selectedSubscription = null;
      })
    },
    // setActiveSubscription(sub) {
    //   $cookies.set("user.subscriptionType", sub.subscriptionType);
    //   cart.value.selectedSubscription = cart.value.subscriptions.find(subscription => subscription.id === sub.id)
    // },
    getIcon(sub) {
      switch (sub.subscriptionType) {
        case 'FreeEmployee': case 'Free': return 'mdi-currency-eur-off'
        case 'Tier1': return 'mdi-home'
        case 'Tier2': return 'mdi-office-building'
        case 'Tier3': return 'mdi-domain'
        case 'Tier4': return 'mdi-home-group'
      }
      return 'mdi-account-key';
    },
    getFormattedDate(date) {
      return FileService.getFormattedDate(date);
    },
    requestSupport(subscription) {
      const supportAddress = "office@icarusdocs.com";
      const emailSub = i18n.global.t('support.mail.subject');
      let body = i18n.global.t('support.mail.body');
      // if (subscription) {
      //   body = i18n.global.t('support.mail.bodySubscribe') + subscription.name;
      // }
      window.open('mailto:' + supportAddress + '?subject=' + emailSub + '&body=' + body, '_self')
    },
    restartTutorial() {
      cart.value.startTutorial = true;
      window.scrollTo(0, 0);
      router.push('overview');
    },
    passwordReset() {
      UserService.changePasswordEmail(this.user.email).then(() => {
        cart.value.showAlertDetails(i18n.global.t('successemail'));
      });
    },
    uploadIcon(data) {
      // var files = data.target.files || data.dataTransfer.files;

      // if (!files.length) {
      //   return;
      // }

      // let file = FileService.dataURLtoFile(this.cameraImageData, this.cameraImageName + ".jpg");
      FileService.createFiles([data[0]], 'logo');
    },
    addSetting(name, value) {
      SettingsService.addSetting(name, value);
    },
    editSetting(name, value) {
      return SettingsService.editSetting(name, value);
    },
    editEmailNotifications(value) {
      const setting = cart.value.settings.find(setting => setting.name === 'DisableEmailNotification');
      const val = setting.value === 'true';
      this.editSetting('DisableEmailNotification', !val).then(setting => {
        this.loadSettings();
      });
    },
    renewSub() {
      this.user.role = 'Accountant';
      SubscriptionService.getByUserId(this.user.id).then(sub => {
        if (sub.data === null || sub.data === '') {
          console.log("Subscription not found for renewal for user " + this.user.id);
          return;
        }
        SubscriptionService.prepareData(this.user, cart.value.mycompany, sub.data).then(url => {
          this.loading = false;
          window.open(url.data.replaceAll(/\s/g,''));
        });
      });
    }
  }
}
</script>

<style scoped>

.settings-container {
  margin: 30px 30px 30px 230px;
  /*align-items: center;*/
  /*height: 90%;*/
  display: flex;
}

.settings-container-mobile {
  padding: 10px;
}

.screen-select-card {
  /*width: 95%;*/
  height: 560px;
  margin-right: 30px !important;
  /*height: 40%;*/
}

.center-text {
  text-align: center;
}

.card-text-details {
  height: 350px;
}

.account-card-image {
  border: 2px solid white;
  border-radius: 50px;
  background-color: rgb(var(--v-theme-secondary));
  width: 100px;
  height: 100px;
  font-size: 70px;
}

.account-card-header {
  position: absolute;
  /*background: linear-gradient(to right,  #756cec, #2e73ee, #756cec);*/
  background-image: linear-gradient(rgb(var(--v-theme-gradient1)), rgb(var(--v-theme-gradient2)), rgb(var(--v-theme-gradient1)));
  /*background-image: url("https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg");*/
  width: 100%;
  height: 80px;
  left: 0;
  top: 0;
}

.card-title {
  margin: 20px 0 20px 0;
}

.card-text {
  margin: 20px 0 20px 0;
}

.card-info-text {
  margin: 5px 0;
}

.screen-select-card.v-theme--mainDarkTheme {
  color: white;
  background-color: rgb(35, 35, 35);
  border: 2px solid gray;
}

.account-card-image.v-theme--mainDarkTheme {
  background-color: rgb(var(--v-theme-secondary));
  border: 2px solid white;
}

.screen-select-buttons-container {
  width: 100%;
}

.screen-select-buttons-container-mobile {
  display: block;
}

.screen-select-buttons-container-mobile .screen-select-card {
  height: 10%;
  margin-bottom: 20px;
  margin-right: 0 !important;
}

.screen-select-buttons-container-mobile .account-card-header {
  height: 40px;
}

.screen-select-buttons-container-mobile .card-title {
  margin: 5% 0;
}

.screen-select-buttons-container-mobile .card-text {
  margin: 20px 0;
}

.screen-select-buttons-container-mobile .account-card-image {
  width: 60px;
  height: 60px;
  font-size: 40px;

}

.screen-select-buttons-container-mobile .v-card-item {
  padding: 0 10px;
}

.confirmation-header {
  padding: 20px;
  font-size: 20px;
  text-align: center;
  background-color: rgb(var(--v-theme-error));
  color: white;
}
</style>
