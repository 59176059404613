<template>
  <div :id="'tutorial-' + tutorialNumber" style="display: none;">
    <v-card :id="'tutorial-box-' + tutorialNumber" elevation="10">
      <v-card-text>{{ text }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="previous" size="small" variant="tonal" color="primary" prepend-icon="mdi-arrow-left">{{ $t('back') }}</v-btn>
        <v-spacer></v-spacer>
        <div style="display: grid; text-align: center;">
          <span>{{cart.tutorialCurrentStep + "/" + totalSteps}}</span>
          <span class="skip-tutorial-btn" @click="skipTutorialModal = true">{{ $t('skip') }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="isLast ? finalizeTutorial() : next()" size="small" variant="tonal" color="primary" append-icon="mdi-arrow-right">{{isLast ? $t('finnish') : $t('next')}}</v-btn>
      </v-card-actions>
    </v-card>
  </div>

  <v-dialog
      v-model="skipTutorialModal"
      max-width="400px"
      style="padding: 5px 10px;"
      class="icarus-dialog"
  >
    <v-card>
      <span size="big" class="dialog-title">{{ $t('tutorial.skip') }}</span>
      <v-card-actions>
        <v-btn class="cancel-btn" variant="tonal" @click="skipTutorialModal = false;">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="success-btn" variant="tonal" @click="finalizeTutorial">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {cart} from "@/services/cart";
</script>

<script>
import UserService from "@/services/UserService";
import router from "@/router";
import TutorialService from "@/services/TutorialService";

export default {
  name: "TutorialStep",
  inject: ['mq'],
  props: {
    isLast: {
      type: Boolean,
      default: false
    },
    totalSteps: Number,
    tutorialNumber: Number,
    text: String,
  },
  computed: {
    freeUser() {
      return UserService.getLoggedUser().subscriptionType.toLowerCase() === 'free' || UserService.getLoggedUser().subscriptionType.toLowerCase() === 'freeemployee';
    }
  },
  data: () => ({
    user: null,
    skipTutorialModal: false,
    requestDetails: {
      title: "Cerere Tutorial",
      details: "Aici veți găsi detaliile cererii",
      status: "InProgress",
      priority: "Critical",
      createdById: "createdBy",
      companyId: "companyId",
      responseMessage: "Aici veți găsi detaliile după completarea cererii",
    }
  }),
  methods: {
    finalizeTutorial() {
      TutorialService.unhighlightElements();
      cart.value.startTutorial = false;
      this.skipTutorialModal = false;
      cart.value.tutorialCurrentStep = 1;
      UserService.tutorialDone(UserService.getLoggedUser().id);
      cart.value.company = null;
      router.push('overview');
    },
    previous() {
      TutorialService.previous();
    },
    next() {
      TutorialService.next();
    }
  }
}
</script>

<style scoped>

.skip-tutorial-btn {
  font-size: small; text-decoration: underline; color: blue; cursor: pointer;
}

.skip-tutorial-btn:hover {
  opacity: 0.8;
}

#tutorial-box-1 {
  position: absolute;
  right: 0;
  top: 80px;
  width: 300px;
  z-index: 1210;
}

#tutorial-box-2 {
  position: absolute;
  bottom: 0;
  width: 300px;
  left: calc(50% - 150px);
  right: calc(50% - 150px);
  margin: 20px auto;
  z-index: 1260;
}

#tutorial-box-3, #tutorial-box-4, #tutorial-box-5, #tutorial-box-6, #tutorial-box-8, #tutorial-box-9, #tutorial-box-10 {
/*#tutorial-box-8, #tutorial-box-9, */
  position: absolute;
  width: 300px;
  left: calc(50% - 150px);
  right: calc(50% - 150px);
  z-index: 1255;
}

#tutorial-box-3 {
  top: 220px;
}
#tutorial-box-8 {
  top: 80px;
  bottom: auto !important;
}

#tutorial-box-4, #tutorial-box-5, #tutorial-box-6, #tutorial-box-8, #tutorial-box-9, #tutorial-box-10 {
/*#tutorial-box-8, #tutorial-box-9, */
  bottom: 50px;
}

#tutorial-box-7 {
  position: absolute;
  width: 300px;
  right: calc(50% + 300px);
  bottom: 50%;
  z-index: 2450;
}

#tutorial-box-10 {
  position: absolute;
  width: 300px;
  left: calc(50% - 150px);
  right: calc(50% - 150px);
  top: 80px;
  height: fit-content;
  z-index: 1255;
}
</style>