import axios from 'axios';
import Helper from './Helper'
import {cart} from "@/services/cart";
import FileService from "@/services/FileService";
import UserService from "@/services/UserService";
import ProductService from "@/services/ProductService";

const COMPANY_API_BASE_URL = Helper.getHost() + '/api/company';
const ANAF_API_BASE_URL = 'https://webservicesp.anaf.ro/PlatitorTvaRest/api/v8/ws/tva';

class CompanyService{
    showDataUpload = false
    formItem = null

    add(userId, company) {
        return axios.post(COMPANY_API_BASE_URL, company, { headers: {'userId': userId}});
    }

    previewFileData(file, userId) {
        let formData = new FormData();
        formData.append('file', file, file.name);
        return axios.post(COMPANY_API_BASE_URL + "/file", formData, { headers: {'userId': userId}});
    }

    uploadFileData(fileData, userId) {
        return axios.post(COMPANY_API_BASE_URL + "/file/upload", fileData, { headers: {'userId': userId}});
    }

    downloadFileTemplate() {
        return axios.get(COMPANY_API_BASE_URL + "/template").then(bytes => {
            let blob = new Blob([bytes.data], {type: "octet/stream"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "company_data_template.csv";
            link.click();
        });
    }

    edit(company) {
        return axios.put(COMPANY_API_BASE_URL, company);
    }

    remove(id, userId) {
        return axios.delete(COMPANY_API_BASE_URL, { headers: {'companyId': id, 'userId': userId}});
    }

    getById(id) {
        return axios.get(COMPANY_API_BASE_URL, { headers: {'companyId': id}});
    }

    getCompanyById(companyId) {
        let company = cart.value.companies.find(comp => comp.id === companyId);
        if (!company) return this.getById(companyId).then(comp => {
            if (!comp || !comp.data) return;
            company = comp.data
        });
        return company;
    }

    changeCompany(companyId, all=false) {
        cart.value.company = cart.value.companies.find(x => x.id === companyId);
        cart.value.filterCompanies = [cart.value.company];
        if (all) {
            cart.value.filterCompanies = cart.value.companies;
        }
        localStorage.setItem("selectedCompanyId", companyId);
    }

    getExistentCompany(data) {
        return axios.post(COMPANY_API_BASE_URL + "/anaf", null, { headers: {'cui': data.cui}});
    }

    loadDefaultCompany() {
        if (!cart.value.mycompany || Object.keys(cart.value.mycompany).length === 0 || cart.value.mycompany.id === '') {
            let user = UserService.getLoggedUser();

            user = UserService.getUserByIdLocally(user.id);
            if (!user || !user.defaultCompanyId) return;
            let localComp = cart.value.companies.find(comp => comp.id === user.defaultCompanyId);
            if (localComp) {
                cart.value.mycompany = localComp;
                this.addDefaultCompanyToList();
                return localComp;
            }

            this.getById(user.defaultCompanyId).then(company => {
                if (company && company.data) {
                    cart.value.mycompany = company.data;
                    this.addDefaultCompanyToList();
                    //Because this takes a while to load, reload files afterwards
                    FileService.loadFilesByCompanies();
                }
                if (!this.formItem || !this.formItem.id)
                    this.formItem = company.data;
            });
            return this.formItem;
        } else {
            this.addDefaultCompanyToList();
            return cart.value.mycompany;
        }
    }

    addDefaultCompanyToList() {
        if (cart.value.mycompany && cart.value.mycompany.id && !cart.value.companies.find(comp => comp.id === cart.value.mycompany.id)) {
            cart.value.companies.unshift(cart.value.mycompany);
        }
    }

    loadDefaultCompanyDb(user) {
        return UserService.getUserById(user.id).then(userResponse => {
            if (!userResponse || !userResponse.data) return;
            user = userResponse.data
            if (!user || !user.defaultCompanyId) return;
            this.getById(user.defaultCompanyId).then(company => {
                if (!company.data) {
                    return;
                }
                cart.value.mycompany = company.data;
                if (!this.formItem || !this.formItem.id)
                    this.formItem = company.data;
            });
        })
    }

    setFormItem(item) {
        this.formItem = item;
    }

    getFormItem() {
        return this.formItem;
    }
}

export default new CompanyService();