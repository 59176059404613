import {ref} from "vue";
import i18n from "@/i18n";
import $cookies from "vue-cookie";

export const cart = ref({
    company: null,
    mycompany: {id: "", name: ''},
    logo: null,
    activeUser: false,
    iconAvailable: false,
    gridView: false,
    previewPrintButton: true,
    previewDialog: false,
    previewFileSrc: "",
    companies: [],
    settings: [],
    employees: [],
    subscriptions: [],
    usersByCompany: [],
    users: [],
    usersCache: [],
    usersIdCache: [],
    scanPreview: false,
    showTooltip: false,
    currentPage: '',
    filesData: [],
    uploadTableData: [],
    uploadTableDataFiltered: [],
    uploadTableLoading: false,
    tutorialCurrentStep: 1,
    startTutorial: false,
    tutorialDetailsDialog: false,
    setUploadTableData(tableData) {
        this.uploadTableData = tableData;
    },
    previewLoading: false,
    downloadTableData: [],
    downloadTableDataFiltered: [],
    downloadTableLoading: false,
    invoicesData: [],
    invoiceTableLoading: false,
    invoiceSub: false,
    addedProducts: [],
    availableProducts: [],
    productsTableLoading: false,
    setDownloadTableData(tableData) {
        this.downloadTableData = tableData;
    },
    requestsData: [],
    requestTableLoading: false,
    getDownloadTableDataByIndex(fileId) {
        return this.downloadTableData.find(file => file.id === fileId)
    },
    search: "",
    // filterDate: (new Date().getFullYear()) + "-" + String((new Date()).getMonth()+1).padStart(2,'0'),
    filterDate: i18n.global.t('filters.all'),
    filterCompanies: [],
    filterStatus: [],
    filterPriority: [],
    showResolved: false,
    showAllMonths: true,
    isDark: false,
    requestDetailsResponseMessage: '',
    notification: {
        show: false,
        showUpgrade: false,
        title: "Title",
        text: "Text"
    },
    showAlertDetails(text, showUpgrade=false, title= "", duration= 5000) {
        this.notification.show = true;
        this.notification.title = title;
        this.notification.text = text;
        this.notification.showUpgrade = showUpgrade;
        if (showUpgrade) {
            duration *= 3;
        }
        window.setTimeout(() => {
            this.notification.show = false;
        }, duration);
    },
    startLoading(obj) {
        obj.loading = true;
        setTimeout(() => {
            if (obj.loading) {
                obj.loading = false;
                this.showAlertDetails(i18n.global.t('loading.failed'));
            }
        }, 5000);
    },
    startLoadingVar(loadingVar) {
        this[loadingVar] = true;
        setTimeout(() => {
            if (this[loadingVar]) {
                this[loadingVar] = false;
                this.showAlertDetails(i18n.global.t('loading.failed'));
            }
        }, 5000);
    },
    activatedAccountSuccessfully: null,
    showDataUpload: null,
    lineChartMax: 0,
    userFormStep: 0,
    folderViewFiles: [],
    uploadLoaderWindowPanel: [0],
    showUploadFilesWindow: false,
    appLoader: false
});