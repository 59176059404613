<template>
  <span size="big" class="dialog-title">{{ $t('subscription.upgrade') }}</span>
  <v-card>
  <div v-for="(subscription, index) in subs" v-bind:key="subscription.id" style="width: -webkit-fill-available; padding: 20px;">
    <v-card
        class="mx-auto screen-select-card"
        variant="outlined"
        :elevation="4"
        :style="index === 4 ? 'margin-right: 0 !important;' : ''"
        :color="getColor(index)"
        @click="selectSub(subscription, index)"
    >
      <v-card-item :id="'upgrade-sub-' + index">
        <div class="account-card-header"></div>
        <div class="card-text-details">
          <div class="mb-1 center-text card-title">
            <span style="font-size: large">{{ $t('subscription.name.' + subscription.subscriptionType) }}</span>
          </div>
          <div class="center-text card-text"><b>{{ subscription.price }}</b> RON / {{ $t('month') }}</div>
          <div class="text-caption-custom center-text card-info-text">
            <b>{{ subscription.maxCompanies == '10000' ? $t('subscription.nolimit') : subscription.maxCompanies }}</b>{{ $t('subscription.maxCompanies') }}
            {{ $t('and') }}
            <b>{{ subscription.maxUsers }}</b>{{ $t('subscription.maxUsers') }}
            {{ subscription.subscriptionType === 'Free' ? $t('subscription.filelimit').toLowerCase() : ''}}
          </div>
        </div>
      </v-card-item>
    </v-card>
  </div>
  </v-card>
  <v-card-actions>
    <v-btn class="cancel-btn" variant="tonal" @click="$emit('upgradeDialog', false);">{{ $t('cancel') }}</v-btn>
    <v-spacer></v-spacer>
    <v-btn class="success-btn" variant="tonal" @click="upgradeSub" append-icon="mdi-check">
      {{ cart.selectedSubscription.subscriptionType === 'Free' ? $t('pay') : $t('confirm') }}
    </v-btn>
  </v-card-actions>


  <v-dialog
      class="details-dialog icarus-dialog"
      v-model="payedSubDialog"
      width="300"
      :class="mq.current === 'mobile' ? 'request-details-container-mobile' : 'request-details-container'">
    <v-card>
      <span class="dialog-title" size="big">{{ $t('confirm') }}</span>
      <div class="card-text-details" style="padding: 10px;">
        <span>{{ $t('subscription.upgrade.confirm') }}</span>
      </div>
    </v-card>
    <v-card-actions id="request-details-buttons" style="background: rgb(var(--v-theme-primary)); min-height: 40px; padding: 0;">
      <v-btn class="cancel-btn" variant="tonal" @click="payedSubDialog = false">{{ $t('cancel') }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="success-btn" variant="tonal" @click="upgradeFromPayedSub">{{ $t('pay') }}</v-btn>
    </v-card-actions>
  </v-dialog>

</template>

<script setup>
import {cart} from "@/services/cart";
</script>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import Helper from "@/services/Helper";
import UserService from "@/services/UserService";
import i18n from "@/i18n";

export default {
  name: "UpgradeSubscription",
  inject: ['mq'],
  mounted() {
    this.loadSubs();
  },
  data: () => ({
    subs: [],
    payedSubDialog: false,
    subsToDisplay: ['Tier1', 'Tier2', 'Tier3'],
  }),
  methods: {
    loadSubs() {
      SubscriptionService.getAllDetails().then(subs => {
        this.subs = subs.data.reverse();
        this.subs = this.subs.filter(sub => {
          if (cart.value.selectedSubscription.id === sub.id) return false;
          return this.subsToDisplay.includes(sub.subscriptionType);
        });
        this.betaSub = subs.data.find(sub => sub.subscriptionType === 'Beta');
      });
    },
    getColor(index) {
      switch (index) {
        case 0: return '#FFC400';
        case 1: return 'rgb(var(--v-theme-success))';
        case 2: return 'rgb(var(--v-theme-primary))';
      }
    },
    selectSub(sub, index) {
      cart.value.subUpgrade = sub;
      for (let i = 0; i < this.subs.length; i++) {
        let element = document.getElementById('upgrade-sub-' + i);
        //Selected sub
        if (i === index) {
          element.style.background = this.getColor(i);
          element.style.color = "#fff";
          //  Other subs
        } else {
          element.style.color = this.getColor(i);
          element.style.background = "none";
        }
      }
    },
    upgradeSub() {
      if (cart.value.selectedSubscription.subscriptionType === 'Free') {
        SubscriptionService.prepareData(UserService.getLoggedUser(), cart.value.mycompany, cart.value.subUpgrade, true).then(url => {
          this.loading = false;
          window.open(url.data.replaceAll(/\s/g,''), "_self");
        });
      } else {
        this.payedSubDialog = true;
      }
    },
    upgradeFromPayedSub() {
      SubscriptionService.upgradeSub(cart.value.subUpgrade).then(subLink => {
        cart.value.showAlertDetails(i18n.global.t('subscription.upgrade.success'));
      });
      this.payedSubDialog = false;
      this.$emit('upgradeDialog', false);
    }
  }
}
</script>

<style scoped>

</style>