import {cart} from "@/services/cart";
import FolderService, {folderData} from "@/services/FolderService";
import router from "@/router";
import PreviewService from "@/services/PreviewService";


class SelectionService {
    currentItem = null
    keyCounter = 0;

    addSelectedItem(item) {
        folderData.value.selectedItems.add(item);
        folderData.value.lastSelectedItem = item;
        this.currentItem = null;
    }

    deselectAll() {
        folderData.value.selectedItems.forEach((item) => this.deselectItem(item));
    }

    deselectItem(item) {
        if (!item) return;
        if (!folderData.value.lastSelectedItem) this.addSelectedItem(cart.value.folderViewFiles[0]);
        folderData.value.selectedItems.delete(item);
        let elem = document.getElementById("card-" + item.id);
        if (elem) {
            elem.classList.remove('selected-item');
        }
    }

    selectNeighbor(steps) {
        if (!folderData.value.lastSelectedItem) {
            this.addSelectedItem(cart.value.folderViewFiles[0]);
            return;
        }
        let selectedIndex = cart.value.folderViewFiles.indexOf(cart.value.folderViewFiles.find(folderItem => folderItem.id === folderData.value.lastSelectedItem.id));

        const folderNumber = cart.value.folderViewFiles.filter(file => file.directory).length;

        if (Math.abs(steps) > 1) {
            if (selectedIndex + steps > folderNumber && selectedIndex + steps < folderNumber + steps) {
                const stepOffset = steps - folderNumber % steps;
                steps -= stepOffset;
            }
            if (steps < 0 && selectedIndex - Math.abs(steps) < folderNumber &&
                selectedIndex - Math.abs(steps) > folderNumber - Math.abs(steps)) {
                const stepOffset = Math.abs(steps) - folderNumber % Math.abs(steps);
                steps += stepOffset;
                steps = selectedIndex+steps+1 > folderNumber ? folderNumber-selectedIndex-1 : steps;
            }
        }

        this.deselectAll();
        if (selectedIndex+steps < cart.value.folderViewFiles.length && selectedIndex+steps >= 0) {
            this.addSelectedItem(cart.value.folderViewFiles[selectedIndex+steps]);
        } else if (selectedIndex+steps < 0) {
            this.addSelectedItem(cart.value.folderViewFiles[0]);
        } else if (selectedIndex+steps >= cart.value.folderViewFiles.length) {
            this.addSelectedItem(cart.value.folderViewFiles[cart.value.folderViewFiles.length-1]);
        }
    }

    setSelectedItemsDesign() {
        folderData.value.selectedItems.forEach(item => {
            if (!item) return;
            let elem = document.getElementById("card-" + item.id);
            if (elem) {
                elem.classList.add('selected-item');
            }
        });
    }

    setSelectedElementDesign(elem) {
        const id = elem.id.replace("parent-", "");
        const card = document.getElementById("card-" + id);
        const fileItem = cart.value.folderViewFiles.find(file => file.id === id);
        if (!card || !fileItem) return;
        card.classList.add('selected-item');
        folderData.value.selectedItems.add(fileItem);
    }

    keysHandler(event) {
        if (router.currentRoute.value.name !== 'documents') return;

        if ((event.ctrlKey || event.shiftKey) && event.code === 'KeyA' && this.isDocsPage()) {
            event.preventDefault();
            cart.value.folderViewFiles.forEach(fileItem => {
                this.addSelectedItem(fileItem);
            });
            this.setSelectedItemsDesign();
        }
        if (event.ctrlKey && this.isDocsPage()) {
            if (event.code === 'KeyC') {
                folderData.value.clipboardItems = Array.from(folderData.value.selectedItems);
                const blob = new Blob(["text"], { type: 'text/plain' });
                const clipboardItem = new ClipboardItem({ 'text/plain': blob });

                var data = [new ClipboardItem({ "text/plain": Promise.resolve(new Blob(["Text data"], { type: "text/plain" })) })];

                navigator.clipboard.write([clipboardItem]);
            }
        }
        this.cooldownFunc(() => {this.shiftArrows(event)}, 20);
        if (event.code === 'Delete' && folderData.value.selectedItems.size > 0) {
            event.preventDefault();
            folderData.value.deleteDialog = true;
        }
        if (event.code === 'Enter' && !folderData.value.deleteDialog && !folderData.value.addDialog) {
            const [first] = folderData.value.selectedItems;
            if (!first || !first.id) return;
            const item = folderData.value.contentItems.find(ci => ci.id === first.id);

            this.enterItem(item, event, false);
        }
        if (event.code === 'Enter' && folderData.value.deleteDialog) {
        //    Submit the delete
            folderData.value.deleteDialog = false;
            FolderService.deleteFile();
        }
        if (event.code === 'Enter' && folderData.value.addDialog) {
        //    Submit the rename
            folderData.value.deleteDialog = false;
            FolderService.createOrRenameDirectory(folderData.value.fileName);
        }
        if (event.code === 'Backspace' && !folderData.value.moveDialog && !folderData.value.addDialog && !folderData.value.deleteDialog) {
            this.goBack();
        }
    }

    isDocsPage() {
        return router.currentRoute.value.name === 'documents';
    }

    shiftArrows(event) {
        const steps = getGridItemCount();
        if (!this.isDocsPage()) return
        if (event.shiftKey) {
            if (!folderData.value.lastSelectedItem) return;
            let selectedIndex = cart.value.folderViewFiles.indexOf(cart.value.folderViewFiles.find(folderItem => folderItem.id === folderData.value.lastSelectedItem.id));
            this.currentItem = this.currentItem !== null ? this.currentItem : selectedIndex;
            if (event.code === 'ArrowLeft' && this.currentItem-1 >= 0) {
                this.selectRange(cart.value.folderViewFiles[--this.currentItem]);
            }
            if (event.code === 'ArrowRight' && this.currentItem+1 < cart.value.folderViewFiles.length) {
                this.selectRange(cart.value.folderViewFiles[++this.currentItem]);
            }
        } else {
            if (event.code === 'ArrowLeft') {
                this.selectNeighbor(-1);
            }
            if (event.code === 'ArrowRight') {
                this.selectNeighbor(1);
            }
            if (event.code === 'ArrowDown') {
                this.selectNeighbor(steps);
            }
            if (event.code === 'ArrowUp') {
                this.selectNeighbor(-steps);
            }
            this.setSelectedItemsDesign();
        }
    }

    shiftClick(item, event) {
        if (event.shiftKey && this.isDocsPage()) {
            this.selectRange(item);
            return true;
        }
    }

    selectRange(item) {
        this.deselectAll();

        let currentIndex = cart.value.folderViewFiles.indexOf(cart.value.folderViewFiles.find(folderItem => folderItem.id === item.id));
        let selectedIndex = currentIndex;
        if (folderData.value.lastSelectedItem) {
            selectedIndex = cart.value.folderViewFiles.indexOf(cart.value.folderViewFiles.find(folderItem => folderItem.id === folderData.value.lastSelectedItem.id));
            if (selectedIndex === -1) {
                folderData.value.lastSelectedItem = item;
                selectedIndex = currentIndex;
            }
        } else {
            folderData.value.lastSelectedItem = item;
        }
        const minIndex = Math.min(selectedIndex, currentIndex);
        const maxIndex = Math.max(selectedIndex, currentIndex);
        for (let i = minIndex; i <= maxIndex; i++) {
            folderData.value.selectedItems.add(cart.value.folderViewFiles[i]);
        }
        this.setSelectedItemsDesign();
    }

    cooldownFunc(func, cd) {
        if(this.keyCounter === 0) {
            this.keyCounter++;
            this.timer = setTimeout(() => {
                this.keyCounter = 0;
            }, cd);
            func();
        }
    }


    goToSection(item) {
        //Clicked on one of the breadcrumbs link
        const pos = item.position;
        folderData.value.historyBack.push(item);
        folderData.value.breadcrumbItems = folderData.value.breadcrumbItems.slice(0, pos+1);
        switch (pos) {
            case 0: FolderService.loadCompanies(); break;
            case 1: FolderService.selectCompany(folderData.value.currentCompany); break;
            default: FolderService.selectFolder(item);
        }
    }

    enterItem(item, ev, isMobile) {
        if (!item) return;
        folderData.value.selectedItems = new Set();
        if (item.type === 'directory') {
            FolderService.selectFolder(item);
        } else if (item.type === 'file' && isMobile){
            FolderService.downloadFile(ev, item);
        } else if (item.type === 'file' && !isMobile){
            this.openFile(item);
        } else {
            FolderService.addBreadcrumbItem(1, item.title, '');
            FolderService.selectCompany(item);
        }
    }

    openFile(item) {
        this.deselectItem(item);
        PreviewService.previewFile(item);
        this.addSelectedItem(item);
    }

    goBack() {
        if (folderData.value.historyBack.length < 2) return;
        const backItem = {...folderData.value.historyBack[folderData.value.historyBack.length-2]};
        folderData.value.historyBack.pop();
        folderData.value.historyBack.pop();
        folderData.value.breadcrumbItems = folderData.value.historyBack;
        this.goToSection(backItem);
    }

    rectangleSelect(selector, x1, y1, x2, y2) {
        if (x2 < x1) [x1, x2] = [x2, x1];
        if (y2 < y1) [y1, y2] = [y2, y1];
        let elements = [];
        let items = document.getElementsByClassName(selector);
        Array.from(items).forEach(elem => {
            if (!elem) return;
            let rect = elem.getBoundingClientRect();
            let x = rect.x;
            let y = rect.y;
            let w = rect.width;
            let h = rect.height;

            // this element intersects with the selection rectangle
            if (x1 <= x + w && x2 >= x &&
                y1 <= y + h && y2 >= y)
                elements.push(elem);
        });
        return elements;
    }

    setUploadDesign(elemId) {
        let elem = document.getElementById(elemId);
        elem.classList.add('uploading');
    }

    removeUploadDesign(elemId) {
        if (elemId) {
            let elem = document.getElementById(elemId);
            elem.classList.remove('uploading');
        } else {
            let elems = document.getElementsByClassName('uploading');
            for (let i = 0; i < elems.length; i++) {
                elems[i].classList.remove('uploading');
            }
        }
    }
}

export default new SelectionService();

function getGridItemCount() {
    const gridContainer = document.querySelector('.content-container');
    const gridItem = document.querySelector('.content-item');

    if (!gridContainer || !gridItem) return 0;

    // Get the width of the grid container and one grid item
    const containerWidth = gridContainer.clientWidth - 20;
    const itemWidth = gridItem.offsetWidth;
    const gap = parseFloat(getComputedStyle(gridContainer).gap);

    let count = 0;
    while (count * (itemWidth+gap) < containerWidth) {
        count++;
    }
    count--;

    return count;
}