import { createRouter, createWebHistory } from 'vue-router'
import ClientUploadView from '../views/ClientUploadView.vue'
import AdminDownloadView from '../views/AdminDownloadView.vue'
import LoginView from '../views/LoginView.vue'
import ScreenSelectView from '../views/ScreenSelectView.vue'
import SettingsView from "@/views/SettingsView";

import UserService from '../services/UserService';
import PeopleManagerView from "@/views/PeopleManagerView";
import CompaniesManagerView from "@/views/CompaniesManagerView";
import axios from "axios";
import RequestsView from "@/views/RequestsView";
import ForgotPassword from "@/components/ForgotPassword";
import {cart} from "@/services/cart";
import SubscriptionService from "@/services/SubscriptionService";
import i18n from "@/i18n";
import OverviewView from "@/views/OverviewView";
import ErrorView from "@/views/ErrorView";
import ActivateAccount from "@/components/ActivateAccount";
import InvoicesView from "@/views/InvoicesView";
import RegisterUser from "@/components/RegisterUser";
import RegistrationSuccess from "@/components/mini/RegistrationSuccess";
import RegistrationFail from "@/components/mini/RegistrationFail";
import HomePage from "@/components/website/HomePage";
import LegalPage from "@/components/website/LegalPage";
import DocumentView from "@/views/DocumentView";
import UpgradeSuccess from "@/components/mini/UpgradeSuccess";

const routes = [
  {
    path: '/upload',
    name: 'upload',
    component: ClientUploadView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/download',
    name: 'download',
    component: AdminDownloadView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoicesView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/screen-select',
    name: 'screen-select',
    component: ScreenSelectView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/overview',
    name: 'overview',
    component: OverviewView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/people',
    name: 'people',
    component: PeopleManagerView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesManagerView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/activate',
    name: 'activate',
    props: true,
    component: ActivateAccount,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterUser,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/register/success',
    name: 'RegistrationSuccess',
    component: RegistrationSuccess,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/upgrade/success',
    name: 'UpgradeSuccess',
    component: UpgradeSuccess,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    component: LoginView,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/en',
    name: 'homeEn',
    component: HomePage,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/legal',
    name: 'legal',
    component: LegalPage,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/register/fail',
    name: 'RegistrationFail',
    component: RegistrationFail,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView,
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/sitemap.xml',
    name: 'Sitemap',
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/ro',
    redirect: '/'
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/error'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    const user = UserService.getLoggedUser();
    const isFree = SubscriptionService.isFreeEmployee(user.subscriptionType);
    const isFreeEmployee = SubscriptionService.isFreeEmployee(user.subscriptionType);
    if (!user.admin && (isFree || isFreeEmployee)) {
      cart.value.showAlertDetails(i18n.global.t('subscription.notavailable'))
      return false;
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!UserService.isUserLoggedIn()) {
      next({path: '/login'})
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

axios.interceptors.request.use(function (config) {
  config.headers['session'] = UserService.getSession();
  // config.headers['s_session'] = $cookies.get('S_SESSION');
  config.headers['user-id'] = UserService.getLoggedUser().id;
  config.headers['language'] = i18n.global.locale.value.toUpperCase();
  return config;
});

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error)
  if (error.response.status === 500) {
    // cart.value.showAlertDetails(i18n.global.t('servererror'));
    return;
  }

  if (error.response.status === 400) {
    // cart.value.showAlertDetails(i18n.global.t('servererror'));
    return;
  }

  if (!error || !error.response) {
    // cart.value.showAlertDetails(i18n.global.t('servererror'));
    // return Promise.reject(error);
    return;
  }

  if (error.response.status === 401) {
    UserService.clearCookies()
    router.push('/login')
    return Promise.reject(error);
  } else {
    cart.value.showAlertDetails(i18n.global.t('servererror'))
    return Promise.reject(error);
  }
  // return Promise.reject(error)
})

export default router

