
<template>
  <v-container fluid class="grey lighten-5">
    <router-view />
  </v-container>
</template>

<style>

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url(./assets/fonts/Inter/static/Inter-Regular.ttf) format("truetype");
}

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*font-family: 'Oxygen', sans-serif;*/
  font-family: 'Inter', Avenir, Helvetica, Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: unset;
  background-color: rgb(var(--v-theme-background));
}

.text-caption-custom {
  font-family: 'Inter', Avenir, Helvetica, Arial, serif !important;
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
  text-transform: none !important;
}

html {
  overflow-y: unset !important;
}

tbody tr td {
  background: inherit !important;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}

thead th {
  /*background: rgb(var(--v-theme-primary)) !important;*/
  /*color: white !important;*/
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(245, 245, 245);
}

tbody tr:nth-of-type(even) {
  background-color: rgb(255 ,255, 255);
}

.v-theme--mainDarkTheme tbody tr:nth-of-type(even) {
  background-color: rgb(15 ,15, 15);
}

.v-theme--mainDarkTheme tbody tr:nth-of-type(odd) {
  background-color: rgb(25 ,25, 25);
}

.v-checkbox .v-selection-control {
  min-height: 34px !important;
}

.v-overlay-container {
  --v-body-scroll-x: 0 !important;
  --v-body-scroll-y: 0 !important;
}

.icarus-dialog .dialog-title {
  text-align: center;
  padding: 10px 0;
  background: rgb(var(--v-theme-primary));
  color: white;
}

.icarus-dialog .delete-btn {
  background: rgb(var(--v-theme-error)) !important;
}

.icarus-dialog .success-btn {
  /*  Small btn*/
  --v-btn-size: 0.75rem;
  --v-btn-height: 28px;
  font-size: var(--v-btn-size);
  min-width: 50px;

  height: var(--v-btn-height);
  margin-left: 10px;
  margin-right: 10px;
  background: rgb(var(--v-theme-success));
}

.icarus-dialog .cancel-btn {
/*  Small btn*/
  --v-btn-size: 0.75rem;
  --v-btn-height: 28px;
  font-size: var(--v-btn-size);
  min-width: 50px;

  height: var(--v-btn-height);
  margin-right: 10px;
  margin-left: 10px;
  color: white;

}

.icarus-dialog .v-card-actions {
  background: rgb(var(--v-theme-primary));
  min-height: 40px;
  padding: 0;
  color: white;

  position: sticky;
  width: -webkit-fill-available;
  bottom: 0;
}

.v-application, .content-page-main {
  margin-top: 60px !important;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.v-field__overlay, .v-field__field, .v-theme--mainDarkTheme BUTTON.v-btn {
  background: rgb(var(--v-theme-input));
}

.v-theme--mainDarkTheme .content-item {
  background: rgb(var(--v-theme-input)) !important;
}

</style>